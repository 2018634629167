import { If } from 'simple-core-ui'

import s from './DescriptionList.scss'

const DescriptionList = ({
  pairs,
  descriptions,
  noSubtext,
  containerStyle = {},
  valueStyle = {}
}) => (
  <section className={s.container} style={containerStyle}>
    {pairs.map(([name, value], idx) => (
      <section className={s.value} style={valueStyle} key={idx}>
        <header className={s.header}>
          <h3 className={s.heading}>
            <strong>{name}</strong>
            <If condition={!noSubtext}> ({value})</If>
          </h3>
          <If condition={descriptions[name]?.text}>
            <p className={s.description}>{descriptions[name]?.text}</p>
          </If>
        </header>
        <If condition={descriptions[name]?.render}>
          {() => <section className={s.content}>{descriptions[name]?.render(value)}</section>}
        </If>
      </section>
    ))}
  </section>
)

export default DescriptionList
