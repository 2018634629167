/* eslint-disable no-console */
import { CSSProperties } from 'react'
import noop from 'lodash/noop'
import { IoIosClose } from 'react-icons/io'
import classNames from 'classnames'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

import { KeyValuePairs, Popable, If } from 'simple-core-ui'

import s from './HoverAction.scss'

interface Props {
  testid?: string
  onClick?(): void
  isDisabled?: boolean
  tip?: string
  tipPosition?: string
  icon?: JSX.Element
  size?: 'xsmall' | 'small' | 'medium'
  style?: CSSProperties
  className?: string
  forceClass?: boolean
  hasNewDesign?: boolean
}

const HoverAction = ({
  testid,
  onClick,
  isDisabled,
  tip,
  tipPosition,
  icon,
  size = 'small',
  style = {},
  className = '',
  forceClass = false,
  hasNewDesign = false
}: Props) => {
  const Content = (): JSX.Element => (
    <span
      onClick={isDisabled ? noop : onClick}
      className={classNames(s.container, s[size], {
        [s.disabled]: isDisabled,
        [className]: (!tip || forceClass) && className,
        [s.hasNewDesign]: hasNewDesign
      })}
      style={tip ? {} : style}
    >
      {icon}
    </span>
  )

  return (
    <If condition={!!tip} fallback={<Content />}>
      <Popable
        testid={testid}
        position={tipPosition}
        content={tip}
        openOnHover
        asTip
        className={className}
        styles={style}
      >
        <Content />
      </Popable>
    </If>
  )
}

registration.register({
  name: 'HoverAction',
  description: 'A component that makes an icon into an action item.',
  props: [
    {
      name: 'icon',
      type: 'React.Node',
      note: 'The component to make actionable. It should be an icon in most cases.'
    },
    {
      name: 'onClick',
      type: '() => void',
      note: 'The click handle for the action item. If disabled it will not fire.'
    },
    {
      name: 'isDisabled',
      optional: true,
      type: 'boolean',
      defaultValue: 'false',
      note:
        'If set true this will render the component in a disabled state and the onClick will not be fired.'
    },
    {
      name: 'tip',
      optional: true,
      type: 'string',
      note:
        'If provided a tooltip will render on the component when hovered with the passed value as its content.'
    },
    {
      name: 'tipPosition',
      optional: true,
      type: 'top | right | bottom | left',
      defaultValue: 'top',
      note: 'The direction in which the tooltip should be displayed.'
    },
    {
      name: 'size',
      optional: true,
      type: 'xsmall | small | medium',
      defaultValue: 'small',
      note: 'The size of the actionable component.'
    },
    {
      name: 'style',
      optional: true,
      type: 'Object',
      defaultValue: '{}',
      note: 'Custom styles to apply to the component.'
    },
    {
      name: 'className',
      optional: true,
      type: 'string',
      defaultValue: "''",
      note: 'Custom className to apply to the component.'
    }
  ],
  example: {
    literal: `
<KeyValuePairs
  pairs={[
    {
      key: 'HoverAction (xsmall, normal)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          icon={<IoIosClose />}
          size='xsmall'
        />
      )
    },
    {
      key: 'HoverAction (xsmall, disabled)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          isDisabled
          icon={<IoIosClose />}
          size='xsmall'
        />
      )
    },
    {
      key: 'HoverAction (xsmall w/ tip)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          tip='Just Do It.'
          icon={<IoIosClose />}
          size='xsmall'
        />
      )
    },
    {
      key: 'HoverAction (small, normal)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          icon={<IoIosClose />}
        />
      )
    },
    {
      key: 'HoverAction (small, disabled)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          isDisabled
          icon={<IoIosClose />}
        />
      )
    },
    {
      key: 'HoverAction (small w/ tip)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          tip='Just Do It.'
          icon={<IoIosClose />}
        />
      )
    },
    {
      key: 'HoverAction (medium, normal)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          icon={<IoIosClose />}
          size='medium'
        />
      )
    },
    {
      key: 'HoverAction (medium, disabled)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          isDisabled
          icon={<IoIosClose />}
          size='medium'
        />
      )
    },
    {
      key: 'HoverAction (medium w/ tip)',
      value: (
        <HoverAction
          onClick={() => console.log('HoverAction clicked...')}
          tip='Just Do It.'
          icon={<IoIosClose />}
          size='medium'
        />
      )
    }
  ]}
  style={{maxWidth: '700px', margin: 'auto'}}
/>`.trim(),
    render: () => (
      <KeyValuePairs
        pairs={[
          {
            key: 'HoverAction (xsmall, normal)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                icon={<IoIosClose />}
                size="xsmall"
              />
            )
          },
          {
            key: 'HoverAction (xsmall, disabled)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                isDisabled
                icon={<IoIosClose />}
                size="xsmall"
              />
            )
          },
          {
            key: 'HoverAction (xsmall w/ tip)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                tip="Just Do It."
                icon={<IoIosClose />}
                size="xsmall"
              />
            )
          },
          {
            key: 'HoverAction (small, normal)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                icon={<IoIosClose />}
              />
            )
          },
          {
            key: 'HoverAction (small, disabled)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                isDisabled
                icon={<IoIosClose />}
              />
            )
          },
          {
            key: 'HoverAction (small w/ tip)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                tip="Just Do It."
                icon={<IoIosClose />}
              />
            )
          },
          {
            key: 'HoverAction (medium, normal)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                icon={<IoIosClose />}
                size="medium"
              />
            )
          },
          {
            key: 'HoverAction (medium, disabled)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                isDisabled
                icon={<IoIosClose />}
                size="medium"
              />
            )
          },
          {
            key: 'HoverAction (medium w/ tip)',
            value: (
              <HoverAction
                onClick={() => console.log('HoverAction clicked...')}
                tip="Just Do It."
                icon={<IoIosClose />}
                size="medium"
              />
            )
          }
        ]}
        style={{ maxWidth: '700px', margin: 'auto' }}
      />
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/HoverAction/HoverAction'
})

export default HoverAction
