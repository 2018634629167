import { IoIosSearch } from 'react-icons/io'
import { Input } from 'components'
import s from './FilterValues.scss'

const SearchValue = ({ onChange, filterTerm }) => (
  <div className={s.searchContainer}>
    <Input
      type="text"
      value={filterTerm}
      className={s.filterInput}
      placeholder="Search"
      onChange={onChange}
    />
    <IoIosSearch />
  </div>
)

export default SearchValue
