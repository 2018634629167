import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'
import Flex from '../Flex/Flex.js'

const { borderWidth, color, borderRadius, spacing } = DESIGN

const descriptions = {
  bdW100: {
    text:
      'For elements such as inputs, selects, or any other elements that need a subtle distinction from their background.',
    render: value => (
      <section
        style={{
          border: `${borderWidth.bdW100} solid ${color.gray400}`,
          borderRadius: borderRadius.bdR200,
          width: spacing.sp1100,
          height: spacing.sp600,
          background: color.gray100
        }}
      />
    )
  },
  bdW200: {
    text:
      'Used mostly when trying to distinguish a particular element as selected / active (i.e Sidebar link, Tabs)',
    render: value => {
      const baseStyle = { padding: spacing.sp200, opacity: '0.7' }

      const baseStyleA = {
        ...baseStyle,
        borderBottom: `${value} solid transparent`
      }

      const baseStyleB = {
        ...baseStyle,
        borderLeft: `${value} solid transparent`
      }

      return (
        <Flex justify="flex-start">
          <Flex
            direction="column"
            align="flex-start"
            justify="flex-start"
            style={{ marginRight: spacing.sp900 }}
          >
            <section
              style={{
                ...baseStyleB,
                opacity: '1',
                borderColor: color.blue600
              }}
            >
              Item
            </section>
            <section style={baseStyleB}>Item</section>
            <section style={baseStyleB}>Item</section>
          </Flex>
          <Flex justify="flex-start">
            <section
              style={{
                ...baseStyleA,
                opacity: '1',
                borderColor: color.blue600
              }}
            >
              Item
            </section>
            <section style={baseStyleA}>Item</section>
            <section style={baseStyleA}>Item</section>
          </Flex>
        </Flex>
      )
    }
  }
}

const sass = `
.navLink {
  border-bottom: $bdW200 solid $blue600;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { borderWidth: { bdW200 }, color: { blue600 } } = DESIGN

const NavLink = () => (
  <a style={{ borderBottomWidth: bdW200, borderColor: blue600 }}>Home</a>
)
`

const html = `
<a class='b-bdW200 b-blue600'>Home</a>

<!--
.bdW200    ==> border-width
.t-bdW200  ==> border-width-top
.r-bdW200  ==> border-width-right
.b-bdW200  ==> border-width-bottom
.l-bdW200  ==> border-width-left
.v-bdW200  ==> border-width-top/bottom
.h-bdW200  ==> border-width-left/right
-->
`

export default registration.register({
  name: 'Border Width',
  description: 'SimpleLegal Design System Border Width',
  example: {
    render: () => (
      <DescriptionList pairs={getDesignValues(borderWidth)} descriptions={descriptions} />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
