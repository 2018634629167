import { IoIosClose } from 'react-icons/io'

import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

import s from './system_doc.scss'

const { transition } = DESIGN

const descriptions = {
  trans100: {
    text: 'For quick, subtle tranistions usually around color / opacity.',
    render: () => <IoIosClose className={s.transition100Example} />
  },
  trans200: {
    text: 'For more noticeable transitions around color / opacity.',
    render: () => <span className={s.transition200Example}>Hover Me...</span>
  },
  trans300: {
    text: 'A shorter duration that is good for movement of an element via transition.',
    render: () => <section className={s.transition300Example} />
  },
  trans400: {
    text: 'A long duration for movement of an element.',
    render: () => <span className={s.transition400Example} />
  },
  trans500: {
    text:
      'The longest duration a transition should take. It should not be used in most cases, except for special UI.',
    render: () => <span className={s.transition500Example} />
  }
}

const sass = `
.header {
  opacity: 0.5;
  transition: opacity $trans200;

  &:hover{
    opacity: 1;
  }
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { transition: { trans200 } } = DESIGN

const Header = () => <h1 style={{transition: trans200, transitionProperty: 'opacity'}}>...</h1>
`

const html = '<h1 class="trans200" style="transition-property: opacity">...</h1>'

export default registration.register({
  name: 'Transition',
  description: 'SimpleLegal Design System Transitions',
  example: {
    render: () => (
      <DescriptionList pairs={getDesignValues(transition)} descriptions={descriptions} />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
