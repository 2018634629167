import { CSSProperties } from 'react'
import classNames from 'classnames'
import { IoIosFlag } from 'react-icons/io'

import { KeyValuePairs, If, Spinner } from 'simple-core-ui'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

import s from './Badge.scss'

type Size = 'sm' | 'md' | 'lg'

interface Props {
  className?: string
  asPill?: boolean
  isLoading?: boolean
  size?: Size
  content: JSX.Element | string
  title?: string
  style?: CSSProperties
  onBadgeClick?: () => void
}

const Badge = ({
  content,
  style = {},
  title,
  size = 'md',
  className = '',
  asPill,
  isLoading,
  onBadgeClick
}: Props) => (
  <section
    className={classNames(s.container, s[size], { [className]: className, [s.pill]: asPill })}
    style={style}
    {...(title ? { title } : {})}
    onClick={onBadgeClick}
  >
    {content}
    <If condition={isLoading}>
      <Spinner className={s.spinner} />
    </If>
  </section>
)

registration.register({
  name: 'Badge',
  description:
    'A simple component that will render a badge useful for indicating a number of new messages or additional items in a condensed list.',
  props: [
    {
      name: 'content',
      type: 'React.Node',
      note: 'The content that will be rendered within the Badge itself.'
    },
    {
      name: 'size',
      type: 'sm | md | lg',
      optional: true,
      defaultValue: 'md',
      note: 'The size the Badge should render as.'
    },
    {
      name: 'title',
      type: 'string',
      optional: true,
      note:
        'The value for the title attribute on the DOM node to show when mouse is hovered over component for period of time.'
    },
    {
      name: 'style',
      optional: true,
      defaultValue: '{}',
      type: 'CSS Object Declaration',
      note: 'The custom styles to apply to the Badge instance.'
    },
    {
      name: 'className',
      optional: true,
      defaultValue: "''",
      type: 'string',
      note: 'The custom class to apply to the Badge instance.'
    },
    {
      name: 'asPill',
      optional: true,
      defaultValue: 'false',
      type: 'boolean',
      note: 'If true the badge will render in a pill form.'
    },
    {
      name: 'isLoading',
      optional: true,
      defaultValue: 'false',
      type: 'boolean',
      note: 'If true the badge will render a spinner next to the content within.'
    }
  ],
  example: {
    render: () => {
      const FlexWrapper = ({ children }: { children: JSX.Element[] }) => (
        <section style={{ display: 'flex', justifyContent: 'space-around' }}>{children}</section>
      )

      const sizingMap = { sm: 'small', md: 'medium', lg: 'large' }

      const BadgeCollection = ({ size }: { size: Size }) => (
        <KeyValuePairs
          pairs={[
            {
              key: `Standard Form (${sizingMap[size]})`,
              value: (
                <FlexWrapper>
                  <Badge content="+5 more" size={size} />
                  <Badge
                    content="5 approved"
                    size={size}
                    style={{ color: 'white', background: 'mediumseagreen' }}
                  />
                  <Badge
                    content="8 unread"
                    size={size}
                    style={{ color: 'white', background: 'dodgerblue' }}
                  />
                  <Badge
                    content={
                      <span>
                        <IoIosFlag /> 180 flagged
                      </span>
                    }
                    size={size}
                    style={{ color: 'white', background: 'orangered' }}
                  />
                  <Badge
                    content="Loading Status"
                    size={size}
                    style={{ color: 'white', background: 'orange' }}
                    isLoading
                  />
                </FlexWrapper>
              )
            },
            {
              key: `Pill Form (${sizingMap[size]})`,
              value: (
                <FlexWrapper>
                  <Badge content="+5 more" size={size} asPill />
                  <Badge
                    content="5 approved"
                    size={size}
                    style={{ color: 'white', background: 'mediumseagreen' }}
                    asPill
                  />
                  <Badge
                    content="8 unread"
                    size={size}
                    style={{ color: 'white', background: 'dodgerblue' }}
                    asPill
                  />
                  <Badge
                    content={
                      <span>
                        <IoIosFlag /> 180 flagged
                      </span>
                    }
                    size={size}
                    style={{ color: 'white', background: 'orangered' }}
                    asPill
                  />
                  <Badge
                    content="Loading Status"
                    size={size}
                    style={{ color: 'white', background: 'orange' }}
                    asPill
                    isLoading
                  />
                </FlexWrapper>
              )
            }
          ]}
          stackedKeys
        />
      )

      return (
        <>
          {['sm', 'md', 'lg'].map(size => {
            const s = size as Size
            return <BadgeCollection size={s} key={s} />
          })}
        </>
      )
    }
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Badge/Badge'
})

export default Badge
