import { FaAngleRight, FaAngleLeft } from 'react-icons/fa'
import s from './EditableFieldFilter.scss'
import cn from 'classnames'

const ValuesMover = ({
  leftSelectedOption,
  rightSelectedOption,
  moveAvailable2Selected,
  moveSelected2Available,
  moveAllAvailable2Selected,
  moveAllSelected2Available,
  isReadOnly
}) => (
  <div className={s.actionsWrapper}>
    <div className={s.actionsGroup}>
      <div
        className={cn(s.action, {
          [s.readOnly]: isReadOnly
        })}
        onClick={() => {
          if (isReadOnly) return
          moveAvailable2Selected(leftSelectedOption)
        }}
      >
        <span>Move</span>
        <FaAngleRight />
      </div>
      <div
        className={cn(s.action, {
          [s.readOnly]: isReadOnly
        })}
        onClick={() => {
          if (isReadOnly) return
          moveSelected2Available(rightSelectedOption)
        }}
      >
        <span>Move</span>
        <FaAngleLeft />
      </div>
    </div>
    <div className={s.actionsGroup}>
      <div
        className={cn(s.action, {
          [s.readOnly]: isReadOnly
        })}
        onClick={() => {
          if (isReadOnly) return
          moveAllAvailable2Selected()
        }}
      >
        <span>Move All</span>
        <FaAngleRight />
      </div>
      <div
        className={cn(s.action, {
          [s.readOnly]: isReadOnly
        })}
        onClick={() => {
          if (isReadOnly) return
          moveAllSelected2Available()
        }}
      >
        <span>Move All</span>
        <FaAngleLeft />
      </div>
    </div>
  </div>
)

export default ValuesMover
