/* eslint-disable no-console */
import * as React from 'react'
import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import { Modal, Button } from 'simple-core-ui'

export default class ModalContainer extends React.Component {
  static defaultProps = {
    size: 'md',
    shouldModalClose: true
  }

  state = {
    isVisible: !this.props.children
  }

  componentDidMount() {
    if (this.props.closeOnEscape) {
      window.addEventListener('keyup', this.escapeModal)
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEscape) {
      window.removeEventListener('keyup', this.escapeModal)
    }
  }

  escapeModal = e => {
    if (e.keyCode === 27) {
      this.closeModal()
    }
  }

  openModal = () => {
    this.setState(() => ({
      isVisible: true
    }))
  }

  closeModal = () => {
    this.setState(() => ({
      isVisible: false
    }))
  }

  confirmCb = e => {
    const { confirmCb, shouldModalClose } = this.props

    if (typeof confirmCb === 'function') {
      confirmCb(e)
    }
    if (shouldModalClose) this.setState({ isVisible: false })
  }

  secondaryConfirmCb = () => {
    const { secondaryConfirmCb, shouldModalClose } = this.props

    if (typeof secondaryConfirmCb === 'function') {
      secondaryConfirmCb()
    }
    if (shouldModalClose) this.setState({ isVisible: false })
  }

  cancelCb = () => {
    const { cancelCb, shouldModalClose } = this.props

    if (typeof cancelCb === 'function') {
      cancelCb()
    }
    if (shouldModalClose) this.setState({ isVisible: false })
  }

  render() {
    return (
      <Modal
        {...this.props}
        {...this.state}
        confirmCb={this.props.confirmCb && this.confirmCb}
        secondaryConfirmCb={this.props.secondaryConfirmCb && this.secondaryConfirmCb}
        cancelCb={this.props.cancelCb && this.cancelCb}
        openModal={this.openModal}
        closeModal={this.closeModal}
      />
    )
  }
}

registration.register({
  name: 'ModalContainer',
  description: 'The component that should be used to display information in a modal format.',
  props: [
    {
      name: 'size',
      type: 'xs | sm | md | lg',
      defaultValue: 'md',
      note: 'The size the component should render as.'
    },
    { name: 'content', type: 'React.Node', note: 'The content to render within the modal body.' },
    {
      name: 'children',
      type: '(openModal) => React.Node',
      note:
        'A render prop that exposes the functions for opening and closing the modal. The render prop should return the React.Node that will be responsible for controlling the visiblity of the ModalContainer.'
    },
    { name: 'title', optional: true, type: 'React.Node', note: 'The title of the modal.' },
    { name: 'subtitle', optional: true, type: 'string', note: 'The subtitle of the modal.' },
    { name: 'confirmText', type: 'string', note: 'The text of the confirm button.' },
    {
      name: 'secondaryConfirmText',
      optional: true,
      type: 'string',
      note: 'The text of the secondary confirm button.'
    },
    { name: 'cancelText', type: 'string', note: 'The text of the cancel button.' },
    {
      name: 'confirmCb',
      type: 'function -- () => void',
      note:
        'A callback which will be invoked when the confirm button is clicked. Note that the modal will automatically close when the confirmation button is clicked.'
    },
    {
      name: 'secondaryConfirmCb',
      type: 'function -- () => void',
      optional: true,
      note:
        'A callback which will be invoked when the secondary confirm button is clicked. Note that the modal will automatically close when the secondary confirmation button is clicked.'
    },
    {
      name: 'cancelCb',
      type: 'function -- () => void',
      optional: true,
      note:
        'A callback which will be invoked when the cancel button or the X is clicked. Note that the modal will automatically close when the cancel button is clicked.'
    },
    {
      name: 'hasNewButtons',
      optional: true,
      type: 'boolean',
      note: 'Will render the new design for buttons if true.'
    },
    {
      name: 'isDisabled',
      optional: true,
      type: 'boolean',
      note: 'Will disable the Confirm button in the modal.'
    },
    {
      name: 'isDisabledSecondary',
      optional: true,
      type: 'boolean',
      note: 'Will disable the Secondary Confirm button in the modal.'
    },
    {
      name: 'isLoading',
      optional: true,
      type: 'boolean',
      note: 'Will render spinner overlay over the contents of the modal.'
    },
    {
      name: 'animate',
      optional: true,
      type: 'boolean',
      defaultValue: 'false',
      note: 'If true the modal will animate in.'
    },
    {
      name: 'closeOnEscape',
      optional: true,
      type: 'boolean',
      defaultValue: 'false',
      note: 'If true the modal will close when user presses escape.'
    }
  ],
  example: {
    render: () => (
      <section
        style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'center'
        }}
      >
        <ModalContainer
          title="Small Modal (Loading)"
          content="This is a small modal in a loading state..."
          confirmText="Yes, I do"
          cancelText="No, I do not"
          confirmCb={() => console.log('Confirmed Small Modal!')}
          cancelCb={() => console.log('Cancelled Small Modal!')}
          size="sm"
          isLoading
        >
          {openModal => (
            <Button onClick={openModal} isSecondary>
              Small Modal (Loading)
            </Button>
          )}
        </ModalContainer>
        <ModalContainer
          title="Small Modal"
          content="This is a small modal..."
          confirmText="Yes, I do"
          cancelText="No, I do not"
          confirmCb={() => console.log('Confirmed Small Modal!')}
          cancelCb={() => console.log('Cancelled Small Modal!')}
          size="sm"
        >
          {openModal => (
            <Button onClick={openModal} isSecondary>
              Small Modal
            </Button>
          )}
        </ModalContainer>
        <ModalContainer
          title="Medium Modal"
          content="This is a medium modal..."
          confirmText="Yes, I do"
          cancelText="No, I do not"
          confirmCb={() => console.log('Confirmed Medium Modal!')}
          cancelCb={() => console.log('Cancelled Medium Modal!')}
          size="md"
        >
          {openModal => (
            <Button onClick={openModal} isSecondary>
              Medium Modal
            </Button>
          )}
        </ModalContainer>
        <ModalContainer
          title="Large Modal"
          content="This is a large modal..."
          confirmText="Yes, I do"
          cancelText="No, I do not"
          confirmCb={() => console.log('Confirmed Large Modal!')}
          cancelCb={() => console.log('Cancelled Large Modal!')}
          size="lg"
        >
          {openModal => (
            <Button onClick={openModal} isSecondary>
              Large Modal
            </Button>
          )}
        </ModalContainer>
        <ModalContainer
          title="Extra Large Modal"
          content="This is an extra large modal..."
          confirmText="Yes, I do"
          cancelText="No, I do not"
          confirmCb={() => console.log('Confirmed Extra Large Modal!')}
          cancelCb={() => console.log('Cancelled Extra Large Modal!')}
          size="xl"
        >
          {openModal => (
            <Button onClick={openModal} isSecondary>
              Extra Large Modal
            </Button>
          )}
        </ModalContainer>
        <ModalContainer
          title="Full Page Modal"
          content="This is a full page modal..."
          confirmText="Yes, I do"
          cancelText="No, I do not"
          confirmCb={() => console.log('Confirmed Extra Large Modal!')}
          cancelCb={() => console.log('Cancelled Extra Large Modal!')}
          size="fp"
        >
          {openModal => (
            <Button onClick={openModal} isSecondary>
              Full Page Modal
            </Button>
          )}
        </ModalContainer>
        <ModalContainer
          title="Animated Modal"
          content="This modal animated in..."
          confirmText="Cool"
          cancelText="Whatever"
          confirmCb={() => console.log('Confirmed Animated Modal!')}
          cancelCb={() => console.log('Cancelled Animated Modal!')}
          size="md"
          animate
        >
          {openModal => (
            <Button onClick={openModal} isSecondary>
              Animated Modal
            </Button>
          )}
        </ModalContainer>
      </section>
    )
  },
  category: CATEGORY.OVERLAYS,
  path: 'containers/Core/ModalContainer/ModalContainer'
})
