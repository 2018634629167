import s from './Textarea.scss'

const Textarea = ({
  rows,
  value,
  onChange,
  placeholder,
  styles,
  withCgStyling,
  isDisabled,
  id
}) => {
  const classes = `${withCgStyling ? s.cgStyles : s.textarea} ${isDisabled ? s.disabled : ''}`
  return (
    <textarea
      rows={rows}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
      style={styles}
      className={classes}
      disabled={isDisabled && isDisabled}
      id={id}
    />
  )
}

export default Textarea
