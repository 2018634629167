import FilterValue from './FilterValue'
import SearchValue from './SearchValue'
import s from './FilterValues.scss'
import { SimpleContext } from 'simple-core-ui'

const FilterValues = ({
  options,
  selectedValue,
  selectedValues,
  setSelectedValue,
  searchTerm,
  onSearch,
  noOptionsTitle,
  noOptionsText,
  optionFormat,
  isMulti,
  isReadOnly,
  labelPrefix
}) => (
  <SimpleContext.Consumer>
    {({ theme }) => {
      return (
        <div className={s.listContainer}>
          <SearchValue onChange={onSearch} filterTerm={searchTerm} />
          {options.length ? (
            <ul className={`${s.list} ${s[theme]}`}>
              {options.map(option => (
                <FilterValue
                  value={option.value}
                  valid={option.valid}
                  label={option.label}
                  isSelected={
                    isMulti ? selectedValues.includes(option.value) : option.value === selectedValue
                  }
                  setSelectedValue={setSelectedValue}
                  key={option.value}
                  optionFormat={optionFormat && optionFormat(option)}
                  isMulti={isMulti}
                  isReadOnly={isReadOnly}
                  labelPrefix={labelPrefix}
                />
              ))}
            </ul>
          ) : (
            <div className={s.noOptionsContainer}>
              <div>
                <h1 className={s.noOptionsTitle}>{noOptionsTitle}</h1>
                {noOptionsText && <p className={s.noOptionsText}>{noOptionsText}</p>}
              </div>
            </div>
          )}
        </div>
      )
    }}
  </SimpleContext.Consumer>
)

export default FilterValues
