import { SVGProps } from 'react'

const ShareLineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.52145 15.5106C5.17135 15.8778 4.87516 16.2436 4.62631 16.5901C5.14615 13.3518 6.70329 11.371 8.40082 10.1431C10.5003 8.62432 12.866 8.22192 13.9099 8.11707L14.3599 8.07187V7.61957V6.16914L19.2876 10.387L14.3599 14.6051V12.6267V12.1127L13.8461 12.1269C9.6812 12.2421 7.07943 13.8766 5.52145 15.5106Z"
      stroke="black"
    />
  </svg>
)

export default ShareLineIcon
