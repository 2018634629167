import { OutsideClickContainer, useWithPopper } from 'simple-core-ui'
import { cloneElement, ReactElement, CSSProperties } from 'react'
import { createPortal } from 'react-dom'
import s from './CustomPopper.scss'
import { Placement } from '@floating-ui/react'

interface Props {
  children: ReactElement
  style?: CSSProperties
  placement?: Placement
  floatingStyles?: CSSProperties
  component: ReactElement
}

const CustomPopper = ({
  children,
  style,
  placement = 'bottom-start',
  floatingStyles: customFloatingStyles = {},
  component
}: Props) => {
  const { isPopperOpen, togglePopper, setReference, setFloating, floatingStyles } = useWithPopper({
    placement
  })

  return (
    <div style={style}>
      <div ref={setReference} onClick={togglePopper} style={{ display: 'inline-block' }}>
        {component}
      </div>

      {isPopperOpen &&
        createPortal(
          <OutsideClickContainer styles={{ height: 'initial' }} closeComponent={togglePopper}>
            <div
              tabIndex={-1}
              style={{ ...floatingStyles, ...customFloatingStyles }}
              ref={setFloating}
              className={s.popper}
            >
              {cloneElement(children, { togglePopper })}
            </div>
          </OutsideClickContainer>,
          document.body
        )}
    </div>
  )
}

export default CustomPopper
