import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import Category from './Category'

const Categories = ({ categories, clickCb, style }) => {
  return (
    <ul style={style} className={'dashboard-list pull-right'} data-testid="categories_container">
      <li>
        {categories.map(({ id, content, isActive }) => (
          <Category
            content={content}
            isActive={isActive}
            clickCb={() => {
              clickCb(id)
            }}
            key={id}
          />
        ))}
      </li>
    </ul>
  )
}

registration.register({
  name: 'Categories',
  description:
    'A group of Category components that can be used as toggles when filtering tables/lists.',
  props: [
    {
      name: 'categories',
      type: 'Array<{content: React.Node, isActive?: boolean, id: string | number}>',
      note: 'The categories to be shown as filters.'
    },
    {
      name: 'clickCb',
      type: 'function -- number => void',
      note:
        'The callback which will be invoked when a category is clicked. The category id is given as an argument.'
    }
  ],
  example: {
    literal: `
<section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
  <Categories
    categories={[
      { content: 'Open', id: 'open', isActive: true },
      { content: 'Closed', id: 'closed' },
      { content: 'Pending', id: 'pending' },
      { content: 'Draft', id: 'draft' }
    ]}
    clickCb={id => { console.log('You clicked category: ' + id) }}
  />
</section>`.trim(),
    render: () => (
      <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Categories
          categories={[
            { content: 'Open', id: 'open', isActive: true },
            { content: 'Closed', id: 'closed' },
            { content: 'Pending', id: 'pending' },
            { content: 'Draft', id: 'draft' }
          ]}
          clickCb={id => {
            // eslint-disable-next-line no-console
            console.log('You clicked category: ' + id)
          }}
        />
      </section>
    )
  },
  category: CATEGORY.NAV,
  path: 'components/Core/Categories/Categories'
})

export default Categories
