import Select from 'react-select'
import s from './PanelSelect.scss'
import cn from 'classnames'

const PanelSelect = ({ className, value, onChange, options, disabled, styles }) => (
  <Select
    className={cn(s.panelSelect, className)}
    isSearchable={false}
    value={value}
    onChange={value => {
      onChange(value)
    }}
    options={options}
    isClearable={false}
    isDisabled={disabled}
  />
)

export default PanelSelect
