const Download = params => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g
        fill="none"
        fillRule="evenodd"
        stroke={params.color || '#000'} // TODO: figure out how to add color to svg like react icons
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <ellipse cx="17.5" cy="6.24" rx="6" ry="5.76"></ellipse>
        <path d="M17.5 3.36v5.76M17.5 9.12l-2.25-2.16M17.5 9.12l2.25-2.16M.5 15.84h21M21.5 12.96v4.8c0 .53-.448.96-1 .96h-19c-.552 0-1-.43-1-.96V5.28c0-.53.448-.96 1-.96h8M8 22.56a6.543 6.543 0 001.5-3.84M14 22.56a6.543 6.543 0 01-1.5-3.84M6.5 22.56h9"></path>
      </g>
    </svg>
  )
}

export default Download
