import { OutsideClickContainer, useWithPopper } from 'simple-core-ui'
import { cloneElement, CSSProperties, ReactElement } from 'react'
import s from './DateRangePopper.scss'
import cn from 'classnames'
import moment from 'moment'
import { Placement } from '@floating-ui/react'
import { DATE_FORMATS } from 'simple-core-ui/utils/constants'

const { DEFAULT_DATE } = DATE_FORMATS

interface Option {
  value: string | number
  label: string
}

interface ClassNameMap {
  pill?: string
  popper?: string
}

interface Props {
  value: Option[] | (() => string)
  children: Array<ReactElement | null>
  placeholder?: string
  style?: CSSProperties
  placement?: Placement
  classNameMap?: ClassNameMap
  isDisabled?: boolean
}

const DateRangePopper = ({
  value,
  children,
  style,
  placeholder = DEFAULT_DATE,
  placement = 'bottom-start',
  classNameMap,
  isDisabled = false
}: Props) => {
  const {
    isPopperOpen: isStartDatePopperOpen,
    togglePopper: toggleStartDatePopper,
    setReference: startDatePopperRef,
    setFloating: setStartDatePopperElement,
    floatingStyles: startDateFloatingStyles
  } = useWithPopper({
    placement
  })

  const {
    isPopperOpen: isEndDatePopperOpen,
    togglePopper: toggleEndDatePopper,
    setReference: endDatePopperRef,
    setFloating: setEndDatePopperElement,
    floatingStyles: endDateFloatingStyles
  } = useWithPopper({
    placement
  })

  const getLabel = (index: 0 | 1) => {
    if (typeof value === 'function') return value()

    if (value[index]) {
      return moment(value[index].value).format(DEFAULT_DATE)
    }
    return placeholder
  }
  return (
    <div style={style} className={s.filter}>
      <div
        ref={startDatePopperRef}
        className={cn(s.pill, classNameMap?.pill, {
          [s.active]: isStartDatePopperOpen || typeof value === 'function' || value.length > 0
        })}
        onClick={() => {
          if (!isDisabled) toggleStartDatePopper()
        }}
      >
        {getLabel(0)}
      </div>

      {isStartDatePopperOpen && children[0] && (
        <OutsideClickContainer
          styles={{ height: 'initial' }}
          closeComponent={toggleStartDatePopper}
        >
          <div
            tabIndex={-1}
            style={startDateFloatingStyles}
            ref={setStartDatePopperElement}
            className={cn(s.popper, classNameMap?.popper)}
          >
            {cloneElement(children[0], { togglePopper: toggleStartDatePopper })}
          </div>
        </OutsideClickContainer>
      )}

      {children[1] && (
        <>
          <span className={s.separator}>and</span>
          <div
            ref={endDatePopperRef}
            className={cn(s.pill, classNameMap?.pill, {
              [s.active]: isEndDatePopperOpen || typeof value === 'function' || value.length > 1
            })}
            onClick={() => {
              if (!isDisabled) toggleEndDatePopper()
            }}
          >
            {getLabel(1)}
          </div>

          {isEndDatePopperOpen && (
            <OutsideClickContainer
              styles={{ height: 'initial' }}
              closeComponent={toggleEndDatePopper}
            >
              <div
                tabIndex={-1}
                style={endDateFloatingStyles}
                ref={setEndDatePopperElement}
                className={cn(s.popper, classNameMap?.popper)}
              >
                {cloneElement(children[1], { togglePopper: toggleEndDatePopper })}
              </div>
            </OutsideClickContainer>
          )}
        </>
      )}
    </div>
  )
}

export default DateRangePopper
