import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const Title = ({ text, rank, style = {} }) => {
  const Tag = rank > 6 ? 'h6' : `h${rank}`
  return <Tag style={style}>{text}</Tag>
}

registration.register({
  name: 'Title',
  description: 'Core component used for providing a title of a given size.',
  props: [
    {
      name: 'text',
      type: 'React.Node',
      note:
        'Text for the title. Most of the time this will be a string, but occasionally you might need to send mark up to include things such as icons.'
    },
    {
      name: 'rank',
      type: 'number (1 | 2 | 3 | 4 | 5 | 6)',
      note: 'Specifies the size of the title with 1 being largest and 6 being lowest.'
    },
    { name: 'style', optional: true, type: 'Object', note: 'Custom styles for the component.' }
  ],
  example: {
    literal: `
<div style={{fontSize: '25px', textAlign: 'center'}}>
  <Title text='Title - Rank 1' rank={1} />
  <Title text='Title - Rank 2' rank={2} />
  <Title text='Title - Rank 3' rank={3} />
  <Title text='Title - Rank 4' rank={4} />
  <Title text='Title - Rank 5' rank={5} />
  <Title text='Title - Rank 6' rank={6} />
</div>`.trim(),
    render: () => (
      <div style={{ fontSize: '25px', textAlign: 'center' }}>
        <Title text="Title - Rank 1" rank={1} />
        <Title text="Title - Rank 2" rank={2} />
        <Title text="Title - Rank 3" rank={3} />
        <Title text="Title - Rank 4" rank={4} />
        <Title text="Title - Rank 5" rank={5} />
        <Title text="Title - Rank 6" rank={6} />
      </div>
    )
  },
  category: CATEGORY.TYPE,
  path: 'components/Core/Title/Title'
})

export default Title
