import { Navigate, Outlet } from 'react-router-dom'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const isDeployedDev = () =>
  /.*eng-.*/.test(window.location.href) || /canary\.simplelegal\.com/.test(window.location.hostname)

const DevRoute = ({ ...props }) =>
  __DEV__ || isDeployedDev() ? <Outlet {...props} /> : <Navigate to="not-found" />

registration.register({
  name: 'DevRoute',
  description:
    'This utility is a way to create a conditional client-side route in development environments only. This includes any deployed instances whose url that contain `eng-` .',
  props: [
    {
      name: 'Router Props',
      type: 'N/A',
      note:
        'This component accepts all the same props as the standard React Router <Route />. Please reference: https://reacttraining.com/react-router/web/api/Route for details.'
    }
  ],
  example: {
    literal: `
<DevRoute
  path={'/sample/route/here'}
  element={() => <h1>Sample Route Content...</h1>}
/>`.trim(),
    render: () => {}
  },
  category: CATEGORY.UTILS,
  path: 'components/Utils/DevRoute/DevRoute'
})

export default DevRoute
