import { Component } from 'react'
import registration from 'docs/registration'
import { EditableFieldFilter } from 'simple-core-ui'

class EditableFieldFilterContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      leftSearchTerm: '',
      rightSearchTerm: '',
      leftSelectedOption: null,
      rightSelectedOption: null,
      leftSelectedOptions: [],
      rightSelectedOptions: []
    }
  }
  getAvailableOptions = () => {
    const { allOptions, selectedOptions } = this.props
    return allOptions.filter(option => !selectedOptions.find(val => val.value === option.value))
  }
  onLeftSearch = term => this.setState({ leftSearchTerm: term })
  onRightSearch = term => this.setState({ rightSearchTerm: term })
  filterOptions = (options, searchTerm) => {
    return options.filter(option => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
  }
  setLeftSelectedValue = (value, valid) => {
    const { leftSelectedOptions } = this.state
    if (valid) {
      if (this.props.isMulti) {
        if (leftSelectedOptions.includes(value)) {
          const index = leftSelectedOptions.findIndex(o => +o === +value)
          const arr = [...leftSelectedOptions]
          arr.splice(index, 1)
          this.setState({
            leftSelectedOptions: arr,
            rightSelectedOptions: []
          })
        } else {
          this.setState(prevState => ({
            leftSelectedOptions: [...prevState.leftSelectedOptions, value],
            rightSelectedOptions: []
          }))
        }
      } else {
        this.setState({
          leftSelectedOption: value,
          rightSelectedOption: null
        })
      }
    }
  }
  setRightSelectedValue = (value, valid) => {
    if (this.props.isMulti) {
      const { rightSelectedOptions } = this.state
      if (rightSelectedOptions.includes(value)) {
        const index = rightSelectedOptions.findIndex(o => +o === +value)
        const arr = [...rightSelectedOptions]
        arr.splice(index, 1)
        this.setState({
          rightSelectedOptions: arr,
          leftSelectedOptions: []
        })
      } else {
        this.setState(prevState => ({
          rightSelectedOptions: [...prevState.rightSelectedOptions, value],
          leftSelectedOptions: []
        }))
      }
    } else {
      this.setState({
        leftSelectedOption: null,
        rightSelectedOption: value
      })
    }
  }
  resetSelectedValue = () =>
    this.setState({
      leftSelectedOption: null,
      rightSelectedOption: null,
      leftSelectedOptions: [],
      rightSelectedOptions: []
    })
  moveValue = (value, from, to) => {
    to = [...to, ...from.filter(e => e.value === value && e.valid)]
    from = from.filter(e => {
      if (!e.valid) return true
      return e.value !== value
    })

    return { from, to }
  }
  moveValues = (options, from, to) => {
    from = from.filter(option => !options.find(o => o === option))
    to = [...to, ...options]

    return { from, to }
  }
  moveAvailable2Selected = value => {
    const availableOptions = this.getAvailableOptions()
    const { selectedOptions, isMulti } = this.props

    const result = isMulti
      ? this.moveValues(
          availableOptions.filter(o => this.state.leftSelectedOptions.includes(o.value)),
          availableOptions,
          selectedOptions
        )
      : this.moveValue(value, availableOptions, selectedOptions)

    this.props.updateSelectedOptions(result.to)

    this.resetSelectedValue()
  }
  moveSelected2Available = value => {
    const availableOptions = this.getAvailableOptions()
    const { selectedOptions, isMulti } = this.props

    const result = isMulti
      ? this.moveValues(
          selectedOptions.filter(o => this.state.rightSelectedOptions.includes(o.value)),
          selectedOptions,
          availableOptions
        )
      : this.moveValue(value, selectedOptions, availableOptions)

    this.props.updateSelectedOptions(result.from)

    this.resetSelectedValue()
  }
  moveAllAvailable2Selected = () => {
    const availableOptions = this.getAvailableOptions()
    const { leftSearchTerm } = this.state
    const { selectedOptions } = this.props

    const result = this.moveValues(
      this.filterOptions(availableOptions, leftSearchTerm),
      availableOptions,
      selectedOptions
    )

    this.props.updateSelectedOptions(result.to)

    this.resetSelectedValue()
  }
  moveAllSelected2Available = () => {
    const availableOptions = this.getAvailableOptions()
    const { rightSearchTerm } = this.state
    const { selectedOptions } = this.props

    const result = this.moveValues(
      this.filterOptions(selectedOptions, rightSearchTerm),
      selectedOptions,
      availableOptions
    )

    this.props.updateSelectedOptions(result.from)

    this.resetSelectedValue()
  }
  render() {
    const availableOptions = this.getAvailableOptions()
    const {
      leftSearchTerm,
      rightSearchTerm,
      leftSelectedOption,
      rightSelectedOption,
      leftSelectedOptions,
      rightSelectedOptions
    } = this.state
    const { selectedOptions, style, optionFormat, isReadOnly, isMulti } = this.props

    return (
      <EditableFieldFilter
        availableOptions={this.filterOptions(availableOptions, leftSearchTerm)}
        selectedOptions={this.filterOptions(selectedOptions, rightSearchTerm)}
        onLeftSearch={this.onLeftSearch}
        onRightSearch={this.onRightSearch}
        leftSearchTerm={leftSearchTerm}
        rightSearchTerm={rightSearchTerm}
        leftSelectedOption={leftSelectedOption}
        rightSelectedOption={rightSelectedOption}
        leftSelectedOptions={leftSelectedOptions}
        rightSelectedOptions={rightSelectedOptions}
        setLeftSelectedValue={this.setLeftSelectedValue}
        setRightSelectedValue={this.setRightSelectedValue}
        moveAvailable2Selected={this.moveAvailable2Selected}
        moveSelected2Available={this.moveSelected2Available}
        moveAllAvailable2Selected={this.moveAllAvailable2Selected}
        moveAllSelected2Available={this.moveAllSelected2Available}
        style={style}
        optionFormat={optionFormat}
        isReadOnly={isReadOnly}
        isMulti={isMulti}
      />
    )
  }
}

registration.register({
  name: 'EditableFieldFilterContainer',
  description:
    'The component used for filtering where you can move the values that you want to filter from the right section to the left one.',
  props: [
    {
      name: 'allOptions',
      type: 'Array<{label: string, value: number), valid: boolean>',
      note: 'All the options avialable for filter.'
    },
    {
      name: 'selectedOptions',
      type: 'Array<{label: string, value: number), valid: boolean>',
      note: 'The options selected for the filter.'
    },
    {
      name: 'updateSelectedOptions',
      type: 'function -- (Array<Option>) => void',
      note: 'A function which is called when options are moved from one side to another.'
    },
    {
      name: 'optionFormat',
      type: 'function -- (option) => option',
      note: 'A function which is called when you want a different format for your option.'
    },
    {
      name: 'isReadOnly',
      type: 'boolean',
      note: "Buttons become disabled so you can't change your selection"
    },
    {
      name: 'isMulti',
      type: 'boolean',
      note:
        'Each option will now have a checkbox so you can select multiple options before moving them right or left'
    }
  ],
  example: {
    literal: `
<EditableFieldFilterContainer
  allOptions={[
    {valid: true, value: -1, label: 'None'},
    {valid: true, value: 7, label: 'France'},
    {valid: true, value: 8, label: 'Germany'},
    {valid: true, value: 3, label: 'Romania'},
    {valid: true, value: 4, label: 'USA'}
  ]}
  selectedOptions={[
    {valid: true, value: -1, label: 'None'},
    {valid: true, value: 7, label: 'France'},
    {valid: true, value: 8, label: 'Germany'}
  ]}
  updateSelectedOptions={() => console.log('Update selected options called')}
/>`.trim(),
    render: theme => (
      <EditableFieldFilterContainer
        allOptions={[
          { valid: true, value: -1, label: 'None' },
          { valid: true, value: 7, label: 'France' },
          { valid: true, value: 8, label: 'Germany' },
          { valid: true, value: 3, label: 'Romania' },
          { valid: true, value: 4, label: 'USA' }
        ]}
        selectedOptions={[
          { valid: true, value: -1, label: 'None' },
          { valid: true, value: 7, label: 'France' },
          { valid: true, value: 8, label: 'Germany' }
        ]}
        // eslint-disable-next-line no-console
        updateSelectedOptions={() => console.log('Update selected options called')}
      />
    )
  }
})

export default EditableFieldFilterContainer
