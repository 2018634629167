import moment from 'moment'
import isPlainObject from 'lodash/isPlainObject'

export const formatDate = (dt, isExtended) => {
  if ((!dt || typeof dt !== 'string') && !(dt instanceof Date)) {
    return
  }

  if (String(new Date(dt)) === 'Invalid Date') {
    return
  }

  return isExtended ? moment(dt).format('MMMM D, YYYY') : moment(dt).format('MM/DD/YY')
}

export const renderWithLineBreaks = s => {
  return s.split('\n').map((item, i) => (
    <span key={i}>
      {item}
      <br />
    </span>
  ))
}

export const fes = (num, str) => {
  if (num === 1) {
    return `${num} ${str.slice(0, str.length - 1)}`
  }

  return `${num} ${str}`
}

export const formatNumber = (amount, minDigits = 2, maxDigits = 2) => {
  if (typeof amount === 'string' && !Number.isNaN(Number(amount))) {
    amount = Number(amount)
  }

  if (typeof amount !== 'number') {
    return
  }

  try {
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits
    })
  } catch (e) {
    console.error(e)
    return 'Invalid Number'
  }
}

export const formatPercentage = (amount, minDigits = 0, maxDigits = 0) => {
  return `${formatNumber(amount, minDigits, maxDigits)}%`
}

export const capitalizeWord = str => {
  if (typeof str !== 'string') {
    return
  }

  return str ? str[0].toUpperCase() + str.slice(1) : ''
}

export const formatResponse = (response, fallback = '') => {
  /*
   * Will return a string if response is of type string.
   *
   * Will break down an object/json response into a list of strings.
   *
   * Will return fallback value if not a plain object or a string.
   */
  if (typeof response === 'string') {
    return response
  }

  if (!isPlainObject(response)) {
    return fallback
  }

  return Object.keys(response).reduce((acc, c) => {
    const value = response[c]

    if (Array.isArray(value)) {
      return [...acc, ...value]
    }

    return [...acc, value]
  }, [])
}
