import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

const { breakpoint, borderWidth, spacing } = DESIGN

const Spacer = ({ value, color }) => (
  <section
    style={{
      width: value,
      border: `${borderWidth.bdW200} dashed ${color}`,
      marginRight: spacing.sp400
    }}
  />
)

const descriptionText = {
  breakpoint100: 'Breakpoint for mobile devices.',
  breakpoint200: 'Breakpoint for tablet devices.',
  breakpoint300: 'Breakpoint for laptop devices.',
  breakpoint400: 'Breakpoint for desktop devices.',
  breakpoint500: 'Breakpoint for larger monitor devices.'
}

const descriptions = getDesignValues(breakpoint).reduce(
  (acc, [name, value], idx) => ({
    ...acc,
    [name]: {
      text: descriptionText[name],
      render: v => <Spacer value={v} color={`hsl(${100 + idx * 50}, 100%, 45%)`} />
    }
  }),
  {}
)

const sass = `
@media (max-width: $bkpt100) {
  .box {
    display: none;
  }
}
`

const js = `
import Media from 'react-media'
import { DESIGN } from 'simple-core-ui'

const { breakpoint: { bkpt100 } } = DESIGN

const Box = () => (
  <Media query={'(max-width: ' + bkpt100 + ')'}>
    {matches => <p>Temp text...</p> }
  </Media>
)
`

const html = `
<span class='hide-at-bkpt100'>Temp text...</span>

<!--
.show-at-bkpt100   ==> show element at breakpoint
.hide-at-bkpt100   ==> hide element at breakpoint
.row-at-bkpt100    ==> flex-direction of row at breakpoint
.column-at-bkpt100 ==> flex-direction of column at breakpoint
-->
`

export default registration.register({
  name: 'Breakpoint',
  description: 'SimpleLegal Design System Breakpoints',
  example: {
    render: () => (
      <DescriptionList pairs={getDesignValues(breakpoint)} descriptions={descriptions} />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
