import cn from 'classnames'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

import s from './Spinner.scss'
import { CSSProperties } from 'react'

interface Props {
  style?: CSSProperties
  className?: string
  hasNewDesign?: boolean
}

const Spinner = ({ style = {}, className, hasNewDesign }: Props) => (
  <span
    data-testid="spinner"
    className={cn(hasNewDesign ? s.newSpinner : s.spinner, className)}
    style={style}
  />
)

registration.register({
  name: 'Spinner',
  description: 'A simple spinner with a linear animation timing.',
  props: [
    { name: 'style', type: 'object', optional: true, note: 'Custom styles to be applied.' },
    { name: 'className', type: 'string', optional: true, note: 'Custom class to be applied.' },
    { name: 'hasNewDesign', type: 'boolean', optional: true, note: 'Use the new spinner design.' }
  ],
  example: {
    literal:
      "<Spinner style={{display: 'block', width: '100%', height: '2em', margin: 0, fontSize: '1.5em'}} />",
    render: () => (
      <>
        <Spinner
          style={{
            display: 'block',
            width: '100%',
            height: '2em',
            margin: 0,
            fontSize: '1.5em'
          }}
        />
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <Spinner hasNewDesign />
        </div>
      </>
    )
  },
  category: CATEGORY.ANIMATE,
  path: 'components/Core/Spinner/Spinner'
})

export default Spinner
