import { CheckboxContainer } from 'simple-core-ui'
import s from './TableHead.scss'
import cn from 'classnames'

const TableHeadCheckbox = ({
  isVisible,
  allRowsSelected,
  selectAllRows,
  size = 'md',
  showCheckbox = true,
  className,
  isCgOutline
}) => (
  <th className={s.checkbox} style={{ visibility: !isVisible && 'hidden' }}>
    {showCheckbox && (
      <CheckboxContainer
        styles={{ borderRadius: '1px' }}
        size={size}
        isChecked={allRowsSelected}
        cb={selectAllRows}
        hasGreyBorder
        isCgOutline={isCgOutline}
        className={cn(s.headerCheckbox, className)}
        testId="select-all-checkbox"
      />
    )}
  </th>
)

TableHeadCheckbox.defaultProps = {
  selectAllRows: () => {}
}

export default TableHeadCheckbox
