import * as React from 'react'
import isEqual from 'lodash/isEqual'
import differenceWith from 'lodash/differenceWith'
import Select from 'react-select'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import PickList from '../../../components/Core/PickList/PickList'

class PickListContainer extends React.Component {
  static defaultProps = {
    label: '',
    numberedLabel: '',
    selectLabel: '',
    emptyText: '',
    initialValues: []
  }

  state = {
    options: this.props.initialValues
  }

  pushUpdates = () => {
    this.props.onUpdate(this.state.options)
  }

  onRemove = optionToRemove =>
    this.setState(
      prevState => ({
        options: prevState.options.filter(option => !isEqual(option, optionToRemove))
      }),
      this.pushUpdates
    )

  onAdd = optionToAdd => {
    if (optionToAdd) {
      const { byLatest } = this.props
      const alreadyAdded = this.state.options.find(option => isEqual(option, optionToAdd))

      alreadyAdded
        ? this.onRemove(optionToAdd)
        : this.setState(
            prevState => ({
              options: [
                ...(byLatest ? [optionToAdd] : []),
                ...prevState.options,
                ...(!byLatest ? [optionToAdd] : [])
              ]
            }),
            this.pushUpdates
          )
    }
  }

  render() {
    return (
      <PickList
        {...this.props}
        options={this.state.options}
        onRemove={this.onRemove}
        onAdd={this.onAdd}
      />
    )
  }
}

registration.register({
  name: 'PickList',
  description:
    'A component that allows for clean / transparent selection of multiple items from a list.',
  props: [
    {
      name: 'label',
      optional: true,
      type: 'string',
      note: 'Optional label that will be displayed above the list of selected values.'
    },
    {
      name: 'numberedLabel',
      optional: true,
      type: 'string',
      note:
        'Optional label that will be displayed on the top right above the selected items. The text passed in should be singular because the `s` is added by the component logic (i.e `invoice` rather than `invoices`).'
    },
    {
      name: 'selectLabel',
      optional: true,
      type: 'string',
      note: 'Optional label that will be displayed above the select used to add values.'
    },
    {
      name: 'emptyText',
      optional: true,
      type: 'string',
      note: 'Optional text that will be shown when no values are selected.'
    },
    {
      name: 'initialValues',
      optional: true,
      type: 'Array<Object>',
      note:
        'Optional initial values to set as selected. If a value has a property of `cannotRemove` the line will not be removable.'
    },
    {
      name: 'renderOption',
      type: '(Option) => React.Node',
      note: 'The render prop that provided a selected option returns a React Node.'
    },
    {
      name: 'renderSelect',
      type: '(onAdd, Options) => React.Node',
      note:
        'The render prop that renders the select component to add new lines. It is provided a callback that should be set on the change handler of the select itself to trigger adding a new option. It is also provided the list of selected options that maybe used to manually filter out selected options from the selects dropdown.'
    },
    {
      name: 'onUpdate',
      type: '(Options) => void',
      note:
        'The callback that will be invoked when an item is added / removed from the pick list, receiving the updated list of options.'
    },
    {
      name: 'height',
      optional: true,
      type: 'string',
      note:
        'You can manually set the height on the select values list if you do not like the default.'
    },
    {
      name: 'byLatest',
      optional: true,
      type: 'boolean',
      note: 'If true the list will be prepended to rather than appended to (default).'
    },
    {
      name: 'readOnly',
      optional: true,
      type: 'boolean',
      note:
        'If true the list will render as read only and the add / remove controls will not be rendered.'
    }
  ],
  example: {
    render: () => {
      const OPTIONS = [
        { label: 'Javascript', description: 'A language built for the browser.', value: 1 },
        {
          label: 'Python',
          description: 'An all around language for servers and filesystems.',
          value: 2
        },
        {
          label: 'Ruby',
          description: 'Made popular with the advent of Rails Framework.',
          value: 3
        },
        {
          label: 'Elm',
          description: 'A simple, functional language to make reliable interfaces.',
          value: 4
        },
        {
          label: 'Scala',
          description: 'An advanced functional language built on top of the JVM.',
          value: 5
        },
        { label: 'Go', description: 'A fast, clean language for modern backends.', value: 6 }
      ]

      return (
        <PickListContainer
          label="Favorite Programming Languages:"
          numberedLabel="language"
          selectLabel="Select your Favorite Languages:"
          emptyText="You have not selected any programming languages."
          initialValues={[OPTIONS[0]]}
          renderOption={({ label, description }) => (
            <React.Fragment>
              <strong style={{ display: 'block' }}>{label}</strong>
              <span style={{ display: 'block' }}>{description}</span>
            </React.Fragment>
          )}
          renderSelect={(onAdd, selectedOptions) => (
            <Select
              name="example-select-picklist"
              placeholder="Select a language to add..."
              noOptionsMessage="No languages found..."
              onChange={onAdd}
              filterOptions={(options, searchValue) => {
                const searchableOptions = differenceWith(options, selectedOptions, isEqual)

                return searchableOptions.filter(({ label }) =>
                  label.toLowerCase().startsWith(searchValue.toLowerCase())
                )
              }}
              options={OPTIONS}
            />
          )}
          // eslint-disable-next-line no-console
          onUpdate={newValues => console.log('New Values Picked:', newValues)}
        />
      )
    }
  },
  category: CATEGORY.FORM,
  path: 'containers/Core/PickListContainer/PickListContainer'
})

export default PickListContainer
