import { Component } from 'react'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

class OutsideClickContainer extends Component {
  wrapper
  componentDidMount() {
    if (document.body) {
      document.body.addEventListener('mousedown', this.handleClick)
    }
  }
  componentWillUnmount() {
    if (document.body) {
      document.body.removeEventListener('mousedown', this.handleClick)
    }
  }
  setWrapper = el => {
    this.wrapper = el
  }
  handleClick = event => {
    if (typeof this.props.closeComponent !== 'function') {
      return
    }
    const { target } = event
    if (this.wrapper && target instanceof HTMLElement) {
      if (target !== this.wrapper && !this.wrapper.contains(target)) {
        this.props.closeComponent()
      }
    }
  }
  render() {
    const styles = { height: '100%', ...this.props.styles }
    return (
      <div ref={this.setWrapper} style={styles}>
        {this.props.children}
      </div>
    )
  }
}

registration.register({
  name: 'OutsideClickContainer',
  description:
    'A utility for triggering a callback when a click occurs outside of a given component.',
  props: [
    { name: 'children', type: 'React.Node', note: 'The content within the clickable area.' },
    {
      name: 'styles',
      optional: true,
      type: 'Object',
      note: 'Custom styles applied to the wrapper around the clickable area.'
    },
    {
      name: 'closeComponent',
      type: 'function',
      note: 'The callback function to trigger when the area outside the clickable area is clicked.'
    }
  ],
  example: {
    literal: `
<OutsideClickContainer
  closeComponent={() => {console.log('You clicked outside the OutsideClickContainer!')}}
>
  <h1 style={{background: 'mediumseagreen', padding: '1em', textAlign: 'center', color: 'white'}}>Click Outside Me!</h1>
</OutsideClickContainer>
    `.trim(),
    render: () => (
      <OutsideClickContainer
        closeComponent={() => {
          // eslint-disable-next-line no-console
          console.log('You clicked outside the OutsideClickContainer!')
        }}
      >
        <h1
          style={{
            background: 'mediumseagreen',
            padding: '1em',
            textAlign: 'center',
            color: 'white'
          }}
        >
          Click Outside Me!
        </h1>
      </OutsideClickContainer>
    )
  },
  category: CATEGORY.UTILS,
  path: 'containers/Utils/OutsideClickContainer/OutsideClickContainer'
})

export default OutsideClickContainer
