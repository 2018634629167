import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const Entries = ({ page, pageSize, total, filtered, language }) => {
  const start = pageSize * (page - 1) + 1
  const end = pageSize * page > filtered ? filtered : pageSize * page

  const optionalStr = total && filtered ? ` ${start} to ${end} of ` : ' '
  const optionalFiltered = total > filtered ? ` (filtered from ${total})` : ''
  const { singular, plural } = language

  return (
    <section>
      <span>{`Showing${optionalStr}${filtered} ${
        filtered === 1 ? singular : plural
      }${optionalFiltered}`}</span>
    </section>
  )
}

registration.register({
  name: 'Entries',
  description: 'A component used to show the number of entries in a list/table',
  props: [
    { name: 'page', type: 'number', note: 'The current page that you are on.' },
    { name: 'pageSize', type: 'number', note: 'The size of the results on a given page.' },
    { name: 'total', type: 'number', note: 'Total number in the given sequence.' },
    {
      name: 'filtered',
      type: 'number',
      note: 'Total number of filtered items in the given sequence.'
    },
    {
      name: 'language',
      optional: true,
      type: 'string',
      note:
        'Default { singular: "entry", plural: "entries" }. Text used to describe the type of objects that are being paginated.'
    }
  ],
  example: {
    literal: `
<section style={{textAlign: 'center'}}>
  <Entries page={2} pageSize={25} total={1000} filtered={700} />
</section>`.trim(),
    render: () => (
      <section style={{ textAlign: 'center' }}>
        <Entries page={2} pageSize={25} total={1000} filtered={700} />
      </section>
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Entries/Entries'
})

Entries.defaultProps = {
  language: {
    singular: 'entry',
    plural: 'entries'
  }
}

export default Entries
