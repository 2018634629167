import { CSSProperties, useMemo, useRef } from 'react'

import { getNumericFromPixel } from 'simple-core-ui/utils/helpers'

type Ref =
  | React.MutableRefObject<HTMLInputElement | null>
  | React.MutableRefObject<HTMLTextAreaElement | null>
  | undefined

interface Params {
  autoWidth?: boolean
  autoHeight?: boolean
  ref?: Ref
  value?: string
  placeholder?: string
  paddingLeft?: CSSProperties['paddingLeft']
  paddingRight?: CSSProperties['paddingRight']
}

type Return = Partial<{
  width: CSSProperties['width']
  height: CSSProperties['height']
}>

const useAutosizeField = ({
  autoWidth = false,
  autoHeight = false,
  ref,
  value,
  placeholder,
  paddingLeft = 0,
  paddingRight = 0
}: Params): Return => {
  const canvasContext = useRef(document.createElement('canvas').getContext('2d'))
  canvasContext.current!.font = '14px Lato'

  const width = useMemo(() => {
    if (!autoWidth) return null

    const textToMeasure = String(value || placeholder)
    const { width } = canvasContext.current!.measureText(textToMeasure)

    const numericPaddingLeft =
      typeof paddingLeft === 'string' ? getNumericFromPixel(paddingLeft) : paddingLeft
    const numericPaddingRight =
      typeof paddingRight === 'string' ? getNumericFromPixel(paddingRight) : paddingRight

    const padding = numericPaddingLeft + numericPaddingRight

    return `calc(${Math.ceil(width) + padding + 'px'} + 1ch)`
  }, [autoWidth, paddingLeft, paddingRight, placeholder, value])

  const height = useMemo(() => {
    if (!autoHeight) return null

    if (ref && ref.current instanceof HTMLTextAreaElement) {
      ref.current.style.height = '0px'
      const scrollHeight = value ? ref.current.scrollHeight : 0
      const height = scrollHeight + 'px'
      ref.current.style.height = height

      return height
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoHeight, ref?.current, value])

  return (() => {
    const obj: Return = {
      ...(width ? { width } : {}),
      ...(height ? { height } : {})
    }

    return obj
  })()
}

export default useAutosizeField
