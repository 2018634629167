import swal from 'sweetalert'

const withValidation = (validateCb, cb, opts) => {
  const { title, text, acceptText = 'Ok', buttons } = opts
  return async (...args) => {
    const isInvalid = validateCb()
    if (isInvalid) {
      await swal({
        icon: 'warning',
        title,
        text
      })
    } else {
      return cb(...args)
    }
  }
}

export default withValidation
