import { Component } from 'react'
import { createPortal } from 'react-dom'

let modalRoot = document.getElementById('react-modal-portal')

if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'react-modal-portal')
  document.body.appendChild(modalRoot)
}

type Props = {
  children?: JSX.Element
}

class ModalPortal extends Component<Props> {
  el: HTMLElement

  constructor(props: Props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el)
    }
  }

  componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el)
    }
  }

  render() {
    return createPortal(this.props.children, this.el)
  }
}

export default ModalPortal
