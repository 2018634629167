import { useEffect, CSSProperties, useRef, KeyboardEvent } from 'react'
import classNames from 'classnames'
import { FaTimes } from 'react-icons/fa'
import ModalPortal from './ModalPortal'
import scopeTab from './scopeTab'

import { Spinner, Title, Button, If } from 'simple-core-ui'

import s from './Modal.scss'

type openModal = () => void

const getNewButtonStyles = (isCg?: boolean) => ({
  height: '40px',
  minWidth: '67px',
  borderRadius: '4px',
  ...(isCg ? { fontFamily: 'Open Sans', padding: '0 32px', borderRadius: '8px' } : {})
})

interface Props {
  isVisible: boolean
  content: JSX.Element | string
  children?: (arg0?: openModal) => void
  title?: JSX.Element | string
  confirmCb?: () => void
  secondaryConfirmCb?: () => void
  cancelCb?: () => void
  openModal?: openModal
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fp' | 'fs'
  confirmText?: JSX.Element | string
  secondaryConfirmText?: JSX.Element | string
  cancelText: string
  isDisabled?: boolean
  isLoading?: boolean
  hideButtons?: boolean
  hideCancelBtn?: boolean
  animate?: boolean
  hasCloseIcon?: boolean
  isDisabledSecondary?: boolean
  footerContent?: JSX.Element | string
  subtitle?: string
  contentStyle?: CSSProperties
  footerStyle?: CSSProperties
  subtitleStyle?: CSSProperties
  isFooterContentGray?: boolean
  testid?: string
  hasNewButtons?: boolean
  contentClassName?: string
  hideScroll?: boolean
  isCg?: boolean
  titleStyles?: CSSProperties
  wrapperStyles?: CSSProperties
}

const isTabKey = (event: KeyboardEvent) => event.code === 'Tab' || event.key === 'Tab'

const Modal = ({
  isVisible,
  content,
  children,
  title,
  confirmCb,
  secondaryConfirmCb,
  cancelCb,
  openModal,
  size,
  confirmText = 'Confirm',
  secondaryConfirmText,
  cancelText,
  isDisabled,
  isLoading,
  hideButtons,
  hideCancelBtn,
  animate,
  hasCloseIcon,
  isDisabledSecondary,
  footerContent,
  subtitle,
  titleStyles,
  contentStyle,
  footerStyle,
  subtitleStyle,
  isFooterContentGray = false,
  testid,
  hasNewButtons = false,
  contentClassName,
  hideScroll = true,
  isCg,
  wrapperStyles
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isVisible) {
      if (contentRef.current) {
        contentRef.current.focus()
      }
      if (!hideScroll) return
      if (document.body) {
        document.body.style.overflow = 'hidden'
      }
    }
    return () => {
      if (!hideScroll) return
      if (document.body) {
        document.body.style.overflow = 'scroll'
      }
    }
  }, [hideScroll, isVisible])

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (isTabKey(event) && contentRef.current) {
      scopeTab(contentRef.current, event)
    }
  }

  return (
    <>
      <ModalPortal>
        <div
          data-testid={testid}
          className={classNames(s.container, { [s.hidden]: !isVisible, [s.animate]: animate })}
          onKeyDown={handleKeyDown}
          ref={contentRef}
          tabIndex={-1}
        >
          <section
            className={classNames({
              [s.main]: true,
              [s.animate]: animate,
              [s.xsmall]: size === 'xs',
              [s.small]: size === 'sm',
              [s.medium]: size === 'md',
              [s.large]: size === 'lg',
              [s.extraLarge]: size === 'xl',
              [s.fullPage]: size === 'fp',
              [s.fullScreen]: size === 'fs'
            })}
            style={wrapperStyles}
          >
            {hasCloseIcon && <FaTimes className={s.closeModal} onClick={cancelCb} />}
            <If condition={!!title}>
              <section className={s.header}>
                <Title
                  text={title}
                  rank={2}
                  style={{ overflowWrap: 'break-word', margin: 0, ...titleStyles }}
                />
                {subtitle && (
                  <h3
                    className={s.subtitle}
                    style={{ marginBottom: !content ? 8 : 0, ...subtitleStyle }}
                  >
                    {subtitle}
                  </h3>
                )}
              </section>
            </If>
            <main>
              <section
                className={classNames(
                  s.content,
                  { [s.loading]: isLoading ?? false },
                  contentClassName
                )}
                style={contentStyle}
              >
                <If condition={isLoading ?? false}>
                  <Spinner className={s.spinner} />
                </If>
                {content}
              </section>
            </main>
            <If condition={!hideButtons && !!(cancelCb || confirmCb || secondaryConfirmCb)}>
              <section
                style={footerStyle}
                className={classNames(s.footer, {
                  [s.bottomContent]: isFooterContentGray,
                  [s.fullScreenFooterContent]: size === 'fs'
                })}
              >
                <If condition={!!footerContent}>{footerContent!}</If>
                <section className={s.footerButtons}>
                  <If condition={!!cancelCb && !hideCancelBtn}>
                    <Button
                      onClick={cancelCb}
                      {...(hasNewButtons
                        ? {
                            isPrimary: true,
                            isOutline: true,
                            hasNewDesign: true,
                            isCg,
                            style: getNewButtonStyles(isCg)
                          }
                        : { isSecondary: true })}
                    >
                      {cancelText}
                    </Button>
                  </If>
                  <If condition={!!secondaryConfirmCb && !!secondaryConfirmText}>
                    <Button
                      testid="secondary_confirm_btn"
                      isDisabled={isDisabledSecondary}
                      onClick={secondaryConfirmCb}
                      {...(hasNewButtons
                        ? {
                            isPrimary: true,
                            isOutline: true,
                            isCg,
                            hasNewDesign: true,
                            style: getNewButtonStyles(isCg)
                          }
                        : { isSecondary: true })}
                    >
                      {secondaryConfirmText}
                    </Button>
                  </If>
                  <If condition={!!confirmCb}>
                    <Button
                      ariaLabel={`${title}-submit`}
                      onClick={confirmCb}
                      isDisabled={isDisabled}
                      testid={`${title}_submit`}
                      {...(hasNewButtons
                        ? {
                            isPrimary: true,
                            hasNewDesign: true,
                            isCg,
                            isFill: isCg,
                            style: getNewButtonStyles(isCg)
                          }
                        : {})}
                    >
                      {confirmText}
                    </Button>
                  </If>
                </section>
              </section>
            </If>
          </section>
        </div>
      </ModalPortal>
      {children ? children(openModal) : null}
    </>
  )
}

Modal.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  animate: true,
  hasCloseIcon: true,
  size: 'lg'
}

export default Modal
