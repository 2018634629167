const FolderIcon = params => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle' }}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#000">
          <g>
            <path d="M11.236 6a1 1 0 01-.894-.553l-.948-1.894A1 1 0 008.5 3H2a1 1 0 00-1 1v16a1 1 0 001 1h20a1 1 0 001-1V7a1 1 0 00-1-1H11.236z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FolderIcon
