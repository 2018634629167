import classNames from 'classnames'
import { SimpleContext } from 'simple-core-ui'

import s from './InfoBox.scss'

const InfoBox = ({
  message,
  details,
  icon,
  toggleDetails,
  isExpanded,
  className = '',
  style = {},
  theme
}) => (
  <SimpleContext.Consumer>
    {({ theme }) => {
      return (
        <section
          className={classNames(s.container, s[theme], { [className]: className })}
          style={style}
        >
          {icon && <span className={s.icon}>{icon}</span>}
          <section className={s.messageContainer}>
            <section className={s.message}>{message}</section>
            {isExpanded && <section className={s.details}>{details}</section>}
          </section>
          {details && (
            <span className={s.info} onClick={toggleDetails}>
              {isExpanded ? 'Hide Details' : 'Show Details'}
            </span>
          )}
        </section>
      )
    }}
  </SimpleContext.Consumer>
)

export default InfoBox
