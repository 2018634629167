import * as React from 'react'
import { IoMdHelpCircleOutline } from 'react-icons/io'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import { InfoBox } from 'simple-core-ui'

class InfoBoxContainer extends React.Component {
  static defaultProps = {
    initExpanded: false
  }

  state = {
    isExpanded: this.props.initExpanded
  }

  toggleDetails = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }))
  }

  render = () => <InfoBox {...this.props} {...this.state} toggleDetails={this.toggleDetails} />
}

registration.register({
  name: 'InfoBoxContainer',
  description:
    'This component should be used for in app messages that need to be shown inline. It provides a convenient way to toggle details if provided.',
  props: [
    {
      name: 'initExpanded',
      type: 'boolean',
      optional: true,
      defaultValue: 'false',
      note: 'Will set the initial state of the InfoBoxContainer to open if true.'
    },
    {
      name: 'message',
      type: 'React.Node',
      note: 'The main message to be displayed. It will always be visible.'
    },
    {
      name: 'details',
      type: 'React.Node',
      optional: true,
      defaultValue: 'null',
      note: 'The details that will be togglable.'
    },
    {
      name: 'icon',
      type: 'React.Node',
      optional: true,
      defaultValue: 'null',
      note: 'The optional icon that can be set to display to the left of the message.'
    },
    {
      name: 'className',
      type: 'string',
      optional: true,
      defaultValue: "''",
      note: 'An optional className to set on the container portion of the component.'
    },
    {
      name: 'style',
      type: 'Object',
      optional: true,
      defaultValue: '{}',
      note: 'Custom styles for the component.'
    }
  ],
  example: {
    literal: `
<InfoBoxContainer
  message='This message will self destruct in 5 minutes...'
  details='If this message is displayed for longer than 5 minutes it will self implode into an infinite abyss. Please refrain from causing any catastrophic blackholes due to the destruction of this message.'
  icon={<IoMdHelpCircleOutline />}
  style={{background: 'white'}}
/>`.trim(),
    render: theme => (
      <InfoBoxContainer
        message="This message will self destruct in 5 minutes..."
        details="If this message is displayed for longer than 5 minutes it will self implode into an infinite abyss. Please refrain from causing any catastrophic blackholes due to the destruction of this message."
        icon={<IoMdHelpCircleOutline />}
        style={{ background: 'white' }}
      />
    )
  },
  category: CATEGORY.INFO,
  path: 'containers/Core/InfoBoxContainer/InfoBoxContainer'
})

export default InfoBoxContainer
