import { SVGProps } from 'react'

const AppleCalendarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 2160 2160"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">{`.apple-cal-st1{ fill: #FF0000 }`}</style>
      <g>
        <path
          fill="#FFFFFF"
          d="M2160,675.7c0-25.8,0-51.6-0.1-77.4c-0.1-21.7-0.4-43.5-1-65.2c-1.3-47.3-4.1-95.1-12.5-141.9   c-8.5-47.5-22.5-91.7-44.5-134.9c-21.6-42.4-49.8-81.2-83.4-114.9c-33.7-33.6-72.4-61.8-114.9-83.4c-43.1-22-87.3-35.9-134.8-44.4   c-46.8-8.4-94.6-11.2-141.9-12.5c-21.7-0.6-43.5-0.8-65.2-1C1535.9,0,1510.1,0,1484.3,0H675.7c-25.8,0-51.6,0-77.4,0.1   c-21.7,0.1-43.5,0.4-65.2,1C485.8,2.4,438,5.2,391.2,13.6c-47.5,8.5-91.7,22.5-134.8,44.4c-42.4,21.6-81.3,49.8-114.9,83.4   c-33.7,33.7-61.8,72.4-83.4,114.9c-22,43.2-35.9,87.4-44.5,134.9C5.2,438,2.4,485.8,1.2,533.1c-0.6,21.7-0.9,43.5-1,65.2   C0,624.1,0,649.9,0,675.7v808.6c0,25.8,0,51.6,0.2,77.4c0.1,21.7,0.4,43.5,1,65.2c1.3,47.3,4.1,95.1,12.5,141.9   c8.5,47.5,22.5,91.8,44.5,134.9c21.6,42.4,49.8,81.2,83.4,114.8c33.6,33.7,72.4,61.9,114.9,83.5c43.1,22,87.3,35.9,134.8,44.4   c46.8,8.4,94.6,11.2,141.9,12.5c21.7,0.6,43.5,0.8,65.2,1c25.8,0.1,51.6,0.1,77.4,0.1h808.6c25.8,0,51.6,0,77.4-0.1   c21.7-0.1,43.5-0.4,65.2-1c47.3-1.3,95.1-4.1,141.9-12.5c47.5-8.5,91.7-22.5,134.8-44.4c42.4-21.6,81.2-49.8,114.9-83.5   c33.7-33.6,61.8-72.4,83.4-114.8c22-43.2,35.9-87.4,44.5-134.9c8.4-46.8,11.2-94.5,12.5-141.9c0.6-21.7,0.8-43.5,1-65.2   c0.1-25.8,0.1-51.6,0.1-77.4V675.7z"
        />
        <g>
          <path d="M806.2,1767.1V762.2H764L508,931.7v42.9l253.1-165.7h3v958.3H806.2L806.2,1767.1z" />
          <path d="M1056.3,762.2v38.5h552v3l-443.3,963.5h47.4L1652,802.9v-40.7L1056.3,762.2L1056.3,762.2z" />
        </g>
        <path
          className="apple-cal-st1"
          d="M392.5,565.1V347.8h0.8l85.9,195.1l28.5,0l85.6-195.1h1v217.3h28.5V277.3h-30.2L494,507.9h-0.8l-98.9-230.5   h-30.2v287.7H392.5L392.5,565.1z"
        />
        <path
          className="apple-cal-st1"
          d="M777.7,340.6c-60.1,0-93.5,43.7-93.5,102.9v21.7c0,59.5,33.1,103.4,93.5,103.4c60.3,0,93.2-43.9,93.2-103.4   v-21.7C871,384.3,837.6,340.6,777.7,340.6L777.7,340.6z M777.7,367.8c39.2,0,62.2,29.3,62.2,77.2l0,18.6c0,48.1-23,77.4-62.2,77.4   c-39.5,0-62.4-29.5-62.4-77.4V445C715.3,397.2,738.3,367.8,777.7,367.8L777.7,367.8z"
        />
        <path
          className="apple-cal-st1"
          d="M929,565.1h31V429.8c0-31.6,17.3-60.8,57.8-60.8c34.6,0,56.8,20.9,56.8,58.6v137.3h31v-142   c0-53.6-34-82.5-79.5-82.5c-36.3,0-57,19-65.2,33.8H960v-30.2h-31L929,565.1L929,565.1z"
        />
        <path
          className="apple-cal-st1"
          d="M1248.3,340.4c-54.9,0-88.4,42.8-88.4,102.7v22.6c0,61,31,102.7,88.4,102.7c31.7,0,54.4-16,66.2-37.8h0.8   l0,34.4h29.3V261.9h-31v113.9h-0.8C1302.1,356.7,1279.3,340.4,1248.3,340.4L1248.3,340.4z M1251.1,368.1   c39.2,0,63.3,30.8,63.3,76.4v20.5c0,47.5-23.4,75.9-62.9,75.9c-35,0-60.5-25.5-60.5-76.1v-20   C1190.9,393.2,1216.9,368.1,1251.1,368.1L1251.1,368.1z"
        />
        <path
          className="apple-cal-st1"
          d="M1536.5,536.4h0.8v28.7h30.2V411.5c0-45.4-33.3-70.9-79.3-70.9c-51.2,0-77.6,26.8-80.2,65.2h29.3   c2.5-23.4,19.2-38.4,49.6-38.4c31.4,0,49.6,16.7,49.6,47.7v23.8h-60.1c-50.4,0.2-77,24.7-77,62.9c0,40.5,29.3,66.9,72.2,66.9   C1505.3,568.6,1525.9,554.1,1536.5,536.4L1536.5,536.4z M1478.9,541.9c-24.9,0-47.5-13.3-47.5-40.7c0-21.5,13.7-36.9,46-36.9h59.1   v27.2C1536.5,521.2,1511.8,541.9,1478.9,541.9z"
        />
        <path
          className="apple-cal-st1"
          d="M1795.9,344.2h-33.1L1699.7,529h-1.1l-63.1-184.8H1601l82.5,224.4l-4.2,13.5c-6.5,22.8-17.7,36.1-43.7,36.1   c-4.8,0-12.7-0.6-16.2-1.3v26.4c5.5,0.8,13.7,1.7,21.1,1.7c43,0,57.8-29.5,69.4-61.4l6.3-16.5L1795.9,344.2L1795.9,344.2z"
        />
      </g>
    </svg>
  )
}

export default AppleCalendarIcon
