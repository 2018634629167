import classNames from 'classnames'

import s from './Grid.scss'

const GridRow = ({ children, style, className }) => (
  <section className={classNames(s.row, className)} style={style}>
    {children}
  </section>
)

GridRow.defaultProps = {
  style: {},
  className: ''
}

export default GridRow
