import * as React from 'react'
import classNames from 'classnames'
import ModalPortal from './ModalPortal'

import s from './Modal.scss'

type openModal = () => void

type FormArgs = {
  title?: string
  confirmText?: string | JSX.Element
  cancelText?: string
  cancelCb?: () => void
  confirmCb?: () => void
}

type Props = {
  isVisible: boolean
  children?: (arg0?: openModal) => void
  title?: string
  confirmCb?: () => void
  cancelCb?: () => void
  openModal?: openModal
  size: 'sm' | 'md' | 'lg' | 'xl' | 'fp'
  confirmText?: JSX.Element | string
  cancelText: string
  animate?: boolean
  hasCloseIcon?: boolean
  isDisabledSecondary?: boolean
  footerContent?: JSX.Element | string
  subtitle?: string
  contentStyle?: React.CSSProperties
  footerStyle?: React.CSSProperties
  isFooterContentGray?: boolean
  testid?: string
  renderForm: (arg0: FormArgs) => JSX.Element
}

const FormModal = ({
  isVisible,
  children,
  title,
  confirmCb,
  cancelCb,
  openModal,
  size,
  confirmText,
  cancelText,
  renderForm,
  animate
}: Props) => (
  <>
    <ModalPortal>
      <div className={classNames(s.container, { [s.hidden]: !isVisible, [s.animate]: animate })}>
        <section
          className={classNames({
            [s.main]: true,
            [s.animate]: animate,
            [s.small]: size === 'sm',
            [s.medium]: size === 'md',
            [s.large]: size === 'lg',
            [s.extraLarge]: size === 'xl',
            [s.fullPage]: size === 'fp'
          })}
        >
          {renderForm({ title, confirmText, cancelText, cancelCb, confirmCb })}
        </section>
      </div>
    </ModalPortal>
    {children ? children(openModal) : null}
  </>
)

FormModal.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  animate: true
}

export default FormModal
