import registration from 'docs/registration'
import { MARKDOWN } from 'docs/constants'

const markdown = `
# When Should I Not Register a Component?

A component should **NOT** be registered if:

1. It does not belong in the shared \`simple-core-ui\`.
2. It (or something similar) has already been registered.
3. It is not something that is intended to be publicly utilized.

---

# Component Category

A registered component should belong to a _category_ to aid in the identification and taxonomy of the UI elements within the application.

Here are some categories listed with descriptions around usage:

* **Animation / Transition:** Primary goal is to guide the user through an transition or loading state.
* **Informational:** Details or high-level information that should surface to the user.
* **Form Elements:** Utilized in form construction / data modification and updating.
* **Layout:** Primary goal is to structure content in some fashion.
* **Navigation / Filtration:** Primary objective is to navigate a user to a new state / filter down result sets.
* **Overlays:** Wrap other UI in order to provide some new UI in the forefront. (i.e confirmation/modal overlay)
* **Tables / Charts:** For components dealing with tabular data.
* **Typography:** Font/typeface modifications or whose primary objective is to render a body of text.
* **Utilities:** Helpers that can be used to help in development (i.e logical components like \`<Conditional Render />\`)

> **Note**: It is important to always use the constants provided when setting the **category** for a registered component. For example if I wanted the _Informational_ category I would set it as \`CATEGORY.INFO\`. The categories can found in / imported from \`SimpleLegal/static/simple-core-ui/docs/constants.js\`.

---

# Registeration Example

The _registration object_ which handles tracking registered components can be found at \`SimpleLegal/static/simple-core-ui/docs/registration.js\`.

First, here is a basic example of a component being registered:

\`\`\`
import React from 'react'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

type Props = {
  text: 'string',
  isUpper?: boolean
}

const Title = ({text, isUpper}: Props) => (
  <h1>{isUpper ? text.toUpperCase() : text}</h1>
)

registration.register({
  name: 'Title',
  description: 'For displaying title text in the UI.',
  category: CATEGORY.TYPE,
  props: [
    { name: 'text', type: 'string', note: 'The title text to render.' },
    { name: 'isUpper', type: 'boolean', optional: true, note: 'If true it will render title in all caps.' }
  ],
  example: {
    literal: \`
      <React.Fragment>
        <Title text='Normal Title' />
        <Title text='Upper Title' isUpper />
      </React.Fragment>
    \`,
    render: theme => (
      <React.Fragment>
        <Title text='Normal Title' />
        <Title text='Upper Title' isUpper />
      </React.Fragment>
    )
  },
  path: 'components/Core/Title/Title'
})

export default Title
\`\`\`

---

# Registeration Explained

The example above illustrates a simple component \`Title\` which renders an \`h1\` with the content optionally uppercased.

The component is exported as a default export (as with most components in our app).

The _registration object_ is provided with a configuration which sets the following meta information for the component:

* **name:** The name of the component.
* **description:** A description of the component and what it does.
* **category:** A valid category the component should belong to.
* **props:** An array of \`prop\` description objects used to document the props for the component.
  * **prop.name:** The name of the prop.
  * **prop.type:** The type of the prop.
  * **prop.note:** A note about / description of the prop.
  * **prop.optional:** If \`true\` it will show the prop as optional when listed.
  * **prop.defaultValue:** If provided it will show up as a default value when listed.
* **example:** An object configuration showing both the literal code and working example.
  * **literal:** The literal text of the example. (This is convenient to provide for easy of copying/pasting)
  * **render:** A function that should return some JSX to render. It will receive the _theme_ in which the styleguide is being rendered so that a component may render differently between CouselGO and Ebill.
* **path:** A string representing the file path relative to being in the \`simple-core-ui\` directory. When included it will render a link to the file in [GitLab](https://gitlab.com/SimpleLegal/SimpleLegal). The file extension should not be included.
  * Example: The _path_ for \`SimpleLegal/static/simple-core-ui/components/Core/Avatar/Avatar.js\` would be \`components/Core/Avatar/Avatar\`

> **Naming Components**: A component should always have a name that is **unique** within the application and is **descriptive** of what it does / what action it performs.

---

# Finishing Thoughts

Please be mindful to not reinvent the wheel by registering something that already duplicates the work of another, existing component.

**When you update a component that is registered be sure to update the registration information as necessary!**

📜 You are now all ready to start documenting and registering components! ✍🏽
`.trim()

registration.register({
  name: 'Registering Components',
  description:
    'This doc will explain how you can register a component within Simple-Core-UI so that it may show up in the styleguide for documentation purposes.',
  category: MARKDOWN.GETTING_STARTED,
  props: [],
  markdown
})

export default markdown
