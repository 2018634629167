import GridRow from './GridRow'
import GridColumn from './GridColumn'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const Grid = { Row: GridRow, Column: GridColumn }

registration.register({
  name: 'Grid',
  description:
    'This compnent is broken into two subcomponents <Grid.Row /> and <Grid.Column /> which can be utilized together to render content in a proper 12 column grid structure.',
  props: [
    {
      name: 'children (Grid.Row)',
      type: 'React.Node',
      note:
        'The content to be rendered within the row. Will almost always be one or more Grid.Column component instances.'
    },
    {
      name: 'style (Grid.Row)',
      optional: true,
      type: 'object',
      note: 'Custom styles to be applied to the Row wrapper.'
    },
    {
      name: 'className (Grid.Row)',
      optional: true,
      type: 'string',
      note: 'Custom className to be applied to the Row wrapper.'
    },
    {
      name: 'children (Grid.Column)',
      type: 'React.Node',
      note: 'The content to be rendered within the column.'
    },
    {
      name: 'span (Grid.Column)',
      type: 'number (1 - 12)',
      optional: true,
      defaultValue: '12',
      note: 'The number of columns the column should span in the surrounding row.'
    },
    {
      name: 'breakpoint (Grid.Column)',
      type: 'max | phone | tablet | laptop | desktop | monitor',
      optional: true,
      defaultValue: 'phone',
      note:
        'If max is set the column will always span the set span no matter the size of the screen. Otherwise, at the given breakpoint the column will span 100% of the device.'
    },
    {
      name: 'style (Grid.Column)',
      optional: true,
      type: 'object',
      note: 'Custom styles to be applied to the Column wrapper.'
    },
    {
      name: 'className (Grid.Column)',
      optional: true,
      type: 'string',
      note: 'Custom className to be applied to the Column wrapper.'
    }
  ],
  example: {
    literal: `
const Colored = ({color, text}) => (
  <div
    style={{
      height: '200px',
      backgroundColor: color,
      color: 'white',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {text}
  </div>
)

const Title = ({text}) => <h3 style={{margin: '1em 0'}}>{text}</h3>

const { Row, Column } = Grid

return (
  <section>
    <Title text='Standard Grid (w/out breakpoints):' />
    <Row>
      {new Array(12).fill(1).map((span, idx) => (
        <Column span={span} breakpoint='max' key={idx}>
          <Colored color='#22bab0' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(6).fill(2).map((span, idx) => (
        <Column span={span} breakpoint='max' key={idx}>
          <Colored color='#00b4e3' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(4).fill(3).map((span, idx) => (
        <Column span={span} breakpoint='max' key={idx}>
          <Colored color='#b87ff0' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(3).fill(4).map((span, idx) => (
        <Column span={span} breakpoint='max' key={idx}>
          <Colored color='#ff4fa7' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(2).fill(6).map((span, idx) => (
        <Column span={span} breakpoint='max' key={idx}>
          <Colored color='#ff6657' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(1).fill(12).map((span, idx) => (
        <Column span={span} breakpoint='max' key={idx}>
          <Colored color='#2dba7d' text={span} />
        </Column>
      ))}
    </Row>
    <Title text='Responsive Grid (w/ breakpoints):' />
    <Row>
      {new Array(4).fill(3).map((span, idx) => (
        <Column span={span} key={idx} breakpoint='tablet'>
          <Colored color='#b87ff0' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(3).fill(4).map((span, idx) => (
        <Column span={span} key={idx}>
          <Colored color='#ff4fa7' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(2).fill(6).map((span, idx) => (
        <Column span={span} key={idx}>
          <Colored color='#ff6657' text={span} />
        </Column>
      ))}
    </Row>
    <Row>
      {new Array(1).fill(12).map((span, idx) => (
        <Column span={span} key={idx}>
          <Colored color='#2dba7d' text={span} />
        </Column>
      ))}
    </Row>
  </section>`.trim(),
    render: () => {
      const Colored = ({ color, text }) => (
        <div
          style={{
            height: '200px',
            backgroundColor: color,
            color: 'white',
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {text}
        </div>
      )

      const Title = ({ text }) => <h3 style={{ margin: '1em 0' }}>{text}</h3>

      const { Row, Column } = Grid

      return (
        <section>
          <Title text="Standard Grid (w/out breakpoints):" />
          <Row>
            {new Array(12).fill(1).map((span, idx) => (
              <Column span={span} breakpoint="max" key={idx}>
                <Colored color="#22bab0" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(6).fill(2).map((span, idx) => (
              <Column span={span} breakpoint="max" key={idx}>
                <Colored color="#00b4e3" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(4).fill(3).map((span, idx) => (
              <Column span={span} breakpoint="max" key={idx}>
                <Colored color="#b87ff0" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(3).fill(4).map((span, idx) => (
              <Column span={span} breakpoint="max" key={idx}>
                <Colored color="#ff4fa7" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(2).fill(6).map((span, idx) => (
              <Column span={span} breakpoint="max" key={idx}>
                <Colored color="#ff6657" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(1).fill(12).map((span, idx) => (
              <Column span={span} breakpoint="max" key={idx}>
                <Colored color="#2dba7d" text={span} />
              </Column>
            ))}
          </Row>
          <Title text="Responsive Grid (w/ breakpoints):" />
          <Row>
            {new Array(4).fill(3).map((span, idx) => (
              <Column span={span} key={idx} breakpoint="tablet">
                <Colored color="#b87ff0" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(3).fill(4).map((span, idx) => (
              <Column span={span} key={idx}>
                <Colored color="#ff4fa7" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(2).fill(6).map((span, idx) => (
              <Column span={span} key={idx}>
                <Colored color="#ff6657" text={span} />
              </Column>
            ))}
          </Row>
          <Row>
            {new Array(1).fill(12).map((span, idx) => (
              <Column span={span} key={idx}>
                <Colored color="#2dba7d" text={span} />
              </Column>
            ))}
          </Row>
        </section>
      )
    }
  },
  category: CATEGORY.SYSTEM,
  path: 'components/Core/Grid/Grid'
})

export default Grid
