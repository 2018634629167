import { Fragment } from 'react'
import { SimpleContext, Spinner, Avatar, Panel, Markdown } from 'simple-core-ui'
import moment from 'moment'
import { IoIosArrowDown } from 'react-icons/io'
import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import s from './ActivityLog.scss'

export const ActivityLogBody = ({ logs, isFetching, hasMore, loadMoreCb, theme, styles }) => {
  const shouldShowLogs = logs.length > 0
  const shouldShowEmptyMessage = logs.length === 0 && !isFetching
  const shouldShowLoadMoreSpinner = logs.length > 0 && isFetching
  const shouldShowLoadMoreText = logs.length > 0 && !isFetching && hasMore
  const shouldShowLoadingSpinner = isFetching && logs.length === 0

  return (
    <SimpleContext.Consumer>
      {({ theme }) => {
        return (
          <main
            className={`${s.main} ${s[theme]}`}
            style={{ alignItems: !logs.length && 'center' }}
          >
            {shouldShowLoadingSpinner ? (
              <Spinner style={styles.spinner} />
            ) : (
              <ul
                className={s.list}
                style={{ opacity: isFetching && logs.length === 0 ? '0' : '1' }}
              >
                {shouldShowLogs && (
                  <Fragment>
                    <li>
                      <span className={s.fadeTop} />
                    </li>
                    {logs.map(log => (
                      <Ticket key={log.created} {...log} />
                    ))}
                    <li>
                      <span className={s.fadeBottom} />
                    </li>
                  </Fragment>
                )}
                {shouldShowEmptyMessage && <li className={s.emptyLog}>{'No activities logged'}</li>}
                {shouldShowLoadMoreSpinner && (
                  <li className={s.ticket}>
                    <Spinner style={styles.spinner} />
                  </li>
                )}
                {shouldShowLoadMoreText && (
                  <li className={s.loadMore} onClick={loadMoreCb}>
                    <span className={s.moreText}>Load More</span>
                    <IoIosArrowDown className={s.arrow} />
                  </li>
                )}
              </ul>
            )}
          </main>
        )
      }}
    </SimpleContext.Consumer>
  )
}

const ActivityLog = ({ logs, isFetching, isError, hasMore, loadMoreCb, isBodyOnly, theme }) => {
  const panelTitle = `Activity Log ${logs.length ? `(${logs.length})` : ''}`
  const styles = {
    spinner: {
      height: '4em',
      width: '4em',
      margin: '0 auto',
      display: 'flex'
    }
  }

  return (
    <Panel title={panelTitle} className={s.panel} isError={isError} isBodyOnly={isBodyOnly}>
      <ActivityLogBody
        logs={logs}
        loadMoreCb={loadMoreCb}
        isFetching={isFetching}
        hasMore={hasMore}
        styles={styles}
      />
    </Panel>
  )
}

export default ActivityLog

const formatTime = dt => ({
  time: moment(dt).format('LT'),
  day: moment(dt).format('ll')
})

const Ticket = ({ ...rest }) => {
  const formattedTime = formatTime(rest.created)
  return (
    <li className={s.ticket}>
      <section className={s.date}>
        <span className={s.day}>{formattedTime.day}</span>
        <span className={s.time}>{formattedTime.time}</span>
      </section>
      <section className={s.info}>
        <Avatar initials={rest.user} />
        <div className={s.summary}>
          <p className={s.action}>{rest.category}</p>
          <div className={s.descContainer}>
            {!!rest.owner && !!rest.action && (
              <>
                <p className={s.descStrong}>{rest.owner}</p>
                <p>{rest.action}</p>
              </>
            )}
            <Markdown value={rest.description} />
          </div>
        </div>
      </section>
    </li>
  )
}

registration.register({
  name: 'ActivityLog',
  description:
    'Provided with a list of formatted logs this component will render an audit log detailing who had done what, when.',
  props: [
    { name: 'logs', type: 'object', note: 'See example for structure of logs.' },
    {
      name: 'isFetching',
      type: 'boolean',
      optional: true,
      note:
        'Used to determine if the loading spinner should be shown at the bottom of the logs to indicate more logs are being fetched.'
    },
    {
      name: 'isError',
      type: 'boolean',
      optional: true,
      note:
        'Optional boolean prop to set error state from outside this component. An ErrorBoundaryContainer is utilized which will naturally catch/handle errors within child nodes.'
    },
    {
      name: 'hasMore',
      type: 'boolean',
      optional: true,
      note:
        'Helpful in server-side paginated logs. Used to determine if the user should be shown a button to load more logs or if all the logs have been loaded.'
    },
    {
      name: 'loadMoreCb',
      type: 'function',
      optional: true,
      note: 'The callback function to be invoked when a user clicks the Load More button.'
    },
    {
      name: 'isBodyOnly',
      type: 'boolean',
      optional: true,
      note: 'If true it will render the component without the panel header title.'
    }
  ],
  example: {
    literal: `
<ActivityLog
  logs={[
    { user: 'Akash Deshpande',
      description: 'Akash Deshpande has recently granted permission to read confidential matter no. 1170',
      category: 'Grant',
      created: 1533073838772 },
    ...logs // Rest of logs go here...
  ]}
  isFetching={false}
  hasMore
  loadMoreCb={console.log('ActivityLog Loading...')}
/>
    `,
    render: theme => (
      <ActivityLog
        logs={[
          {
            user: 'Akash Deshpande',
            description:
              'Akash Deshpande has recently granted permission to read confidential matter no. 1170',
            category: 'Grant',
            created: 1533073838772
          },
          {
            user: 'Cameron Cairns',
            description: 'Cameron Cairns has recently created confidential matter no. 1637',
            category: 'Create',
            created: 1533073888772
          },
          {
            user: 'Joe Johnson',
            description: 'Joe Johnson has recently viewed confidential matter no. 8563',
            category: 'Read',
            created: 1533073938772
          },
          {
            user: 'Cameron Cairns',
            description:
              'Cameron Cairns has recently granted permission to read confidential matter no. 1637',
            category: 'Grant',
            created: 1533073988772
          },
          {
            user: 'Ian Jabour',
            description:
              'Ian Jabour has recently granted permission to read confidential matter no. 9354',
            category: 'Grant',
            created: 1533074038772
          },
          {
            user: 'Damien Lilliard',
            description: 'Damien Lilliard has recently created confidential matter no. 4797',
            category: 'Create',
            created: 1533074088772
          },
          {
            user: 'Patrik Outericky',
            description: 'Patrik Outericky has recently created confidential matter no. 9147',
            category: 'Create',
            created: 1533074138772
          },
          {
            user: 'Joseph J. Smith',
            description: 'Joseph J. Smith has recently created confidential matter no. 1586',
            category: 'Create',
            created: 1533074188772
          },
          {
            user: 'Joe Johnson',
            description:
              'Joe Johnson has recently granted permission to read confidential matter no. 2855',
            category: 'Grant',
            created: 1533074238772
          }
        ]}
        isFetching={false}
        hasMore
        loadMoreCb={() => console.log('ActivityLog Loading...')} // eslint-disable-line
      />
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/ActivityLog/ActivityLog'
})
