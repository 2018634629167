import { CSSProperties, ChangeEvent } from 'react'
import cn from 'classnames'
import { IoMdCheckmark } from 'react-icons/io'
import { SimpleContext } from 'simple-core-ui'

import s from './Checkbox.scss'
import noop from 'lodash/noop'

type Size = 'sm' | 'md' | 'lg'
interface Props {
  isChecked: boolean
  size?: Size
  isSelectable?: boolean
  hasWhiteBorder?: boolean
  hasGreyBorder?: boolean
  disabled?: boolean
  styles?: CSSProperties
  className?: string
  checkIconClassName?: string
  triggerCheckbox(e: ChangeEvent<HTMLInputElement>): void
  testId?: string
  isCgOutline?: boolean
}

const Checkbox = ({
  isChecked,
  isSelectable = true,
  triggerCheckbox,
  hasWhiteBorder,
  size = 'lg',
  styles = {},
  className,
  hasGreyBorder,
  checkIconClassName,
  disabled,
  testId = 'default-checkbox',
  isCgOutline
}: Props) => (
  <SimpleContext.Consumer>
    {({ theme }) => {
      if (!isSelectable) return null

      return (
        <label
          style={styles}
          className={cn(
            {
              [s.isActiveWhite]: isChecked && hasWhiteBorder,
              [s.isActiveGrey]: isChecked && hasGreyBorder,
              [s.isCgOutline]: isCgOutline,
              [s.isActive]: isChecked && !hasWhiteBorder,
              [s.disabled]: disabled
            },
            className,
            s.container,
            s[size],
            s[theme]
          )}
        >
          {isChecked && (
            <IoMdCheckmark
              className={cn(s.checkmark, checkIconClassName)}
              style={{ verticalAlign: 'top' }}
            />
          )}
          <input
            type="checkbox"
            onChange={disabled ? noop : triggerCheckbox}
            checked={isChecked}
            className={s.checkbox}
            data-testid={testId}
          />
        </label>
      )
    }}
  </SimpleContext.Consumer>
)

export default Checkbox
