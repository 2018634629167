import FilterValues from './FilterValues'
import s from './EditableFieldFilter.scss'

const EditableFieldFilter = ({
  options,
  onSearch,
  term,
  value,
  values,
  setValue,
  optionFormat,
  isMulti,
  isReadOnly
}) => (
  <div className={s.filterWrapper}>
    <h2 className={s.filterTitle}>Your Selected Values</h2>
    <FilterValues
      options={options}
      selectedValue={value}
      selectedValues={values}
      setSelectedValue={setValue}
      searchTerm={term}
      onSearch={onSearch}
      noOptionsTitle="No values have been selected."
      optionFormat={optionFormat}
      isMulti={isMulti}
      isReadOnly={isReadOnly}
      labelPrefix="selected-values"
    />
  </div>
)

export default EditableFieldFilter
