import noop from 'lodash/noop'
import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import PaginationPage from './PaginationPage'
import s from './Pagination.scss'
import { SimpleContext } from 'simple-core-ui'

const Pagination = ({
  previousPage,
  currentPage,
  nextPage,
  updateCurrentPage,
  pageWindow,
  firstPage,
  lastPage
}) => (
  <SimpleContext.Consumer>
    {({ theme }) => {
      return (
        <ul className={`${s.list} ${s[theme]}`}>
          <PaginationPage
            text={'First'}
            {...(firstPage === currentPage
              ? { fn: noop, page: null }
              : { fn: updateCurrentPage, page: firstPage })}
          />
          <PaginationPage page={previousPage} fn={updateCurrentPage} isArrow isFirst />
          {pageWindow.map((pageNumber, idx) => (
            <PaginationPage
              page={pageNumber}
              text={String(pageNumber)}
              key={idx}
              {...(pageNumber === currentPage
                ? { fn: noop, isActive: true }
                : { fn: updateCurrentPage, isActive: false })}
            />
          ))}
          <PaginationPage page={nextPage} fn={updateCurrentPage} isArrow />
          <PaginationPage
            text={'Last'}
            {...(lastPage === currentPage
              ? { fn: noop, page: null }
              : { fn: updateCurrentPage, page: lastPage })}
          />
        </ul>
      )
    }}
  </SimpleContext.Consumer>
)

registration.register({
  name: 'Pagination',
  description:
    'A component which handles displaying the calculated pagination with proper offsets.',
  props: [
    {
      name: 'previousPage',
      type: 'number | null',
      note:
        'The previous page number to be shown. Use null if you want to make the previous arrow render as disabled.'
    },
    { name: 'currentPage', type: 'number', note: 'The current page number being shown.' },
    {
      name: 'nextPage',
      type: 'number | null',
      note:
        'The next page number to be shown. Use null if you want to make the next arrow render as disabled.'
    },
    {
      name: 'updateCurrentPage',
      type: 'function',
      note:
        'The callback for handling when a page change occurs. The function will receive the page number that was selected.'
    },
    { name: 'theme', type: 'eb | cg', note: 'The theme to be applied to the component.' },
    {
      name: 'pageWindow',
      type: 'Array<number>',
      note: 'An array of page numbers to be displayed as navigable.'
    },
    {
      name: 'firstPage',
      type: 'number',
      note: 'The number of the first page, technically always 1.'
    },
    { name: 'lastPage', type: 'number', note: 'The number of the last page.' }
  ],
  example: {
    literal: `
<section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
  <Pagination
    previousPage={1}
    currentPage={2}
    nextPage={3}
    updateCurrentPage={page => {
      console.log('You have selected page: ' + page || null)
    }}
    pageWindow={[1, 2, 3, 4, 5]}
    firstPage={1}
    lastPage={9}
  />
</section>`.trim(),
    render: () => (
      <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Pagination
          previousPage={1}
          currentPage={2}
          nextPage={3}
          updateCurrentPage={page => {
            console.log(`You have selected page: ${page || 'null'}`) // eslint-disable-line
          }}
          pageWindow={[1, 2, 3, 4, 5]}
          firstPage={1}
          lastPage={9}
        />
      </section>
    )
  },
  category: CATEGORY.NAV,
  path: 'components/Core/Pagination/Pagination'
})

export default Pagination
