import axios from 'axios'

//  --> API request will fail on empty strings
export const sanitizeApiParams = params => {
  for (let key in params) {
    if (params[key] === '') {
      params[key] = null
    }
  }
  return params
}

// These configurations are necessary to make any non-GET request to Django.
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.headers.common['X-CSRFToken'] = window.credentials.csrfToken

// This will allow for the Django view check request.is_ajax() to pass.
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

export const makeFormDataPost = (url, params) => {
  let bodyFormData = new FormData()
  for (let key in params) {
    if (params[key] !== null && params[key] !== undefined) {
      bodyFormData.append(key, params[key])
    }
  }

  return axios({
    method: 'post',
    url: url,
    data: bodyFormData,
    headers: { 'X-CSRFToken': window.credentials.csrfToken },
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  }).then(response => response.data)
}

export const getRequest = url => {
  return axios.get(url).then(response => response.data)
}

export const makeGetRequest = (url, params) => {
  return axios.get(url, params).then(response => response.data)
}

export const makePostRequest = (url, params, config = {}) =>
  axios.post(url, params, config).then(response => response.data)

export const makePutRequest = (url, params) =>
  axios.put(url, params).then(response => response.data)

export const makeDeleteRequest = (url, params) =>
  axios.delete(url, { data: params }).then(response => response.data)

export const makePatchRequest = (url, params) =>
  axios.patch(url, params).then(response => response.data)
