import s from './TableHead.scss'

const TableHeadAvatar = ({ avatarHeader }) =>
  avatarHeader ? (
    <th style={{ textAlign: 'center' }}>{avatarHeader}</th>
  ) : (
    <th className={s.avatar} />
  )

export default TableHeadAvatar
