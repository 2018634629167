import { IoIosArrowDown } from 'react-icons/io'
import s from './Dropdown.scss'

type Props = { label?: string; expandDropdown(): void }

const Label = ({ label, expandDropdown }: Props) => (
  <header className={s.header} onClick={expandDropdown}>
    <label className={s.label}>{label || 'Select.. '}</label>
    <IoIosArrowDown />
  </header>
)

export default Label
