import * as React from 'react'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import { DataTable, withConfirmation } from 'simple-core-ui'

import mockdata from '../../../data/mock_table_data'

const BULK_CONF = {
  title: 'List of selections will be lost.',
  text: 'Do you wish to continue?'
}

function getInitialState(props) {
  const { initialValues, columns } = props

  const columnKey = columns.find(({ isSortable }) => isSortable)?.columnKey ?? columns[0]?.columnKey

  return {
    pageSize: get(initialValues, 'pageSize', 10),
    ordering: get(initialValues, 'ordering', {
      columnKey,
      isDesc: true
    }),
    search:
      typeof initialValues.search === 'object'
        ? initialValues.search.keyword
        : get(initialValues, 'search', ''),
    page: get(initialValues, 'page', 1),
    category: get(initialValues, 'category', 'results'),
    isError: props.isError
  }
}

class DataTableContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = getInitialState(props)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isError) {
      this.setState({ isError: true })
    } else if (!isEqual(this.props.initialValues, prevProps.initialValues)) {
      this.setState({ ...getInitialState(this.props) })
    }
  }

  componentDidCatch(error, info) {
    this.setState({ isError: true })

    console.error('An error has occurred in DataTableContainer', error, info)
  }

  getUpdateParams = () => ({
    pageSize: this.state.pageSize,
    ordering: this.state.ordering,
    search: this.state.search,
    page: this.state.page,
    category: this.state.category
  })

  updatePageSize = pageSize => {
    this.setState({ pageSize, page: 1 })
    this.props.updateTable({ ...this.getUpdateParams(), pageSize, page: 1 })
  }

  updateSearch = search => {
    this.setState({ search, page: 1 })
    this.props.updateTable({ ...this.getUpdateParams(), search, page: 1 })
  }

  updateCurrentPage = page => {
    this.setState({ page })
    this.props.updateTable({ ...this.getUpdateParams(), page })
  }

  updateSortOrder = ordering => {
    this.setState({ ordering })
    this.props.updateTable({
      ...this.getUpdateParams(),
      ordering,
      ...(this.props.hasInfinityScroll ? { page: 1 } : {})
    })
  }

  updateFilterCategory = category => {
    this.setState({ category, page: 1 })
    this.props.updateTable({ ...this.state, category, page: 1 })
  }

  wrapConfirmation = fn => {
    if (
      this.props.bulkDismiss &&
      (this.props.selectedRows.size > 0 || this.props.allRowsSelected)
    ) {
      return withConfirmation(fn, BULK_CONF)
    } else return fn
  }

  render() {
    return (
      <DataTable
        {...this.props}
        {...this.state}
        updatePageSize={this.wrapConfirmation(this.updatePageSize)}
        updateSearch={this.updateSearch}
        updateCurrentPage={this.wrapConfirmation(this.updateCurrentPage)}
        updateSortOrder={this.wrapConfirmation(this.updateSortOrder)}
        updateFilterCategory={this.wrapConfirmation(this.updateFilterCategory)}
      />
    )
  }
}

registration.register({
  name: 'DataTableContainer',
  description:
    'The standard component for showing tabular data. It has props that are utilized by more complex container components to allow sorting by column, multi-row selection, and more. See the DataTable component for implementation details.',
  props: [
    {
      name: 'primayAction',
      optional: true,
      type: 'ButtonProps (Check doc for supported props for Button)',
      note:
        'If provided it will render a primary call to action button on the header section of the table. You provide this prop with the same config that you would for a given Button component in the system. Sane default are set up so the only thing you should need to apply is a onClick handler.'
    },
    {
      name: 'secondaryAction',
      optional: true,
      type: 'ButtonProps (Check doc for supported props for Button)',
      note: 'Same as primayAction but defaults to secondary button styles.'
    },
    {
      name: 'title',
      type: 'React.Node',
      note: 'The title text to be shown with the table. Can be a react node if subtext is needed.'
    },
    {
      name: 'pageSizeOptions',
      optional: true,
      type: 'Array<{label: string, value: number}>',
      note:
        'Default: [{label: "10", value: 10}, {label: "50", value: 50}, {label: "100", value: 100}] - The page size options to be listed.'
    },
    {
      name: 'hasActionsHeader',
      optional: true,
      type: 'boolean',
      note:
        'Default: true - Shows the header with the filter input and the dropdown with number of entries per page'
    },
    {
      name: 'multiSort',
      optional: false,
      type: 'boolean' | { columnKey: 'string', isDesc: 'boolean' },
      note:
        'Default: false - When you need a secondary sort of rows with the same value. If you pass an object it will sort by desired column and direction. If you pass a boolean it will sort by "id", "desc", to match django sorting.'
    },
    {
      name: 'fixedHeader',
      optional: true,
      type: 'boolean',
      note: 'Default: false - Add scrollbar inside table body so the header is always visible'
    },
    {
      name: 'tableHeight',
      optional: true,
      type: 'string',
      note:
        'Default: 500px - Add height to the table in order for scrollbar to appear. Works together with fixedHeader'
    },
    {
      name: 'hasSearch',
      optional: true,
      type: 'boolean',
      note: 'Default: true - Shows the filter input'
    },
    {
      name: 'statusText',
      optional: true,
      type: 'string',
      note:
        'Default: (no matching records found) - This is the message shown when no results are found'
    },
    {
      name: 'customStatusText',
      optional: true,
      type: 'string',
      note:
        'This will take priority over all texts. This is useful for when you want to show a custom message even when you have filters and you don"t want to show the default "Try adjusting the filters." message'
    },
    {
      name: 'previousPage',
      type: 'number | null',
      note: 'The previous page number. Null if no previous page.'
    },
    {
      name: 'nextPage',
      type: 'number | null',
      note: 'The next page number. Null if no next page.'
    },
    {
      name: 'totalEntries',
      type: 'number',
      note: 'The total number of entries in the database for the given object.'
    },
    {
      name: 'filteredTotal',
      type: 'number',
      note:
        'The total number of filtered entries for the given object. (i.e 400 total entries -> showing _ of 400)'
    },
    {
      name: 'categories',
      type: 'Array<{label: string, value: (string | number), count?: number, isTile?: boolean}>',
      note:
        'The categories to be listed as filters. If isTile is true it will show as a Tile on the top of the table.'
    },
    {
      name: 'categoryValue',
      type: 'string | number',
      note: 'The value of the category which is selected in categories.'
    },
    {
      name: 'categoriesClearable',
      optional: true,
      type: 'boolean',
      note:
        'A boolean which, if true, will render an X icon on the active category and invoke the clickCb on Tiles that are active and clicked. This should be false if you want to always have atleast one tile active at all times.'
    },
    {
      name: 'panelTitle',
      optional: true,
      type: 'string',
      note:
        'The title of the panel in which the table is shown in. If not passed no surrounding panel will be rendered.'
    },
    {
      name: 'isLoading',
      optional: true,
      type: 'boolean',
      note:
        'The boolean prop that will be used to determine if the datatable is loading. It will show the spinner in the filter box.'
    },
    {
      name: 'alwaysShowLoadingSkeleton',
      optional: true,
      type: 'boolean',
      note:
        'This prop together with isLoading prop will make sure the loading skeleton will appear on every table update and will ignore the rest of conditions'
    },
    {
      name: 'isError',
      type: 'boolean',
      optional: true,
      note:
        'Optional boolean prop to set error state from outside this component. An ErrorBoundaryContainer is utilized which will naturally catch/handle errors within child nodes.'
    },
    {
      name: 'initialValues',
      type:
        '{pageSize?: number (default: 10), ordering?: {columnKey: number, isDesc: boolean} (default: first sortable column set to descending true), search: string (default: ""), page: number (default: 1), category?: (string | number) (default: "results")}',
      note: 'The initial values for the DataTable.'
    },
    {
      name: 'updateTable',
      type:
        'function -- ({pageSize?: number, ordering?: {columnKey: number, isDesc: boolean}, search: string, page: number, category?: (string | number)}) => void',
      note:
        'The function which will be caled whenever the table is sorted/filtered/paginated. It will recieve the updated UpdateParams to help make the Subsequent calls for data fetching.'
    },
    {
      name: 'rows',
      type: 'Array<Row> (See type declaration in code sample below)',
      note:
        'The rows to be rendered within the table along with additional props to control rendering logic within the TableRow components. An important note is to be sure that each cell in rows.cells has a matching columnKey in the columns.columnKey. If a matching columnKey is not found for the row an error will be logged in the console with details.'
    },
    {
      name: 'columns',
      type: 'Array<Column> (See type declaration in code sample below)',
      note:
        'The columns to be rendered within the table along with additional props to control rendering logic within the TableHead component.'
    },
    {
      name: 'bulkDismiss',
      optional: true,
      type: 'boolean',
      note:
        'Dialog to warn user about bulk selections that are going to be dismissed if proceeding with operation'
    },
    {
      name: 'selectedRows',
      optional: true,
      type: 'Set<RowId> -- (type RowId = number)',
      note:
        'A set of the rows which have been selected. This will only be used when selectRow prop is passed in, which indicates that the table should allow for rows to be selected (via checkbox).'
    },
    {
      name: 'selectRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is selected via the checkbox. By passing this prop in the rows will render with a checkbox on the far left side. The callback will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'clickRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is clicked. It will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'deleteRow',
      optional: true,
      type: 'function -- (Row) => void',
      note:
        'The callback which will be invoked when a row is deleted via the trashcan. By passing this prop in the rows will render with a trashcan icon on the far right side. In order for the trashcan icon to show up you must also pass in true for the prop hasActions. The callback will receive a Row type as an argument. (See type declaration in code sample below)'
    },
    {
      name: 'hasActions',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop which will render extra space on the right side of the table for actions such as showing the attachments icon or the icon to delete.'
    },
    {
      name: 'pinActions',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop which will render the actions on the right side of the table in a fixed position. This is useful when the table is scrollable and you want the actions to always be visible.'
    },
    {
      name: 'alwaysShowActions',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop which will make the actions icon always visible'
    },
    {
      name: 'actions',
      optional: true,
      type: `Array<object/function> --
        {
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        },
        row => ({
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        })
      `,
      note: 'An array of actions that will be rendered on the right side of the table'
    },
    {
      name: 'bulkActions',
      optional: true,
      type: `React.Node || Array<object/function> --
        {
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        },
        row => ({
          icon: Component,
          tooltip: 'String,
          onClick: function -- (event, row) => void,
          disabled: Boolean,
          className: String,
          condition: boolean
        })
      `,
      note:
        'A React.Node or an array of bulk actions that will be rendered on the top left side of the table. The icons will be visible if at least on row is selected (selectedRows.size !== 0)'
    },
    {
      name: 'sortTable',
      optional: true,
      type: 'function -- (ColumnKey: string, isDesc: boolean) => void',
      note:
        'The callback function which, when provided will determine if the table is sortable and also be invoked when a sortable column header is clicked. The columnKey, the unique name and identifier for the column, will be provided as an argument to the callback along with a boolean specifying if the column sort should be descending or not.'
    },
    {
      name: 'hasPagination',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop which is used to determine if the table has pagination footer.'
    },
    {
      name: 'hasInfinityScroll',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop which is used to determine if the table has infinity scroll.'
    },
    {
      name: 'allRowsSelected',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop which is used to determine if the checkbox in the top left should render as checked or not. Only applicable if selectAllRows is passed in as a prop.'
    },
    {
      name: 'selectAllRows',
      optional: true,
      type: 'function -- () => void',
      note:
        'A callback which will be invoked when the checkbox in the top left of the TableHead is clicked. If this is not provided that checkbox in the top left will not be rendered.'
    },
    {
      name: 'renderCell',
      optional: true,
      type: 'function -- (RowId, ColumnKey, Cell.content) => React.Node',
      note:
        'A callback that takes in a RowId, ColumnKey, and Cell.content and should return a React Node to render. This should only be used custom rendering needs to take place.'
    },
    {
      name: 'searchLabel',
      optional: true,
      type: 'string',
      note: 'Text rendered next to search input.',
      defaultValue: 'Filter'
    },
    {
      name: 'entryLanguage',
      optional: true,
      type: 'string',
      note:
        'Default  { singular: "entry", plural: "entries" }. Text displayed next to page size options and in pagination used to describe the type of objects that are dispayed in the table.'
    }
  ],
  example: {
    literal: `
/*
rows = [
  {
    id: 1,
    cells: [
      { columnKey: 'invoiceNumber', content: 4128 },
      { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
      { columnKey: 'description', content: 'Adipisicing in architecto illum aliquid natus.' },
      { columnKey: 'total', content: '$6683' },
      { columnKey: 'status', content: 'Rejected' }
    ],
    attachments: false,
    avatar: 'Kurt Rose',
    canDelete: true,
    borderColor: 'red' },
    (...rest of rows)
]

columns = [
  { columnKey: 'invoiceNumber', content: 'Number', isSortable: true },
  { columnKey: 'invoiceDate', content: 'Date', isSortable: true, style: {whiteSpace: 'nowrap'} },
  { columnKey: 'description', content: 'Description' },
  { columnKey: 'total', content: 'Total', isSortable: true, isDesc: true, style: { textAlign: 'right' } },
  { columnKey: 'status', content: 'Status' }
]
*/
<DataTableContainer
  title='Open Invoices'
  initialValues={{
    pageSize: 10,
    ordering: { columnKey: 'total', isDesc: true },
    page: 1,
    search: '',
    category: 'open'
  }}
  previousPage={null}
  nextPage={2}
  totalEntries={100}
  filteredTotal={100}
  categories={[
    {label: 'All', value: null},
    {label: 'Open', value: 'open', count: 34, isTile: true},
    {label: 'Closed', value: 'closed', count: 67, isTile: true},
    {label: 'Rejected', value: 'rejected', count: 12, isTile: true},
    {label: 'Pending', value: 'pending', count: 78, isTile: true}
  ]}
  rows={rows}
  columns={columns}
  clickRow={row => console.log(row)}
  updateTable={(updateConfig) => console.log(updateConfig)}
  panelTitle='Invoice List'
/>`.trim(),
    render: () => {
      return (
        <>
          <DataTableContainer
            title="Open Invoices"
            initialValues={{
              pageSize: 10,
              ordering: { columnKey: 'total', isDesc: true },
              page: 1,
              search: '',
              category: 'open'
            }}
            previousPage={null}
            nextPage={2}
            totalEntries={100}
            filteredTotal={100}
            categories={[
              { label: 'All', value: null },
              { label: 'Open', value: 'open', count: 34, isTile: true },
              { label: 'Closed', value: 'closed', count: 67, isTile: true },
              { label: 'Rejected', value: 'rejected', count: 12, isTile: true },
              { label: 'Pending', value: 'pending', count: 78, isTile: true }
            ]}
            rows={mockdata.rows}
            columns={mockdata.columns}
            clickRow={row => console.log('You clicked row: ' + row)} // eslint-disable-line
            updateTable={updateConfig => console.log(updateConfig)} // eslint-disable-line
            panelTitle="Invoice List"
          />
          <DataTableContainer
            title="Open Invoices with loading data state"
            initialValues={{
              pageSize: 10,
              ordering: { columnKey: 'total', isDesc: true },
              page: 1,
              search: '',
              category: 'open'
            }}
            previousPage={null}
            nextPage={2}
            totalEntries={100}
            filteredTotal={100}
            categories={[{ label: 'All', value: null }]}
            rows={[]}
            columns={mockdata.columns}
            isLoading
            panelTitle="Invoice List with loading data state"
          />
        </>
      )
    }
  },
  category: CATEGORY.TABLES,
  path: 'containers/Core/DataTableContainer/DataTableContainer'
})

export default DataTableContainer
