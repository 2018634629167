import * as React from 'react'

import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

const { fontWeight, fontSize, color } = DESIGN

const descriptions = {
  ftWt100: {
    text: 'Use for most text body in the UI.',
    render: value => (
      <span style={{ fontWeight: value, fontSize: fontSize.ftSz400 }}>
        If you think you need a thinner weight use a{' '}
        <span style={{ color: color.gray700 }}>lighter</span> font color.
      </span>
    )
  },
  ftWt200: {
    text: 'Use for header text or places where you need to bring attention to an element.',
    render: value => (
      <React.Fragment>
        <span style={{ fontWeight: value, fontSize: fontSize.ftSz600, display: 'block' }}>
          Use Smaller Bold Headings
        </span>
        <span style={{ fontWeight: '300', fontSize: fontSize.ftSz900, display: 'block' }}>
          Over Larger Thin Ones
        </span>
      </React.Fragment>
    )
  }
}

const sass = `
.header {
  font-weight: $ftWt200;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { fontWeight: { ftWt200 } } = DESIGN

const Header = () => <h1 style={{fontWeight: ftWt200}}>...</h1>
`

const html = '<h1 class="ftWt200">...</h1>'

export default registration.register({
  name: 'Font Weight',
  description: 'SimpleLegal Design System Font Weights',
  example: {
    render: () => (
      <DescriptionList pairs={getDesignValues(fontWeight)} descriptions={descriptions} />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
