import { DESIGN, Anchor } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

const { fontFamily } = DESIGN

const descriptions = {
  primaryFontFamily: {
    text: 'Use for all bodies of text and for any headings in the app.',
    render: () => (
      <span>
        You can find more about Lato{' '}
        <Anchor url="https://fonts.google.com/?query=lato" asNewTab>
          here
        </Anchor>
        .
      </span>
    )
  }
}

const sass = `
.p {
  font-family: $primaryFontFamily;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { fontFamily: { primaryFontFamily } } = DESIGN

const Paragraph = () => <p style={{fontFamily: primaryFontFamily}}>...</p>
`

const html = 'Not implemented for HTML...'

export default registration.register({
  name: 'Font Family',
  description: 'SimpleLegal Design System Font Family',
  example: {
    render: () => (
      <DescriptionList pairs={getDesignValues(fontFamily)} descriptions={descriptions} />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
