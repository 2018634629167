import { Component } from 'react'

import SurveyChoice from './SurveyChoice'
import s from './SurveyQuestion.scss'
import { Textarea } from 'components'
import registration from 'docs/registration'
import get from 'lodash/get'

class SurveyQuestion extends Component {
  state = {
    value: get(this.props.initialValues, 'value', null),
    comment: get(this.props.initialValues, 'comment', '')
  }

  static defaultProps = {
    numChoices: 10,
    initialValues: {
      value: null,
      comment: ''
    },
    infoText: {}
  }

  onChangeValue = value => {
    this.setState({
      value: this.state.value === value ? null : value
    })

    if (this.props.onChange) {
      this.props.onChange({ value, comment: this.state.comment })
    }
  }

  onChangeComment = comment => {
    this.setState({
      comment
    })

    if (this.props.onChange) {
      this.props.onChange({ value: this.state.value, comment })
    }
  }

  render() {
    const { text, isClosed, numChoices, infoText, required } = this.props
    const { value, comment } = this.state
    return (
      <li className={s.item}>
        {text}
        {required ? (
          <span className={s.red}>*</span>
        ) : (
          <span className={s.optional}> Optional</span>
        )}
        <section className={s.ratingsContainer}>
          {[...Array(numChoices)].map((_, rIndex) => {
            const curValue = rIndex + 1
            return (
              <SurveyChoice
                isActive={value === curValue}
                onClick={this.onChangeValue}
                text={curValue}
                key={rIndex}
                infoText={infoText}
                isClosed={isClosed}
              />
            )
          })}
        </section>
        {value !== null && (
          <section className={s.commentContainer}>
            <Textarea
              onChange={this.onChangeComment}
              value={comment}
              placeholder={'Tell us why you selected the above score?'}
              isDisabled={isClosed}
            />
          </section>
        )}
      </li>
    )
  }
}

registration.register({
  name: 'SurveyQuestion',
  description: 'User can answer question posed on a 1-X scale.',
  props: [
    { name: 'text*', type: 'string', note: 'The text of the question.' },
    {
      name: 'initialValues',
      type: 'object Answer ({value: integer, comment: string})',
      note: 'Initial values for an answer'
    },
    { name: 'numChoices', type: 'integer', note: 'The number of choices in the scale.' },
    {
      name: 'infoText',
      type: '{ choiceNumber: string }',
      note: 'Help text on particular choices to tell user what the numbers mean.'
    },
    {
      name: 'onChange',
      type: 'function - (Answer) => void',
      note:
        'Callback that returns the answer object {value, comment} whenever there is a value change or comment change.'
    },
    { name: 'isClosed', type: 'boolean', note: 'Will disable inputs if true.' },
    { name: 'required', type: 'boolean', note: 'Marks a question as required.' },
    {
      name: 'validateSurvey',
      type: 'function - (Answer) => void',
      note: 'Callback that is called if a required question is answered.'
    },
    {
      name: 'invalidateSurvey',
      type: 'function - (Answer) => void',
      note: 'Callback that is called if a required question is marked as unanswered.'
    }
  ],
  example: {
    literal: `
    <SurveyQuestion
     text={'How much you like React?'}
     onChange={(answer) => console.log(answer) }
   />
    `.trim(),
    render: theme => {
      const infoText = {
        '1': 'Below Expectations',
        '7': 'Met Expectations',
        '10': 'Exceeded Expectations'
      }

      return (
        <ul style={{ listStyle: 'none' }}>
          <SurveyQuestion
            text={'How much you like React?'}
            infoText={infoText}
            // eslint-disable-next-line no-console
            onChange={answer => console.log(answer)}
          />
        </ul>
      )
    }
  }
})

export default SurveyQuestion
