import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'

const sass = `
.overlay {
  z-index: $zIdx700;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { zIndex: { zIdx700 } } = DESIGN

const Overlay = () => <div style={{zIndex: zIdx700}}>...</div>
`

const html = '<div class="zIdx700">...</div>'

const SIZE = DESIGN.spacing.sp1400

export default registration.register({
  name: 'Z-Index',
  description: 'SimpleLegal Design System Z-Indexes',
  example: {
    render: () => (
      <section style={{ position: 'relative', width: SIZE, height: SIZE }}>
        {getDesignValues(DESIGN.zIndex).map(([name, value], idx) => (
          <section
            style={{
              zIndex: value,
              background: `hsl(${40 * idx}, 75%, 75%)`,
              position: 'absolute',
              bottom: '0',
              right: '0',
              width: 512 - 35 * idx,
              height: 512 - 35 * idx,
              padding: DESIGN.spacing.sp200,
              boxSizing: 'border-box'
            }}
            key={idx}
          >
            <strong>{name}</strong> ({value})
          </section>
        ))}
      </section>
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
