import classNames from 'classnames'
import { IoIosClose } from 'react-icons/io'

import { If } from 'simple-core-ui'

import s from './PickList.scss'

const PickList = ({
  options,
  label,
  selectLabel,
  numberedLabel,
  emptyText,
  renderOption,
  renderSelect,
  onRemove,
  onAdd,
  height,
  readOnly
}) => (
  <section className={s.container}>
    <If condition={label || numberedLabel}>
      <section className={s.labelLine}>
        <If condition={label}>
          <label className={s.label}>{label}</label>
        </If>
        <If condition={numberedLabel}>
          <label className={classNames(s.numberedLabel, { [s.invisible]: !options.length })}>
            <strong className={s.count}>{options.length}</strong>
            {`${numberedLabel}${options.length === 1 ? '' : 's'}`}
          </label>
        </If>
      </section>
    </If>
    <section className={s.listLineContainer} style={height && { height }}>
      {options.map((option, idx) => (
        <ListLine
          content={renderOption(option)}
          onRemove={() => onRemove(option)}
          readOnly={readOnly || option.cannotRemove}
          key={idx}
        />
      ))}
      <If condition={!options.length}>
        <span className={s.empty}>{emptyText}</span>
      </If>
    </section>
    <If condition={!readOnly}>
      <section className={s.selectContainer}>
        <If condition={selectLabel}>
          <label className={s.label}>{selectLabel}</label>
        </If>
        {renderSelect(onAdd, options)}
      </section>
    </If>
  </section>
)

const ListLine = ({ content, onRemove, readOnly }) => (
  <section className={s.listLine}>
    <section>{content}</section>
    <If condition={!readOnly}>
      <IoIosClose className={s.remove} onClick={onRemove} />
    </If>
  </section>
)

export default PickList
