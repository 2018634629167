import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import s from './Banner.scss'

const Banner = ({ title, message, styles = {} }) => {
  const hoverTitle = `${title} - ${typeof message === 'string' ? message : ''}`

  return (
    <section className={s.container} style={styles} title={hoverTitle}>
      <section className={s.title}>{title} -</section>
      <section className={s.message}>{message}</section>
    </section>
  )
}

registration.register({
  name: 'Banner',
  description: 'A component used to display as static banner message.',
  props: [
    { name: 'title', type: 'string', note: 'The title to be shown bolded on the banner.' },
    { name: 'message', type: 'string | Node', note: 'The body text of the banner.' },
    { name: 'styles', type: 'object', note: 'Custom styles to be applied to the component.' }
  ],
  example: {
    literal: `
<Banner
  title='Maintenance Announcement'
  message='Scheduled maintenance on Friday, August 17, 2018 from 8:00PM-9:00PM PT. The app will be unavailable during this maintenance window.'
/>`.trim(),
    render: () => (
      <Banner
        title="Maintenance Announcement"
        message="Scheduled maintenance on Friday, August 17, 2018 from 8:00PM-9:00PM PT. The app will be unavailable during this maintenance window."
      />
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Banner/Banner'
})

export default Banner
