import { CSSProperties, useEffect } from 'react'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import { Portal } from 'simple-core-ui'

import s from './Popable.scss'

interface Props {
  testid?: string
  position?: string
  isOpen?: boolean
  openOnHover: boolean
  content?: string
  asTip: boolean
  className?: string
  styles?: CSSProperties
  contentStyles?: CSSProperties
  children: JSX.Element | JSX.Element[]
  isPortal?: boolean
  coords?: CSSProperties
  updateTooltipCoords?(): void
}

const Popable = ({
  testid,
  position = 'top',
  isOpen = false,
  openOnHover = true,
  content,
  asTip = false,
  styles,
  className = '',
  contentStyles,
  children,
  isPortal,
  coords,
  updateTooltipCoords
}: Props) => {
  const updateCoords = updateTooltipCoords ? debounce(updateTooltipCoords, 100) : noop

  useEffect(() => {
    if (!isPortal) return

    window.addEventListener('resize', updateCoords)
    return () => window.removeEventListener('resize', updateCoords)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPopover = () => {
    return (
      <section
        className={classNames(s[position], {
          [s.contentWrapper]: !asTip && !coords,
          [s.portalContentWrapper]: isPortal && coords,
          [s.asTip]: asTip
        })}
        style={{
          ...contentStyles,
          ...(isPortal && coords
            ? { position: 'absolute', ...coords, ...(isOpen ? { display: 'block' } : {}) }
            : {})
        }}
      >
        {content}
      </section>
    )
  }

  return (
    <span
      data-testid={testid}
      style={styles}
      className={classNames(s.container, {
        [s.openOnHover]: openOnHover,
        [s.isOpen]: isOpen,
        [className]: className
      })}
    >
      {children}
      {isPortal && coords ? <Portal>{renderPopover()}</Portal> : renderPopover()}
    </span>
  )
}

export default Popable
