import s from './NotificationList.scss'

const NotificationList = ({ lines = '' }) =>
  typeof lines === 'string' ? (
    lines
  ) : lines.length === 1 ? (
    lines[0]
  ) : (
    <ul className={s.container} data-testid="notification-list">
      {lines.map((line, idx) => {
        return (
          <li className={s.line} key={idx} data-testid={`notification-line-${idx}`}>
            {line}
          </li>
        )
      })}
    </ul>
  )

export default NotificationList
