import * as React from 'react'
import classNames from 'classnames'
import noop from 'lodash/noop'
import { IoIosClose } from 'react-icons/io'

import { OutsideClickContainer, If, Spinner } from 'simple-core-ui'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import s from './Overlay.scss'

const style = {
  width: '100%',
  height: '100%',
  left: '0px',
  top: '0px',
  position: 'absolute',
  margin: '0px',
  zIndex: '1',
  fontSize: '20px'
}

const Overlay = ({ content, children, onClose, isVisible, asStatic, withSpinner }) => {
  const Content = () => (
    <section className={s.container}>
      <section
        className={classNames({
          [s.blur]: isVisible && !asStatic,
          [s.hidden]: isVisible && asStatic
        })}
      >
        <section className={classNames({ [s.pullBack]: isVisible })}>{children}</section>
      </section>
      <If condition={isVisible}>
        {() => (
          <React.Fragment>
            <figure className={classNames(s.prompt, { [s.asStatic]: asStatic })}>
              <If condition={onClose}>
                <IoIosClose className={s.close} onClick={onClose} />
              </If>
              <section>{withSpinner ? <Spinner style={style} /> : content}</section>
            </figure>
          </React.Fragment>
        )}
      </If>
    </section>
  )

  return isVisible ? (
    <OutsideClickContainer
      closeComponent={onClose || noop}
      styles={{ width: '100%', height: 'auto' }}
    >
      <Content />
    </OutsideClickContainer>
  ) : (
    <Content />
  )
}

Overlay.defaultProps = {
  content: null
}

registration.register({
  name: 'Overlay',
  description:
    'A component used to overlay a component on top of another. This tends to be useful when you want to quickly overlay information on top of a given component without utilizing a modal. This component is meant to be controlled from the outside.',
  props: [
    {
      name: 'content',
      type: 'React.Node',
      note: 'The content to render when overlay is visible.'
    },
    {
      name: 'children',
      type: 'React.Node',
      note: 'The content that will be rendered and live beneath the overlay.'
    },
    {
      name: 'onClose',
      type: '() => void',
      optional: true,
      note:
        'The function that will run when the user closes the overlay. If this function is not provided then the ui associated with dismissing the modal will not be present.'
    },
    {
      name: 'isVisible',
      type: 'boolean',
      optional: true,
      note:
        'The boolean that will determine if the overlay is shown or not. If true the overlay is shown, otherwise it is not visible.'
    },
    {
      name: 'asStatic',
      type: 'boolean',
      optional: true,
      note:
        'The boolean that will set the overlay content as display block to allow for it to render staticly rather than absolutely in the document flow. This can be useful in scenarios where the overlay content is dynamic and could be taller than the content being overlaid.'
    },
    {
      name: 'withSpinner',
      type: 'boolean',
      optional: true,
      note: 'If true it will render a spinner as the overlay content.'
    }
  ],
  example: {
    render: () => {
      const Wrapper = ({ children }) => (
        <section
          style={{
            width: '200px',
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px dotted black',
            margin: '1em auto'
          }}
        >
          {children}
        </section>
      )
      const loremText =
        'Dolor consequuntur commodi molestias tempore placeat Explicabo dignissimos fuga Aliquam ratione aut obcaecati laudantium.'

      const ChildContent = () => <section style={{ textAlign: 'center' }}>{loremText}</section>

      const OverlayContent = () => (
        <section style={{ textAlign: 'center' }}>Overlay Content</section>
      )

      return (
        <React.Fragment>
          <Wrapper>
            <Overlay content={<OverlayContent />}>
              <ChildContent />
            </Overlay>
          </Wrapper>
          <Wrapper>
            <Overlay
              content={<OverlayContent />}
              // eslint-disable-next-line no-console
              onClose={() => console.log('Dismissible Overlay dismissed...')}
              isVisible
            >
              <ChildContent />
            </Overlay>
          </Wrapper>
          <Wrapper>
            <Overlay content={<OverlayContent />} isVisible>
              <ChildContent />
            </Overlay>
          </Wrapper>
          <Wrapper>
            <Overlay isVisible withSpinner>
              <ChildContent />
            </Overlay>
          </Wrapper>
        </React.Fragment>
      )
    }
  },
  category: CATEGORY.OVERLAYS,
  path: 'components/Core/Overlay/Overlay'
})

export default Overlay
