import classNames from 'classnames'
import { IoIosClose, IoIosBriefcase } from 'react-icons/io'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import { SimpleContext } from 'simple-core-ui'
import { colors, getThemeColor } from 'simple-core-ui/styles/cssInJs'
import s from './Tiles.scss'

const Tiles = ({ groups, clickCb, renderIcon, clearable }) => (
  <SimpleContext.Consumer>
    {({ theme }) => {
      return (
        <section className={s.container}>
          {groups.map(tile => (
            <section
              className={classNames(s.tile, { [s.active]: tile.isActive }, s[theme])}
              onClick={() => {
                if (!clearable && tile.isActive) {
                  return
                }
                clickCb(tile.id)
              }}
              key={tile.id}
            >
              <section className={s.info}>
                <span className={classNames(s.count, { [s.zero]: tile.count < 0 })}>
                  {tile.count >= 0 ? tile.count : '--'}
                </span>
                <span className={s.text}>{tile.text}</span>
              </section>
              {renderIcon && renderIcon(tile, getTileStyles(tile.isActive, theme))}
              {tile.isActive && clearable && (
                <span className={s.close}>
                  <IoIosClose style={{ margin: 0 }} />
                </span>
              )}
            </section>
          ))}
        </section>
      )
    }}
  </SimpleContext.Consumer>
)

const getTileStyles = (isActive, theme) => ({
  fontSize: '3.2em',
  marginRight: '0.4em',
  color: isActive ? colors.white : getThemeColor(theme, 'primary')
})

registration.register({
  name: 'Tiles',
  description:
    'A component that can be leveraged as both a group of filters/actionable items as well as summaries for a number of a given resource (i.e Number of Invoice by status).',
  props: [
    {
      name: 'clickCb',
      type: 'function -- (GroupId) => void -- (type GroupId = number)',
      note:
        'The callback which will be called when a given Tile is clicked. The id of that Tile group will be provided as an argument to the callback.'
    },
    {
      name: 'clearable',
      type: 'boolean',
      note:
        'A boolean which, if true, will render an X icon on the active category and invoke the clickCb on Tiles that are active and clicked. This should be false if you want to always have atleast one tile active at all times.'
    },
    {
      name: 'groups',
      type: 'Array<GroupType>',
      note: 'The array of groups to be rendered as Tiles.'
    },
    {
      name: 'group.id',
      type: 'string | number',
      note: 'The unique id for the tile amongst the other tile groups provied.'
    },
    {
      name: 'group.count',
      type: 'number',
      note: 'The number of the given resource to be displayed.'
    },
    { name: 'group.text', type: 'string', note: 'The name of the given resource to be displayed.' },
    {
      name: 'group.Icon',
      optional: true,
      type: 'React.Node',
      note: 'The icon that should be rendered in the tile.'
    },
    {
      name: 'group.isActive',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop that will set the styling to show the tile as active.'
    }
  ],
  example: {
    literal: '',
    render: theme => (
      <Tiles
        clickCb={tileId => {
          // eslint-disable-next-line no-console
          console.log('You selected Tile Group: ' + tileId)
        }}
        renderIcon={(group, style) => <IoIosBriefcase style={{ ...style }} />}
        groups={[
          { id: 'invoices', count: 35, text: 'Invoices' },
          { id: 'matters', count: 24, text: 'Matters' },
          { id: 'vendors', count: 17, text: 'Vendors', isActive: true },
          { id: 'legalEntity', count: 7, text: 'Legal Entity' },
          { id: 'practiceArea', count: 56, text: 'Practice Area' }
        ]}
        clearable
      />
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Tiles/Tiles'
})

export default Tiles
