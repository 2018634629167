import { HTMLAttributes } from 'react'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import { CATEGORY } from 'simple-core-ui/docs/constants'
import registration from 'simple-core-ui/docs/registration'

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: string
}

const Markdown = ({ value, ...rest }: Props) => {
  const parsed = marked.parse(value)
  const clean = DOMPurify.sanitize(parsed)

  return <div {...rest} dangerouslySetInnerHTML={{ __html: clean }} />
}

export default Markdown

registration.register({
  name: 'Markdown',
  description:
    'Provided with stringified markdown/HTML syntax this component will parse it and sanitize.',
  props: [{ name: 'value', type: 'string', note: 'Stringified markdown syntax' }],
  example: {
    literal: `
<Markdown
  value=\"
    # Heading
    ## Subheading
    ### Subsubheading
    *bold* _italic_ ~strike-through~
    <h1>H1</h1>
    <h2>H2</h2>
    <p><br></p><p><span style="color: rgb(230, 0, 0);">green</span> <span style="color: rgb(102, 163, 224);">red</span> <span style="color: rgb(153, 51, 255);">blue</span></p>
  \"
/>
    `,
    render: () => (
      <Markdown
        value={
          '# Heading\n## Subheading\n### Subsubheading\n*bold* _italic_ ~strike-through~\n<h1>H1</h1><h2>H2</h2><p><br></p><p><span style="color: rgb(230, 0, 0);">green</span> <span style="color: rgb(102, 163, 224);">red</span> <span style="color: rgb(153, 51, 255);">blue</span></p>'
        }
      />
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Markdown/Markdown'
})
