import * as React from 'react'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import defaultTo from 'lodash/defaultTo'
import { IoMdPhonePortrait, IoIosMail, IoMdShareAlt } from 'react-icons/io'
import { currencyAwareFormat } from 'utils/formatting'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import { prependProtocol } from 'simple-core-ui/utils/helpers'

import { XeditableForm, XeditableLink } from 'simple-core-ui'

import XeditableCoreContainer from './XeditableCoreContainer'

const LIST_COMBO_TYPES = [
  'list-text',
  'list-textarea',
  'list-number',
  'list-url',
  'list-email',
  'list-phone'
]

const emptyListComboValue = () => ({
  option: null,
  text: ''
})

const isComboValueEmpty = value => isNull(value) || isEqual(value, emptyListComboValue())

const isComboValueIncomplete = ({ option, text }) => !(get(option, 'label') && text)

class XeditableContainer extends React.Component {
  static defaultProps = {
    type: 'text',
    position: 'top',
    placeholder: 'add...',
    cancelCb: () => {}
  }

  static getInitialValue = (initialValue, type) => {
    if (initialValue) {
      return initialValue
    } else if (type === 'list') {
      return null
    } else if (LIST_COMBO_TYPES.includes(type)) {
      return emptyListComboValue()
    } else {
      return ''
    }
  }

  state = (() => {
    const initialState = XeditableContainer.getInitialValue(
      this.props.initialValue,
      this.props.type
    )

    return { formValue: initialState, savedValue: initialState }
  })()

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue && this.props.controlled) {
      const initialState = XeditableContainer.getInitialValue(
        this.props.initialValue,
        this.props.type
      )
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ formValue: initialState, savedValue: initialState })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const initialValue = XeditableContainer.getInitialValue(nextProps.initialValue, nextProps.type)

    if (!isEqual(nextProps.initialValue, initialValue)) {
      const initialState = nextProps.initialValue
      return { savedFields: initialState, formFields: initialState }
    }

    return null
  }

  updateFormValue = value => {
    this.setState({ formValue: value })
  }

  updateSavedValue = () => {
    this.setState(
      ({ formValue, savedValue }) => {
        if (LIST_COMBO_TYPES.includes(this.props.type)) {
          const coalescedValue = isComboValueEmpty(formValue)
            ? emptyListComboValue()
            : isComboValueIncomplete(formValue)
            ? savedValue
            : formValue

          return {
            savedValue: coalescedValue,
            formValue: coalescedValue
          }
        } else {
          return { savedValue: formValue }
        }
      },
      () => {
        this.props.setValueCb(this.state.savedValue)
      }
    )
  }

  validation = () => {
    const { validation } = this.props
    const { formValue } = this.state

    return typeof validation === 'function' ? validation(formValue) : null
  }

  resetForm = () => {
    this.setState(prevState => ({ formValue: prevState.savedValue }))
    this.props.cancelCb()
  }

  renderForm = setValueCb => {
    return this.props.renderForm ? (
      this.props.renderForm(setValueCb, this.updateFormValue, this.state.formValue)
    ) : (
      <XeditableForm
        type={this.props.type}
        value={this.state.formValue}
        changeCb={this.updateFormValue}
        isDisabled={this.props.isDisabled}
        options={this.props.options}
        setValueCb={setValueCb}
        testid={this.props.testid}
        isClearable={this.props.isClearable}
        isMulti={this.props.isMulti}
      />
    )
  }

  getContent = (type, value) => {
    if (type === 'list' && Array.isArray(value)) {
      return value.map(v => v.label).join(', ')
    }
    const label = get(value, 'option.label', '').trim()
    const text = get(value, 'text', '').trim()
    if (type === 'list') {
      return get(value, 'label')
    } else if (type === 'list-textarea') {
      return (
        <React.Fragment>
          <strong>{label}:</strong>
          <p style={{ marginTop: '0.5em' }}>{text}</p>
        </React.Fragment>
      )
    } else if (type === 'currency') {
      return currencyAwareFormat(value.amount, value.code)
    } else if (LIST_COMBO_TYPES.includes(type)) {
      return label && text ? `${label} • ${text}` : `${label || text}`
    } else if (type === 'percentage') {
      return value ? `${value}%` : value
    } else {
      return value
    }
  }

  renderContent = value => {
    const { withoutUnderline, type, renderContent } = this.props
    const noUnderline = withoutUnderline || ['textarea', 'list-textarea'].includes(type)

    const secondaryType = type.replace('list-', '')

    const linkValue = LIST_COMBO_TYPES.includes(type) ? get(value, 'text', '') : value

    const shareLink = defaultTo(
      {
        url: prependProtocol(linkValue),
        email: `mailto:${linkValue}`,
        phone: `tel:${linkValue}`
      }[secondaryType],
      ''
    )

    const shareIcon = defaultTo(
      {
        url: <IoMdShareAlt />,
        email: <IoIosMail />,
        phone: <IoMdPhonePortrait />
      }[secondaryType],
      null
    )

    return renderContent ? (
      renderContent(this.getContent(type, value))
    ) : (
      <XeditableLink
        withoutUnderline={noUnderline}
        shareLink={shareLink}
        shareIcon={shareIcon}
        openLinkInNewTab={['url', 'list-url'].includes(type)}
        readOnly={this.props.readOnly}
      >
        {this.getContent(type, value)}
      </XeditableLink>
    )
  }

  renderPlaceholder = () => {
    const { withoutUnderline, placeholder, readOnly } = this.props

    return (
      <XeditableLink withoutUnderline={withoutUnderline} readOnly={readOnly} asPlaceholder>
        <i>{placeholder}</i>
      </XeditableLink>
    )
  }

  getProxiedProps = () => {
    const {
      type,
      position,
      title,
      isDisabled,
      readOnly,
      isBtnGroupExcluded,
      testid,
      isClosableByOutsideClick,
      isPortal
    } = this.props

    let value = this.state.savedValue

    if (LIST_COMBO_TYPES.includes(type)) {
      value = isComboValueEmpty(value) ? null : value
    }

    return {
      type,
      position,
      value,
      placeholder: this.renderPlaceholder(),
      setValueCb: this.updateSavedValue,
      cancelCb: this.resetForm,
      title,
      renderForm: this.renderForm,
      renderContent: this.renderContent,
      isDisabled,
      readOnly,
      validation: this.validation,
      isBtnGroupExcluded,
      testid,
      isClosableByOutsideClick,
      isPortal
    }
  }

  render() {
    return <XeditableCoreContainer {...this.getProxiedProps()} />
  }
}

registration.register({
  name: 'XeditableContainer',
  description: 'This component allows for the creation of quick inline popover forms.',
  props: [
    {
      name: 'isPortal',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - Boolean prop that will make the popover break out of the parent (use portal)'
    },
    {
      name: 'type',
      optional: true,
      type:
        'string (text | textarea | number | percentage | url | list | list-text | list-textarea | list-number | list-url | list-email | list-phone )',
      note: 'Default: "text" - The basic type for the Xeditable form to render.'
    },
    {
      name: 'position',
      optional: true,
      type: 'string (top | right | bottom | left)',
      note: 'Default: "top" - The position the popover should appear in.'
    },
    {
      name: 'initialValue',
      optional: true,
      type: 'any',
      note: 'The initial value to set the content and value of the input form to.'
    },
    {
      name: 'placeholder',
      optional: true,
      type: 'string',
      note: 'Default: "add..." - The text to display when the content is empty.'
    },
    {
      name: 'setValueCb',
      type: '(any) => void',
      note:
        'A callback that will be called when the Xeditable form is submitted. The callback will receive the value of the form.'
    },
    {
      name: 'cancelCb',
      optional: true,
      type: '() => void',
      note: 'Default: noop - A callback to be triggered when the popover is closed.'
    },
    {
      name: 'title',
      optional: true,
      type: 'string',
      note: 'Default: "" - The title to be displayed at the top of the popover form.'
    },
    {
      name: 'options',
      optional: true,
      type:
        'Array<{value: string, label: string}> | (string, ({options: Array<{value: string, label: string}>) => void) => void',
      note:
        'Default: "null" - The optional prop that should be used with list type Xeditables. The simplest way to set it is an array of options. However, if a function following the specified signature is passed in it will render an Async version of the select and utilize the function for fetching values.'
    },
    {
      name: 'renderContent',
      optional: true,
      type: '(any) => React.Node',
      note:
        'Default: (any) => any - The callback that, provided the value set, should return a React Node. The default is to simply render the value as is.'
    },
    {
      name: 'isDisabled',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - Boolean prop that will disable the input and submit button in the form.'
    },
    {
      name: 'withoutUnderline',
      optional: true,
      type: 'boolean',
      note:
        'Default: true - Boolean prop that will not render the dashed underline under the set value if set to true.'
    },
    {
      name: 'validation',
      optional: true,
      type: '(any) => string',
      note:
        'Default: noop - An optional callback that, provided the submitted value, should return an validation message if there is an error. Otherwise the value should be falsy.'
    },
    {
      name: 'readOnly',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - An optional boolean that can be used to render the Xeditable as read only. It will disable the popover logic and will not render the text as a link.'
    },
    {
      name: 'renderForm',
      optional: true,
      type: '(SetValueCb) => React.Node',
      note:
        'The callback that, provided the value callback to set a value, should return a React Node to render as the form within the popover. The default is to simply render a basic form based on the type specified.'
    }
  ],
  example: {
    literal: `
const style = {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'space-around',
  height: '400px',
  margin: '1em'
}

return (
  <React.Fragment>
    <h3>Primitive Types</h3>
    <section style={style}>
      <XeditableContainer
        type='text'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of Text Type...'
        validation={value => (
          value.toLowerCase() !== value
            ? 'Only lowercase letters are accepted.'
            : null
        )}
      />
      <XeditableContainer
        type='textarea'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of Textarea Type...'
      />
      <XeditableContainer
        type='number'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of Number Type...'
        validation={value => (
          !value.length || Number(value) < 0
            ? 'The value must be greater than zero.'
            : null
        )}
      />
      <XeditableContainer
        type='percentage'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of Percentage Type...'
        validation={value => (
          !value.length || Number(value) < 0
            ? 'The value must be greater than zero.'
            : null
        )}
      />
      <XeditableContainer
        type='url'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of Url Type...'
      />
      <XeditableContainer
        type='email'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of Email Type...'
      />
      <XeditableContainer
        type='phone'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of Phone Type...'
      />
      <XeditableContainer
        type='list'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of List Type...'
        options={[
          {value: 'javascript', label: 'Javascript'},
          {value: 'ruby', label: 'Ruby'},
          {value: 'python', label: 'Python'},
          {value: 'perl', label: 'Perl'},
          {value: 'haskell', label: 'Haskell'},
          {value: 'elm', label: 'Elm'},
          {value: 'rust', label: 'Rust'},
          {value: 'sql', label: 'SQL'},
          {value: 'go', label: 'Go'}
        ]}
      />
    </section>
    <h3>List Combo Types</h3>
    <section style={style}>
      <XeditableContainer
        type='list-text'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of List Text Type...'
        options={[
          {value: 'primary', label: 'Primary'},
          {value: 'secondary', label: 'Secondary'},
          {value: 'other', label: 'Other'}
        ]}
      />
      <XeditableContainer
        type='list-textarea'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of List TextArea Type...'
        options={[
          {value: 'primary', label: 'Primary'},
          {value: 'secondary', label: 'Secondary'},
          {value: 'other', label: 'Other'}
        ]}
      />
      <XeditableContainer
        type='list-number'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of List Number Type...'
        options={[
          {value: 'primary', label: 'Primary'},
          {value: 'secondary', label: 'Secondary'},
          {value: 'other', label: 'Other'}
        ]}
      />
      <XeditableContainer
        type='list-url'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of List URL Type...'
        options={[
          {value: 'primary', label: 'Primary'},
          {value: 'secondary', label: 'Secondary'},
          {value: 'other', label: 'Other'}
        ]}
      />
      <XeditableContainer
        type='list-email'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of List Email Type...'
        options={[
          {value: 'primary', label: 'Primary'},
          {value: 'secondary', label: 'Secondary'},
          {value: 'other', label: 'Other'}
        ]}
      />
      <XeditableContainer
        type='list-phone'
        setValueCb={value => console.log(value)} // eslint-disable-line no-console
        title='Set Value of List Phone Type...'
        options={[
          {value: 'primary', label: 'Primary'},
          {value: 'secondary', label: 'Secondary'},
          {value: 'other', label: 'Other'}
        ]}
      />
    </section>
  </React.Fragment>
)`.trim(),
    render: theme => {
      const style = {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '400px',
        margin: '1em'
      }

      return (
        <React.Fragment>
          <h3>Primitive Types</h3>
          <section style={style}>
            <XeditableContainer
              type="text"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of Text Type..."
              validation={value =>
                value.toLowerCase() !== value ? 'Only lowercase letters are accepted.' : null
              }
            />
            <XeditableContainer
              type="textarea"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of Textarea Type..."
            />
            <XeditableContainer
              type="number"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of Number Type..."
              validation={value =>
                !value.length || Number(value) < 0 ? 'The value must be greater than zero.' : null
              }
            />
            <XeditableContainer
              type="url"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of Url Type..."
            />
            <XeditableContainer
              type="email"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of Email Type..."
            />
            <XeditableContainer
              type="phone"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of Phone Type..."
            />
            <XeditableContainer
              type="list"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of List Type..."
              options={[
                { value: 'javascript', label: 'Javascript' },
                { value: 'ruby', label: 'Ruby' },
                { value: 'python', label: 'Python' },
                { value: 'perl', label: 'Perl' },
                { value: 'haskell', label: 'Haskell' },
                { value: 'elm', label: 'Elm' },
                { value: 'rust', label: 'Rust' },
                { value: 'sql', label: 'SQL' },
                { value: 'go', label: 'Go' }
              ]}
            />
          </section>
          <h3>List Combo Types</h3>
          <section style={style}>
            <XeditableContainer
              type="list-text"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of List Text Type..."
              options={[
                { value: 'primary', label: 'Primary' },
                { value: 'secondary', label: 'Secondary' },
                { value: 'other', label: 'Other' }
              ]}
            />
            <XeditableContainer
              type="list-textarea"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of List TextArea Type..."
              options={[
                { value: 'primary', label: 'Primary' },
                { value: 'secondary', label: 'Secondary' },
                { value: 'other', label: 'Other' }
              ]}
            />
            <XeditableContainer
              type="list-number"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of List Number Type..."
              options={[
                { value: 'primary', label: 'Primary' },
                { value: 'secondary', label: 'Secondary' },
                { value: 'other', label: 'Other' }
              ]}
            />
            <XeditableContainer
              type="list-url"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of List URL Type..."
              options={[
                { value: 'primary', label: 'Primary' },
                { value: 'secondary', label: 'Secondary' },
                { value: 'other', label: 'Other' }
              ]}
            />
            <XeditableContainer
              type="list-email"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of List Email Type..."
              options={[
                { value: 'primary', label: 'Primary' },
                { value: 'secondary', label: 'Secondary' },
                { value: 'other', label: 'Other' }
              ]}
            />
            <XeditableContainer
              type="list-phone"
              setValueCb={value => console.log(value)} // eslint-disable-line no-console
              title="Set Value of List Phone Type..."
              options={[
                { value: 'primary', label: 'Primary' },
                { value: 'secondary', label: 'Secondary' },
                { value: 'other', label: 'Other' }
              ]}
            />
          </section>
        </React.Fragment>
      )
    }
  },
  category: CATEGORY.FORM,
  path: 'containers/Core/XeditableContainer/XeditableContainer'
})

export default XeditableContainer
