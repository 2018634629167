import React from 'react'
import isFunction from 'lodash/isFunction'
import withRouter from 'simple-core-ui/hocs/withRouter'
import { connect } from 'react-redux'
import Loading from '../components/Core/Loading/Loading'
import Page404 from '../components/Core/ErrorPage/Page404'
import Page403 from '../components/Core/ErrorPage/Page403'
import PageJSError from '../components/Core/ErrorPage/PageJSError'
import ErrorBoundaryContainer from '../containers/Utils/ErrorBoundaryContainer/ErrorBoundaryContainer'
import ACT from './actions'

const withFetchFlow = ({
  render,
  getFetchAction,
  filterMode,
  flag = '',
  forceRenderOnBack = false
}) => {
  return WrappedComponent => {
    @connect(({ loading }) => {
      const isLoading = loading[`${flag}Loading`]
      const dataLoaded = loading[`${flag}Loaded`]
      const showErrorPage = loading.showErrorPage
      return {
        isLoading,
        dataLoaded,
        showErrorPage
      }
    })
    @withRouter
    class FetchFlowWrapper extends React.Component {
      UNSAFE_componentWillMount() {
        const { dataLoaded, router } = this.props
        let action = getFetchAction(this.props)
        // detects back button pressing, dont fetch on back button
        if (router.actionType === 'POP' && !!dataLoaded) {
          return
        }

        action.refs = {
          isLoading: `${flag}Loading`,
          dataLoaded: `${flag}Loaded`
        }
        this.props.dispatch(action)
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        const { location, actionType } = this.props.router
        // detects refresh by navigating to same route
        let action = getFetchAction(nextProps)

        if (
          (actionType === 'PUSH' || (forceRenderOnBack && actionType === 'POP')) &&
          nextProps.router.location.key !== location.key &&
          (filterMode ? nextProps.router.location.pathname === location.pathname : true)
        ) {
          action.refs = {
            isLoading: `${flag}Loading`,
            dataLoaded: `${flag}Loaded`
          }
          this.props.dispatch(action)
        }
      }

      componentWillUnmount() {
        if (this.props.showErrorPage) {
          this.props.dispatch({
            type: ACT.SHOW_ERROR_PAGE,
            payload: {
              showErrorPage: 0
            }
          })
        }
      }

      render() {
        const { showErrorPage, isLoading } = this.props

        if (showErrorPage === 404) {
          return <Page404 flag={flag} />
        } else if (showErrorPage === 403) {
          return <Page403 flag={flag} />
        } else if (showErrorPage === 444) {
          return <PageJSError />
        } else if (isLoading) {
          return isFunction(render) ? render(this.props) : <Loading />
        }

        return (
          <ErrorBoundaryContainer>
            {(isError, message) =>
              isError ? <PageJSError /> : <WrappedComponent {...this.props} />
            }
          </ErrorBoundaryContainer>
        )
      }
    }

    return FetchFlowWrapper
  }
}

export default withFetchFlow
