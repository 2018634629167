import { put } from 'redux-saga/effects'

const withQuest = fn => {
  return function*(action) {
    try {
      yield fn(action)
    } catch (e) {
      console.error(e)
      if (e.type !== 'SagaError') {
        yield put({ type: 'SHOW_ERROR_PAGE', payload: { showErrorPage: 444 } })
      }
    }
  }
}

export default withQuest
