import s from './SurveyQuestion.scss'
import cn from 'classnames'

const SurveyChoice = ({ isActive, text, infoText, onClick, qIndex, isClosed }) => {
  const classes = `${isActive ? s.ratingActive : s.rating} ${isClosed ? s.ratingDisabled : ''}`
  return (
    <div className={s.choiceContainer}>
      {!!infoText[text] ? (
        <span
          className={cn(s.infoText, {
            [s.alignLeft]: text === 1,
            [s.alignRight]: text === 10
          })}
        >
          {infoText[text]}
        </span>
      ) : null}

      <span
        onClick={e => !isClosed && onClick(text, qIndex)}
        className={cn(classes, {
          [s.borderRight]: text === 10
        })}
      >
        {text}
      </span>
    </div>
  )
}

export default SurveyChoice
