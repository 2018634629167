import s from './NotFound.scss'
import { Button } from 'simple-core-ui'

const NotFound = () => {
  return (
    <div className={s.container}>
      <div className={s.content}>
        <h1 className={s.errorLabel}>404</h1>
        <p className={s.errorText}>
          {`The page you've requested cannot be found. Please check the URL
          and try again or contact SimpleLegal support for help at `}
          <span>
            <a href="mailto:help@simplelegal.com">help@simplelegal.com</a>
          </span>
          .
        </p>
        <section className={s.buttonWrapper}>
          <Button className={s.dashboardButton} onClick={() => (window.location = '/')}>
            Dashboard
          </Button>
          <Button className={s.prevPageButton} onClick={() => window.history.back()}>
            Back to Previous Page
          </Button>
        </section>
      </div>
    </div>
  )
}

export default NotFound
