const toS3UploadForm = (response, file, lifespan) => {
  var fd = new FormData()

  fd.append('key', response.key)
  fd.append('AWSAccessKeyId', response.AWSAccessKeyId)
  fd.append('acl', 'private')
  fd.append('policy', response.policy)
  fd.append('signature', response.signature)
  fd.append('x-amz-meta-name', file.name)
  fd.append('x-amz-meta-size', file.size)
  fd.append('x-amz-meta-type', file.type)
  fd.append('x-amz-meta-upload-source', document.domain)
  if (lifespan) {
    fd.append(
      'tagging',
      `<Tagging>
        <TagSet>
          <Tag>
            <Key>lifeSpan</Key>
            <Value>${lifespan}</Value>
          </Tag>
        </TagSet>
      </Tagging>`
    )
  }
  fd.append('Content-Type', file.type)
  fd.append('file', file)

  return fd
}

export default toS3UploadForm
