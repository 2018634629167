import registration from 'simple-core-ui/docs/registration'
import { createInitials } from 'simple-core-ui/utils/helpers'
import { CATEGORY } from 'simple-core-ui/docs/constants'
import s from './Avatar.scss'
import cn from 'classnames'
import { CSSProperties } from 'react'
import { Tooltip } from 'simple-core-ui'

interface Props {
  initials: string
  styles?: CSSProperties
  className?: string
  hasTooltip?: boolean
}

const Avatar = ({ initials = '- -', styles, className, hasTooltip }: Props) => (
  <>
    <Tooltip
      trigger={
        <span
          data-testid="avatar"
          className={cn(s.container, {
            [className as string]: className
          })}
          style={styles}
        >
          {createInitials(initials)}
        </span>
      }
      content={hasTooltip ? initials : null}
    />
  </>
)

registration.register({
  name: 'Avatar',
  description:
    'This component should be utilized to represent a given user, displaying their initials up front and their full name on hover',
  props: [
    {
      name: 'initials',
      optional: true,
      type: 'string',
      note: 'A space delimited name. It will be split and converted into initials. Ian Jabour -> IJ'
    },
    { name: 'styles', type: 'object', note: 'Custom styles to be applied to the component.' },
    { name: 'className', type: 'string', note: 'Custom class to be applied to the component.' },
    { name: 'hasTooltip', type: 'boolean', note: 'Show tooptip or not' }
  ],
  example: {
    literal: "<Avatar initials='Ian Jabour' />",
    render: () => <Avatar initials="Ian Jabour" />
  },
  category: CATEGORY.TYPE,
  path: 'components/Core/Avatar/Avatar'
})

export default Avatar
