import { useState, useEffect, useMemo } from 'react'
import { Input, FolderIconFill } from 'simple-core-ui'
import { FaPlusCircle, FaMinusCircle, FaFolder } from 'react-icons/fa'
import { Treebeard, decorators } from 'react-treebeard'
import { treeTheme } from './treeTheme'
import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import * as filters from './helpers'
import cn from 'classnames'
import s from './SearchableTreeView.scss'
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from 'react-icons/io'

const SearchableTreeView = ({ inputData, inputLabel, onNodeSelect, hasNewDesign }) => {
  const [data, setData] = useState(inputData || {})
  const [cursor, setCursor] = useState(false)
  const [searchText, setSearchText] = useState('')

  const treeDecorators = useMemo(
    () => ({
      ...decorators,
      Toggle: props => {
        return props.showIcon ? (
          props.toggled ? (
            hasNewDesign ? (
              <IoIosRemoveCircleOutline className={s.folderIconToggle} />
            ) : (
              <FaMinusCircle className={s.folderToggle} />
            )
          ) : hasNewDesign ? (
            <IoIosAddCircleOutline className={s.folderIconToggle} />
          ) : (
            <FaPlusCircle className={s.folderToggle} />
          )
        ) : (
          ''
        )
      },
      Container: props => {
        return (
          <div
            className={cn(
              s.treeContainer,
              { [s.activeNode]: props.node.active },
              { [s.childNode]: !props.node.children?.length },
              { [s.cleanSpacing]: hasNewDesign }
            )}
            onClick={props.onClick}
          >
            <props.decorators.Toggle
              style={props.style}
              toggled={props.node.toggled}
              showIcon={props.node.children?.length}
            />
            {hasNewDesign ? <FolderIconFill className={s.folderIcon} /> : <FaFolder />}
            <props.decorators.Header {...props} onSelect={props.onSelect} />
          </div>
        )
      }
    }),
    [hasNewDesign]
  )

  useEffect(() => {
    setData(inputData || {})
  }, [inputData])

  const onToggle = (node, toggled) => {
    if (cursor) {
      setCursor({ ...cursor, active: false })
    }
    node.active = true
    if (node.children) {
      node.toggled = toggled
    }
    setCursor(node)
    setData(Object.assign({}, filters.setAllOtherInactive(data, node)))
    onNodeSelect && onNodeSelect(node)
  }

  const onFilterChange = value => {
    if (cursor) {
      setCursor({ ...cursor, active: false })
    }
    onNodeSelect && onNodeSelect(null)
    setSearchText(value)
    const filter = value.trim()
    if (!filter) {
      setData(inputData)
    } else {
      let filtered = filters.filterTree(inputData, filter)
      filtered = filters.expandFilteredNodes(filtered, filter)
      setData(filtered)
    }
  }

  return (
    <section>
      <div className={s.filterInfo}>{inputLabel}</div>
      <Input
        type="text"
        text={searchText}
        cssClass={s.nameInput}
        onChangeCb={event => onFilterChange(event.target.value)}
        placeholder="Type to search..."
      />
      <div className={s.tree}>
        <Treebeard data={data} onToggle={onToggle} decorators={treeDecorators} style={treeTheme} />
      </div>
    </section>
  )
}

registration.register({
  name: 'SearchableTreeView',
  description:
    'A component that will render a searchable tree view structure. It can be used for browsing folders and/or files',
  props: [
    {
      name: 'inputData',
      type: 'JSON Object',
      note: 'The input data that should be a JSON Object taht corresponds to the tree structure.'
    },
    {
      name: 'inputLabel',
      type: 'string',
      note: 'The search text label.'
    },
    {
      name: 'onSelect',
      type: '(node: JSON Object) => void',
      optional: true,
      note: 'The function to be called when a node is selected.'
    }
  ],
  example: {
    render: () => {
      const testData = {
        name: 'root',
        toggled: true,
        children: [
          {
            name: 'parent',
            children: [{ name: 'child 1' }, { name: 'child 2' }]
          },
          {
            name: 'loading parent',
            loading: true,
            children: []
          },
          {
            name: 'parent',
            children: [
              {
                name: 'nested parent',
                children: [{ name: 'nested child 1' }, { name: 'nested child 2' }]
              }
            ]
          }
        ]
      }
      return <SearchableTreeView inputData={testData} />
    }
  },
  category: CATEGORY.MISC,
  path: 'components/Core/SearchableTreeView/SearchableTreeView'
})

export default SearchableTreeView
