import { SVGProps } from 'react'

const LawRuleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={93} fill="none" {...props}>
      <path
        fill="#56C786"
        d="M30.793 38.448v-15.59c0-.635.527-1.264 1.264-1.264h11.27c2.106 0 3.896-1.79 3.896-3.897V6.32c0-.736.527-1.263 1.263-1.263h28.86c2 0 3.686 1.687 3.686 3.687V31.18l5.267-5.686.004-16.649C86.303 3.896 82.3 0 77.457 0H47.963c-2.423 0-4.633.946-6.32 2.633L28.263 16.01c-1.688 1.687-2.634 3.896-2.634 6.32v16.22c.843-.317 1.687-.527 2.633-.527.844 0 1.794.107 2.53.424h.001ZM81.039 65.62c0 2-1.687 3.686-3.687 3.686H60.606c.21.947.107 1.897-.107 2.74-.21.844-.527 1.688-.844 2.527h17.8c4.95 0 8.846-4.003 8.846-8.953l.004-10.953-5.266 5.686v5.267ZM70.61 39.92H41.328a2.61 2.61 0 0 0-2.634 2.634 2.61 2.61 0 0 0 2.634 2.633h27.07l4.106-4.53c-.526-.42-1.156-.737-1.892-.737Z"
      />
      <path
        fill="#56C786"
        d="M70.61 28.123H41.328a2.61 2.61 0 0 0-2.634 2.634 2.61 2.61 0 0 0 2.634 2.633H70.61a2.61 2.61 0 0 0 2.633-2.633 2.607 2.607 0 0 0-2.633-2.634ZM70.612 16.327H56.92a2.61 2.61 0 0 0-2.634 2.633 2.61 2.61 0 0 0 2.634 2.634h13.586a2.61 2.61 0 0 0 2.633-2.634c.107-1.477-1.053-2.633-2.526-2.633ZM65.554 60.568c-.42 1.37.946 2.633 2.316 2.107l7.584-3.16a6.002 6.002 0 0 0 1.896-1.264l-8.217-7.48c-.42.634-.843 1.263-1.053 2l-2.526 7.797ZM99.261 30.86l-4.95-4.53c-.946-.844-2.316-.737-3.16.106L71.874 47.503l8.32 7.584L99.47 34.019c.737-.839.737-2.316-.21-3.16ZM47.857 52.668c-.316-.634-.843-.946-1.473-1.053l-11.479.107c-.527-1.58-1.687-2.95-3.16-3.794v-2.214c0-1.897-1.58-3.477-3.477-3.477-1.897 0-3.477 1.58-3.477 3.477v2.214c-1.473.843-2.633 2.214-3.16 3.794l-11.48-.107c-.633 0-1.16.42-1.472 1.053L.252 69.414c-.21.527-.317 1.054-.21 1.58 1.473 5.16 5.477 8.534 10.217 8.534s8.743-3.37 10.216-8.534c.107-.526.107-1.053-.21-1.58l-6.53-12.957h7.9c.527 1.58 1.688 2.95 3.16 3.794v17.59c0 4.213-3.16 7.69-7.266 8.11l-6.53.736c-.736.107-1.263.737-1.263 1.37v3.16c0 .737.634 1.37 1.37 1.37h34.342c.736 0 1.37-.633 1.37-1.37v-3.16c0-.736-.527-1.37-1.263-1.37l-6.53-.736c-4.214-.42-7.267-3.897-7.267-8.11l-.016-17.698c1.473-.843 2.633-2.106 3.16-3.794h7.9l-6.53 12.958c-.21.526-.317 1.053-.21 1.58 1.473 5.16 5.477 8.534 10.217 8.534s8.744-3.37 10.217-8.534c.107-.527.107-1.053-.21-1.58l-8.429-16.639Zm-32.234 16.01H4.776l5.477-10.85 5.37 10.85Zm25.177 0 5.477-10.85 5.476 10.85H40.8Z"
      />
    </svg>
  )
}

export default LawRuleIcon
