export default {
  rows: [
    {
      id: 1,
      cells: [
        { columnKey: 'invoiceNumber', content: 4128 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        { columnKey: 'description', content: 'Adipisicing in architecto illum aliquid natus.' },
        { columnKey: 'total', content: '$6683' },
        { columnKey: 'status', content: 'Rejected' }
      ],
      attachments: false,
      avatar: 'Kurt Rose',
      canDelete: true,
      borderColor: 'red'
    },
    {
      id: 2,
      cells: [
        { columnKey: 'invoiceNumber', content: 6146 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content:
            'Ducimus quidem nihil quam officiis eligendi reprehenderit mollitia Neque voluptatum?'
        },
        { columnKey: 'total', content: '$96287' },
        { columnKey: 'status', content: 'Sent to AP' }
      ],
      attachments: false,
      avatar: 'Zac Arellano',
      canDelete: true,
      borderColor: 'mediumpurple'
    },
    {
      id: 3,
      cells: [
        { columnKey: 'invoiceNumber', content: 7078 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content:
            'Ducimus quidem nihil quam officiis eligendi reprehenderit mollitia Neque voluptatum?'
        },
        { columnKey: 'total', content: '$52428' },
        { columnKey: 'status', content: 'Open' }
      ],
      attachments: false,
      avatar: 'David Lu',
      canDelete: true,
      borderColor: 'mediumseagreen'
    },
    {
      id: 4,
      cells: [
        { columnKey: 'invoiceNumber', content: 8891 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content: 'Voluptate totam excepturi sapiente est velit facere at?'
        },
        { columnKey: 'total', content: '$51348' },
        { columnKey: 'status', content: 'Rejected' }
      ],
      attachments: true,
      avatar: 'Kelly Slater',
      canDelete: true,
      borderColor: 'red'
    },
    {
      id: 5,
      cells: [
        { columnKey: 'invoiceNumber', content: 4539 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content:
            'Elit id ducimus quaerat dolores dicta Modi recusandae veniam dolores maiores necessitatibus? Tenetur eum rem quod voluptas eligendi Porro ipsa nemo sed cupiditate dignissimos? Pariatur magnam consectetur culpa excepturi obcaecati'
        },
        { columnKey: 'total', content: '$71655' },
        { columnKey: 'status', content: 'Sent to AP' }
      ],
      attachments: false,
      avatar: 'Cameron Cairns',
      canDelete: true,
      borderColor: 'mediumpurple'
    },
    {
      id: 6,
      cells: [
        { columnKey: 'invoiceNumber', content: 8324 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        { columnKey: 'description', content: 'Adipisicing in architecto illum aliquid natus.' },
        { columnKey: 'total', content: '$21752' },
        { columnKey: 'status', content: 'Sent to AP' }
      ],
      attachments: true,
      avatar: 'Akash Deshpande',
      canDelete: true,
      borderColor: 'mediumpurple'
    },
    {
      id: 7,
      cells: [
        { columnKey: 'invoiceNumber', content: 8202 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content:
            'Lorem laborum laborum architecto cumque quidem. Animi atque modi iure minima at. Expedita a accusamus aliquam optio in beatae? Alias nulla minima harum fugit ipsum recusandae Minima atque ratione eius?'
        },
        { columnKey: 'total', content: '$82154' },
        { columnKey: 'status', content: 'Sent to AP' }
      ],
      attachments: true,
      avatar: 'Zac Arellano',
      canDelete: true,
      borderColor: 'mediumpurple'
    },
    {
      id: 8,
      cells: [
        { columnKey: 'invoiceNumber', content: 5166 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content: 'Voluptate totam excepturi sapiente est velit facere at?'
        },
        { columnKey: 'total', content: '$85456' },
        { columnKey: 'status', content: 'Processing' }
      ],
      attachments: true,
      avatar: 'David Lu',
      canDelete: true,
      borderColor: 'skyblue'
    },
    {
      id: 9,
      cells: [
        { columnKey: 'invoiceNumber', content: 3096 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content:
            'Ducimus quidem nihil quam officiis eligendi reprehenderit mollitia Neque voluptatum?'
        },
        { columnKey: 'total', content: '$68808' },
        { columnKey: 'status', content: 'Closed' }
      ],
      attachments: false,
      avatar: 'Micheal Jordan',
      canDelete: true,
      borderColor: 'gray'
    },
    {
      id: 10,
      cells: [
        { columnKey: 'invoiceNumber', content: 6125 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content:
            'Ducimus quidem nihil quam officiis eligendi reprehenderit mollitia Neque voluptatum?'
        },
        { columnKey: 'total', content: '$89290' },
        { columnKey: 'status', content: 'Open' }
      ],
      attachments: true,
      avatar: 'Micheal Jordan',
      canDelete: true,
      borderColor: 'mediumseagreen'
    },
    {
      id: 11,
      cells: [
        { columnKey: 'invoiceNumber', content: 5207 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content: 'Voluptate totam excepturi sapiente est velit facere at?'
        },
        { columnKey: 'total', content: '$30100' },
        { columnKey: 'status', content: 'Closed' }
      ],
      attachments: false,
      avatar: 'Darren Dahl',
      canDelete: true,
      borderColor: 'gray'
    },
    {
      id: 12,
      cells: [
        { columnKey: 'invoiceNumber', content: 5064 },
        { columnKey: 'invoiceDate', content: 'Fri Aug 17 2018' },
        {
          columnKey: 'description',
          content:
            'Lorem eius odit minus delectus temporibus? Vitae debitis placeat accusantium quae quidem? Magni mollitia dignissimos inventore voluptatibus aliquam Maiores ullam quibusdam elit incidunt non ad Molestiae laudantium reiciendis fugiat totam.'
        },
        { columnKey: 'total', content: '$33600' },
        { columnKey: 'status', content: 'Sent to AP' }
      ],
      attachments: true,
      avatar: 'Micheal Jordan',
      canDelete: true,
      borderColor: 'darkorange'
    }
  ],
  columns: [
    { columnKey: 'invoiceNumber', content: 'Number', isSortable: true },
    {
      columnKey: 'invoiceDate',
      content: 'Date',
      isSortable: true,
      style: { whiteSpace: 'nowrap' }
    },
    { columnKey: 'description', content: 'Description' },
    {
      columnKey: 'total',
      content: 'Total',
      isSortable: true,
      isDesc: true,
      style: { textAlign: 'right' }
    },
    { columnKey: 'status', content: 'Status' }
  ]
}
