import { SVGProps } from 'react'

const FolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={22} fill="none" {...props}>
    <rect width={9.5} height={20} x={0.5} y={0.5} fill="url(#a)" stroke="url(#b)" rx={0.5} />
    <g filter="url(#c)">
      <rect width={24} height={18} y={3} fill="url(#d)" rx={1} />
      <rect width={23} height={17} x={0.5} y={3.5} stroke="url(#e)" rx={0.5} />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={2.169}
        x2={2.169}
        y1={7.026}
        y2={19.349}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#93DDFF" />
        <stop offset={1} stopColor="#93DDFF" />
      </linearGradient>
      <linearGradient id="b" x1={2.29} x2={2.29} y1={0} y2={11.842} gradientUnits="userSpaceOnUse">
        <stop stopColor="#75BDDD" />
        <stop offset={1} stopColor="#7AC8E9" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={2.05}
        x2={2.05}
        y1={4.66}
        y2={19.584}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#68CBF8" />
        <stop offset={1} stopColor="#73D1FA" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={10.449}
        x2={10.449}
        y1={3}
        y2={5.326}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#75BADC" />
        <stop offset={1} stopColor="#55A9CF" />
      </linearGradient>
      <filter
        id="c"
        width={24}
        height={19}
        x={0}
        y={3}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feColorMatrix values="0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 0 0.870588 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_563_29205" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_563_29205" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect2_innerShadow_563_29205" />
      </filter>
    </defs>
  </svg>
)
export default FolderIcon
