import { Popable, Button } from 'simple-core-ui'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const HelpText = ({ message, children, hasUnderline, position, className = '', messageStyles }) => (
  <Popable
    openOnHover
    position={position}
    content={message}
    className={className}
    contentStyles={{
      ...messageStyles,
      padding: '0.5em',
      borderRadius: '2px',
      whiteSpace: 'nowrap'
    }}
    styles={
      hasUnderline
        ? {
            borderBottom: '1px dotted',
            lineHeight: 'normal',
            cursor: 'help'
          }
        : {}
    }
  >
    {children}
  </Popable>
)

HelpText.defaultProps = {
  hasUnderline: true,
  position: 'top'
}

registration.register({
  name: 'HelpText',
  description:
    'A simple component that can be used to provide help text around a given element. Showing on hover.',
  props: [
    {
      name: 'message',
      type: 'React.Node',
      note: 'The help text that will be rendered and shown on hover.'
    },
    {
      name: 'children',
      type: 'React.Node',
      note: 'The component that will be wrapped with the given help text.'
    },
    {
      name: 'hasUnderline',
      type: 'boolean',
      optional: true,
      defaultValue: 'true',
      note:
        'If true the children will be rendered with a dotted border below them, indicating that the user can hover for info.'
    }
  ],
  example: {
    literal: `
<section
  style={{
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100px'
  }}
>
  <span>
    Ian Jabour, Cameron Cairns, Darren Dahl, and
    {' '}
    <HelpText message='Only one member of this team needs to review.'>
      Management
    </HelpText>
  </span>
  <HelpText
    message='When clicked a response will be submitted to the vendor.'
    hasUnderline={false}
  >
    <Button isPrimary>Submit Response</Button>
  </HelpText>
</section>`,
    render: theme => (
      <section
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'space-around',
          height: '100px'
        }}
      >
        <span>
          Ian Jabour, Cameron Cairns, Darren Dahl, and{' '}
          <HelpText message="Only one member of this team needs to review.">Management</HelpText>
        </span>
        <HelpText
          message="When clicked a response will be submitted to the vendor."
          hasUnderline={false}
        >
          <Button isPrimary>Submit Response</Button>
        </HelpText>
      </section>
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/HelpText/HelpText'
})

export default HelpText
