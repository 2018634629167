import swal from 'sweetalert'
import { SwalOptions } from 'sweetalert/typings/modules/options'

interface Options extends Partial<Omit<SwalOptions, 'buttons'>> {
  title: string
  text: string
  cancelText?: string
  acceptText?: string
  buttons?: SwalOptions['buttons']
}

const withConfirmation = (
  cb: (...args: any[]) => void,
  opts?: Options,
  secondaryCb?: (...args: any[]) => void
) => {
  const { title, text, cancelText = 'Cancel', acceptText = 'Yes', buttons } = opts || {}
  return async (...args: any[]) => {
    const willConfirm = await swal({
      ...opts,
      buttons: buttons || [cancelText, acceptText],
      title,
      text
    })
    if (willConfirm === 'secondaryConfirm' && secondaryCb) {
      return secondaryCb(...args)
    }
    if (willConfirm) {
      return cb(...args, willConfirm)
    }
  }
}

export default withConfirmation
