import s from './Uploadable.scss'

const Uploadable = ({ onChange, acceptedTypes, multiple, style, render }) => (
  <label className={s.container} style={style}>
    <input
      type="file"
      accept={acceptedTypes}
      multiple={multiple}
      className={s.input}
      onChange={onChange}
    />
    {render()}
  </label>
)

export default Uploadable
