const DataTableSerializer = {
  getRows: (rows, columns) => {
    return rows.map((row, index) => {
      return {
        id: row.id || index,
        cells: columns.map(({ columnKey }) => {
          return {
            columnKey,
            content:
              +(row[columnKey] !== 0) &&
              (row[columnKey] || (row[columnKey] === false ? false : '----'))
          }
        }),
        is_sub_title: row.is_sub_title
          ? {
              value: true,
              text: row.title
            }
          : false
      }
    })
  },

  getRowsByCategory: (dataByCategory, columns, customBlank) => {
    let rowsByCategory = {}

    Object.keys(dataByCategory).forEach(category => {
      if (!Array.isArray(dataByCategory[category])) {
        return
      }

      rowsByCategory[category] = dataByCategory[category].map((row, index) => {
        return {
          id: row.id || index,
          cells: columns.map(col => {
            return {
              columnKey: col.columnKey,
              content:
                +(row[col.columnKey] !== 0) && row[col.columnKey]
                  ? row[col.columnKey]
                  : customBlank
                  ? customBlank
                  : '----'
            }
          }),
          avatar: row.avatar,
          borderColor: row.borderColor,
          actions: row.actions,
          ...row
        }
      })
    })

    return rowsByCategory
  }
}

export default DataTableSerializer
