import { Avatar } from 'simple-core-ui'

import s from '../Table.scss'

const TableRowAvatar = ({ person, styles }) => (
  <td title={person || '(empty)'} className={s.avatar}>
    <Avatar initials={person} styles={styles} />
  </td>
)

export default TableRowAvatar
