import { CSSProperties, MouseEvent, ReactNode } from 'react'
import classNames from 'classnames'
import get from 'lodash/get'

import { If, Spinner, SimpleContext, coreThemes } from 'simple-core-ui'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

import s from './Button.scss'

interface Props {
  onClick?(e: MouseEvent<HTMLElement>): void
  children?: ReactNode
  isDisabled?: boolean
  isLoading?: boolean
  isPrimary?: boolean
  isSecondary?: boolean
  isOutline?: boolean
  className?: string
  style?: CSSProperties
  type?: 'button' | 'submit' | 'reset'
  testid?: string
  id?: string
  href?: string
  ariaLabel?: string
  hasNewDesign?: boolean
  isCg?: boolean
  isFill?: boolean
}

const Button = ({
  onClick,
  children,
  isDisabled,
  isLoading,
  isPrimary,
  isSecondary,
  isOutline,
  className,
  style = {},
  type,
  testid,
  id,
  href,
  ariaLabel,
  hasNewDesign,
  isCg,
  isFill
}: Props) => (
  <SimpleContext.Consumer>
    {themeContext => {
      return !!href ? (
        <a
          id={id}
          data-testid={testid}
          href={href}
          className={classNames(s.button, className, s[get(themeContext, 'theme', coreThemes.EB)], {
            [s.secondary]: isSecondary,
            [s.primary]: isPrimary && !isOutline,
            [s.primaryOutline]: isPrimary && isOutline,
            [s.isLoading]: isLoading,
            [s.hasNewDesign]: hasNewDesign
          })}
          style={style}
          aria-label={ariaLabel}
        >
          {children}
        </a>
      ) : (
        <button
          id={id}
          data-testid={testid}
          onClick={e => onClick && !isLoading && onClick(e)}
          className={classNames(s.button, className, s[get(themeContext, 'theme', coreThemes.EB)], {
            [s.secondary]: isSecondary,
            [s.primary]: isPrimary && !isOutline,
            [s.primaryOutline]: isPrimary && isOutline,
            [s.isLoading]: isLoading,
            [s.hasNewDesign]: hasNewDesign,
            [s.cgButton]: isCg,
            [s.cgFillButton]: isCg && isFill
          })}
          type={type || 'button'}
          disabled={isDisabled}
          style={style}
          aria-label={ariaLabel}
        >
          {children}
          <If condition={!!isLoading}>
            <Spinner className={s.spinner} />
          </If>
        </button>
      )
    }}
  </SimpleContext.Consumer>
)

registration.register({
  name: 'Button',
  description: 'The button component for the application',
  props: [
    {
      name: 'onClick',
      type: '() => void',
      optional: true,
      note: 'The callback to get invoked when the button is clicked.'
    },
    {
      name: 'children',
      optional: true,
      type: 'React.Node',
      note: 'The content to render within the button'
    },
    {
      name: 'isDisabled',
      optional: true,
      type: 'boolean',
      note: 'Optional boolean prop to disable the button.'
    },
    {
      name: 'isLoading',
      optional: true,
      type: 'boolean',
      note: 'Renders a spinner within the button and prevents click handler from being called.'
    },
    {
      name: 'isPrimary',
      optional: true,
      type: 'boolean',
      note: 'Optional boolean prop to render in primary form.'
    },
    {
      name: 'isSecondary',
      optional: true,
      type: 'boolean',
      note: 'Optional boolean prop to render in secondary form.'
    },
    { name: 'style', optional: true, type: 'Object', note: 'Custom styles for the button.' },
    { name: 'id', optional: true, type: 'string', note: 'Optional string prop.' },
    { name: 'testid', optional: true, type: 'string', note: 'Optional string prop.' }
  ],
  example: {
    literal: `
<section
  style={{
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em',
    backgroundColor: theme === 'cg' ? 'black' : 'transparent'
  }}
>
  <Button
    onClick={() => console.log('Clicked base button...')} // eslint-disable-line no-console
  >
    Primary
  </Button>
  <Button
    onClick={() => console.log('Clicked secondary button...')} // eslint-disable-line no-console
    isSecondary
  >
    Secondary
  </Button>
  <Button isDisabled>Primary (Disabled)</Button>
  <Button isDisabled isSecondary>Secondary (Disabled)</Button>
</section>`.trim(),
    render: (theme: string) => {
      const Flex = ({
        direction = 'row',
        children
      }: {
        direction?: string
        children: ReactNode
      }) => (
        <section
          style={{
            display: 'flex',
            flexFlow: `${direction} wrap`,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1em',
            backgroundColor: theme === 'cg' ? 'black' : 'transparent'
          }}
        >
          {children}
        </section>
      )

      return (
        <Flex direction="column">
          <Flex>
            <Button
              onClick={() => console.log('Clicked base button...')} // eslint-disable-line no-console
            >
              Base
            </Button>
            <Button
              onClick={() => console.log('Clicked primary button...')} // eslint-disable-line no-console
              isPrimary
            >
              Primary
            </Button>
            <Button
              onClick={() => console.log('Clicked secondary button...')} // eslint-disable-line no-console
              isSecondary
            >
              Secondary
            </Button>
          </Flex>
          <Flex>
            <Button isDisabled>Base (Disabled)</Button>
            <Button isDisabled isPrimary>
              Primary (Disabled)
            </Button>
            <Button isDisabled isSecondary>
              Secondary (Disabled)
            </Button>
          </Flex>
          <Flex>
            <Button isLoading>Base (isLoading)</Button>
            <Button isLoading isPrimary>
              Primary (isLoading)
            </Button>
            <Button isLoading isSecondary>
              Secondary (isLoading)
            </Button>
          </Flex>
        </Flex>
      )
    }
  },
  category: CATEGORY.FORM,
  path: 'components/Core/Button/Button'
})

export default Button
