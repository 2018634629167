import { processAttachment } from '../utils/helpers'
import { makePostRequest } from '../utils/api'
import { CancelToken } from 'axios'

const getLabelIds = (file: CustomFile): Array<string> => {
  return file.labels && file.labels.length ? file.labels.map(l => l.value) : []
}

const getCategoryId = (file: CustomFile): string => (file.category ? file.category.value : '')

type CustomFile = File & {
  category?: {
    value: string
  }
  labels?: Array<{
    value: string
  }>
}

type Params = {
  withLoading?: {
    start(): void
    end(): void
  }
  uploadUrl: string | null
  successCb(response: any): void
  files: Array<CustomFile>
  folderId?: number
  errorCb(): void
  cancelCb?(): void
  s3successCb?(
    bucketUrl: string,
    fileKey: string,
    fileName: string,
    fileSize: number,
    fileType: string
  ): void | Promise<void>
  handleServerErrorCb?(error: any): void
  withS3Credentials?: boolean
  altRequestBody?: any
  config: {
    onUploadProgress(progressEvent: ProgressEvent): void
    cancelToken: CancelToken
  }
  lifespan?: 'short' | 'medium' | 'long' | undefined
}

const useFilesUpload = async (params: Params) => {
  const {
    withLoading,
    uploadUrl,
    successCb,
    files,
    folderId,
    errorCb,
    cancelCb,
    s3successCb,
    handleServerErrorCb,
    withS3Credentials,
    altRequestBody,
    config,
    lifespan
  } = params

  type NameAttachments = {
    files: Array<{
      key: string
      name: string
      size: number
      contentType: string
      categoryId?: string
      labelIds?: Array<string>
    }>
    folder_id: typeof folderId
  }

  const nameAttachments: NameAttachments = {
    files: [],
    folder_id: folderId
  }

  try {
    // do something when loading starts
    withLoading && withLoading.start()

    for (const file of files) {
      const attachment = await processAttachment(
        file,
        config,
        errorCb,
        cancelCb,
        s3successCb,
        lifespan
      )
      if (attachment) {
        nameAttachments.files.push({
          key: attachment.key,
          name: attachment.name,
          size: attachment.size,
          contentType: attachment.contentType,
          ...(file.category ? { categoryId: getCategoryId(file) } : {}),
          ...(file.labels
            ? {
                labelIds: getLabelIds(file)
              }
            : {})
        })
      }
    }

    if (nameAttachments.files.length) {
      // this request saves the files references to db after the upload to S3
      if (!uploadUrl) {
        return withLoading && withLoading.end()
      }

      const response = await makePostRequest(
        uploadUrl,
        altRequestBody ? altRequestBody : nameAttachments,
        config
      )

      // do something when loading ends
      withLoading && withLoading.end()

      if (successCb) {
        if (withS3Credentials) {
          successCb({ ...response, s3Files: nameAttachments.files })
        } else {
          successCb(response.attachments)
        }
      }
    } else {
      withLoading && withLoading.end()
    }
  } catch (error) {
    handleServerErrorCb && handleServerErrorCb(error)
    withLoading && withLoading.end()
    throw error
  }
}

export default useFilesUpload
