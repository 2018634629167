import s from './LoadingSkeleton.scss'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const LoadingSkeleton = ({ width, height, circle, line, style: customStyle }) => {
  const defaultSize = '100%'
  const lineHeight = 2
  const lineStyle = { height: lineHeight, borderRadius: '1px' }
  const circleStyle = { borderRadius: `${height / 2}px` }
  const style = {
    width: width || defaultSize,
    height: height || defaultSize,
    ...(line && lineStyle),
    ...(circle && circleStyle)
  }

  return (
    <div
      className={s.loadingSkeleton}
      style={{
        ...style,
        ...customStyle
      }}
    >
      &zwnj;
    </div>
  )
}

registration.register({
  name: 'LoadingSkeleton',
  description:
    'Another way to indicate loading of data. Used to build more complex components that represent elements on the screen',
  props: [
    { name: 'width', type: 'number', optional: true, note: 'The width of the element.' },
    { name: 'height', type: 'number', optional: true, note: 'The height of the element.' },
    { name: 'circle', type: 'boolean', optional: true, note: 'Circle type of the element.' },
    { name: 'line', type: 'boolean', optional: true, note: 'Line type of the element.' },
    { name: 'style', type: 'object', optional: true, note: 'Custom styling.' }
  ],
  example: {
    literal: `
      <LoadingSkeleton height={28} width={28} circle style={{ marginBottom: 20 }} />
<LoadingSkeleton height={28} width={160} style={{ marginBottom: 20 }} />
<LoadingSkeleton width={200} line />`,
    render: () => {
      return (
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1em'
          }}
        >
          <LoadingSkeleton height={28} width={28} circle style={{ marginBottom: 20 }} />
          <LoadingSkeleton height={28} width={160} style={{ marginBottom: 20 }} />
          <LoadingSkeleton width={200} line />
        </section>
      )
    }
  },
  category: CATEGORY.ANIMATE,
  path: 'components/Core/LoadingSkeleton/LoadingSkeleton'
})

export default LoadingSkeleton
