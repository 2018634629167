import React from 'react'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import { coreThemes } from 'simple-core-ui/utils/constants'
import EbPanel from './EbPanel/EbPanel'
import CgPanel from './CgPanel/CgPanel'
import { SimpleContext } from 'simple-core-ui'

const Panel = ({ ...props }) => (
  <SimpleContext.Consumer>
    {({ theme }) => {
      return theme === coreThemes.EB ? <EbPanel {...props} /> : <CgPanel {...props} />
    }}
  </SimpleContext.Consumer>
)

registration.register({
  name: 'Panel',
  description:
    'The standard panel that is used throughout the applications. Due to implementation differences, the props you send in to configure this component will change based on the theme you provide it.',
  props: [
    { name: 'title', type: 'string', note: 'The text in the header of the panel.' },
    { name: 'subtitle', type: 'string', note: 'The subtext in the header of the panel.' },
    {
      name: 'children',
      type: 'React Nodes',
      note: 'The main content to be rendered within the panel.'
    },
    {
      name: 'leftActions',
      type: 'Array<React Nodes>',
      note: 'EB Theme Only. Same as actions prop but render on the left side of the panel header.'
    },
    {
      name: 'actions',
      type: 'Array<React Nodes>',
      note: 'These are actionable elements like buttons to be rendered in the header of the panel.'
    },
    {
      name: 'rightActions',
      type: 'Array<React Nodes>',
      note: 'EB Theme Only. Same as actions prop but render on the right side of the panel header.'
    },
    {
      name: 'isClosed',
      type: 'boolean',
      note:
        'EB Theme Only. If true it will render the header a grey used to display that this panel is disabled.'
    },
    {
      name: 'className',
      type: 'string',
      note:
        'EB Theme Only. A class name to be passed in. It should be one defined outside the scope of the component.'
    },
    {
      name: 'styles',
      type: 'object',
      note: 'EB Theme Only. Custom styles to be applied to the panel container.'
    },
    {
      name: 'isBodyOnly',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - Optional boolean prop to only render the body of the panel and not the header portion.'
    },
    {
      name: 'panelStyles',
      type: 'object',
      note: 'CG Theme Only. Custom styles to be applied to the panel container.'
    },
    {
      name: 'innerStyles',
      type: 'object',
      note: 'CG Theme Only. Custom styles to be applied to body of the panel.'
    },
    {
      name: 'hasInnerPadding',
      type: 'boolean',
      note: 'CG Theme Only. If true additional padding is set within the body of the panel.'
    },
    {
      name: 'isError',
      optional: true,
      type: 'boolean',
      note:
        'Optional boolean prop to set the error state for the panel from the outside. It naturally has a boundary for catching errors and displaying a message accordingly.'
    }
  ],
  example: {
    literal: `
<Panel
  title='Simple Core UI'
  theme // Selected theme should go here...
>
  <h4 style={{textAlign: 'center', padding: '2em 0', width: '100%'}}>
    Panel Content goes here...
  </h4>
</Panel>
    `.trim(),
    render: theme => (
      <React.Fragment>
        <section style={{ marginBottom: '1em' }}>
          <Panel title="Simple Core UI">
            <h4
              style={{
                textAlign: 'center',
                padding: '2em 0',
                width: '100%'
              }}
            >
              Panel Content goes here...
            </h4>
          </Panel>
        </section>
        <section>
          <Panel isBodyOnly>
            <h4
              style={{
                textAlign: 'center',
                padding: '2em 0',
                width: '100%'
              }}
            >
              Panel Content without header...
            </h4>
          </Panel>
        </section>
      </React.Fragment>
    )
  },
  category: CATEGORY.LAYOUT,
  path: 'components/Core/Panel/Panel'
})

export default Panel
