import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import s from './Pagination.scss'

const PaginationPage = ({ page, text, isActive, isArrow, isFirst, fn }) => {
  let className = ''
  if (isActive) {
    className = s.activePage
  } else if (!page) {
    className = s.disabledPage
  } else {
    className = s.defaultPage
  }
  return (
    <li className={s.item}>
      <span
        className={className}
        onClick={() => {
          if (page) {
            fn(page)
          }
        }}
      >
        {isArrow ? isFirst ? <IoIosArrowBack /> : <IoIosArrowForward /> : text}
      </span>
    </li>
  )
}

export default PaginationPage
