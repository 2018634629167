const StarryIcon = ({ style = {} }) => {
  return (
    <div
      style={{
        backgroundColor: '#DCAC00',
        width: '26px',
        height: '27px',
        borderRadius: '3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style
      }}
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3208 7.14188L17.2446 4.64472L14.6416 3.49603L17.2446 2.3723L18.3208 0L19.397 2.3723L22 3.49603L19.397 4.64472L18.3208 7.14188ZM18.3208 22L17.2446 19.6027L14.6416 18.479L17.2446 17.3553L18.3208 14.8331L19.397 17.3553L22 18.479L19.397 19.6027L18.3208 22ZM7.33333 18.1544L5.03072 13.235L0 10.9875L5.03072 8.74007L7.33333 3.84563L9.66098 8.74007L14.6667 10.9875L9.66098 13.235L7.33333 18.1544ZM7.33333 14.4586L8.5347 12.0613L10.9875 10.9875L8.5347 9.91373L7.33333 7.51646L6.157 9.91373L3.67918 10.9875L6.157 12.0613L7.33333 14.4586Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

export default StarryIcon
