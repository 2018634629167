import { useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { makeGetRequest } from 'utils/api'
import Select, { components } from 'react-select'
import { useDispatch } from 'react-redux'
import { Ellipsis } from 'simple-core-ui'

const PaginatedSelect = ({
  size = 20,
  serializer,
  url,
  isMulti,
  className,
  classNamePrefix,
  value,
  onChange,
  hasResultsProperty = true,
  debounce = 300,
  defaultOptions = false,
  defaultAction,
  isPortal = false,
  id,
  limit = Infinity,
  comps = {},
  styles = {},
  isDisabled,
  isClearable,
  filterOption,
  placeholder,
  pageParam = 'page',
  pageSizeParam = 'page_size',
  searchTermParam = 'search_term',
  hasMoreProperty = 'more',
  resultsProperty = 'results',
  pageStart = 1,
  mandatoryQueryParams = {},
  withNoneOption,
  ariaLabel,
  noOptionsMessage,
  closeMenuOnSelect,
  maxMenuHeight,
  onMenuClose,
  onMenuOpen,
  menuIsOpen,
  forwardRef,
  onMouseDown,
  options,
  isAsync = true,
  defaultMenuIsOpen = false
}) => {
  const [syncListPage, setSyncListPage] = useState(1)
  const [syncSearchBy, setSyncSearchBy] = useState('')

  const dispatch = useDispatch()

  const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < limit ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Max limit achieved</div>
        )}
      </components.Menu>
    )
  }

  const MultiValueLabel = props => (
    <components.MultiValueLabel {...props}>
      <Ellipsis width={200}>{props.data.label}</Ellipsis>
    </components.MultiValueLabel>
  )

  const loadOptions = async (search, loadedOptions, { page }) => {
    let response
    try {
      response = await makeGetRequest(url, {
        params: {
          [pageParam]: page,
          [pageSizeParam]: size,
          [searchTermParam]: search,
          ...mandatoryQueryParams
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
      return {
        options: [],
        hasMore: false
      }
    }
    if (defaultOptions && page === pageStart && !search) {
      defaultAction && defaultAction(response)
    }
    const responseObj = hasResultsProperty ? response[resultsProperty] : response

    return {
      options: [
        ...(withNoneOption && page === pageStart
          ? [typeof withNoneOption === 'boolean' ? { value: -1, label: 'None' } : withNoneOption]
          : []),
        ...(serializer ? serializer(responseObj) : responseObj)
      ],
      hasMore: Boolean(response[hasMoreProperty]),
      additional: {
        page: page + 1
      }
    }
  }

  const getSyncOptions = () => {
    const resOptions = syncSearchBy
      ? options.filter(option => option.label.toLowerCase().includes(syncSearchBy.toLowerCase()))
      : options
    return resOptions.length > size ? resOptions.slice(0, syncListPage * size) : resOptions
  }

  const syncSearch = searchBy => {
    setSyncListPage(1)
    setSyncSearchBy(searchBy)
  }

  return isAsync ? (
    <AsyncPaginate
      aria-label={ariaLabel}
      defaultOptions={defaultOptions}
      debounceTimeout={debounce}
      value={value}
      placeholder={placeholder}
      className={className}
      classNamePrefix={classNamePrefix}
      loadOptions={loadOptions}
      isMulti={isMulti}
      onChange={onChange}
      additional={{
        page: pageStart
      }}
      styles={{ ...styles, ...(isPortal && { menuPortal: base => ({ ...base, zIndex: 9999 }) }) }}
      menuPortalTarget={isPortal ? document.body : null}
      id={id}
      components={{ Menu, MultiValueLabel, ...comps }}
      isDisabled={isDisabled}
      isClearable={isClearable}
      filterOption={filterOption}
      noOptionsMessage={noOptionsMessage}
      closeMenuOnSelect={closeMenuOnSelect}
      maxMenuHeight={maxMenuHeight}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      menuIsOpen={menuIsOpen}
      forwardRef={forwardRef}
      onMouseDown={onMouseDown}
      defaultMenuIsOpen={defaultMenuIsOpen}
    />
  ) : (
    <Select
      aria-label={ariaLabel}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      value={value}
      options={getSyncOptions()}
      isClearable={isClearable}
      className={className}
      styles={styles}
      menuPortalTarget={isPortal ? document.body : null}
      onChange={onChange}
      onMenuScrollToBottom={() => setSyncListPage(currentPage => currentPage + 1)}
      onInputChange={syncSearch}
      defaultMenuIsOpen={defaultMenuIsOpen}
      components={{ MultiValueLabel }}
    />
  )
}

export default PaginatedSelect
