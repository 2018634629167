import classNames from 'classnames'
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io'

import { If, DESIGN } from 'simple-core-ui'

import s from './Collapsible.scss'

const {
  color: { gray700 }
} = DESIGN

const Collapsible = ({
  title,
  isCollapsed,
  toggleCollapsed,
  children,
  style,
  titleClassName,
  contentClassName
}) => (
  <section style={style}>
    <section onClick={toggleCollapsed} className={classNames(s.title, titleClassName)}>
      <span className={s.arrow}>
        <If condition={isCollapsed} fallback={<IoIosArrowDown style={{ color: gray700 }} />}>
          <IoIosArrowForward style={{ color: gray700 }} />
        </If>
      </span>
      <span style={{ color: gray700 }}>{title}</span>
    </section>
    <section
      style={{ paddingLeft: 5 }}
      className={classNames(s.content, contentClassName, { [s.collapsed]: isCollapsed })}
    >
      {children}
    </section>
  </section>
)

Collapsible.defaultProps = {
  style: {}
}

export default Collapsible
