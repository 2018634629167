import s from './FlexyHalf.scss'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const FlexyHalf = ({ children }) => <section className={s.container}>{children}</section>

registration.register({
  name: 'FlexyHalf',
  description:
    'A utility component used for layout that will evenly split child components into two half sections with added styling for proper wrapping.',
  props: [
    {
      name: 'children',
      optional: false,
      type: 'React Nodes',
      note: 'The component expects two or less react nodes as children.'
    }
  ],
  example: {
    literal: `
<FlexyHalf>
  <div style={{background: 'mediumseagreen', height: '100px'}} />
  <div style={{background: 'palevioletred', height: '100px'}} />
</FlexyHalf>
    `.trim(),
    render: () => (
      <FlexyHalf>
        <div style={{ background: 'mediumseagreen', height: '100px' }} />
        <div style={{ background: 'palevioletred', height: '100px' }} />
      </FlexyHalf>
    )
  },
  category: CATEGORY.LAYOUT,
  path: 'components/Utils/FlexyHalf/FlexyHalf'
})

export default FlexyHalf
