import cn from 'classnames'
import { useState } from 'react'
import s from './SwitchToggle.scss'
import noop from 'lodash/noop'
import { FaCheck, FaTimes } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { UpdateToPremiumLabel } from 'simple-core-ui'

interface Props {
  className?: string
  children?: string
  noText?: boolean
  large?: boolean
  theme?: 'success'
  onToggle?: (value: boolean) => void
  defaultOn?: boolean
  checked?: boolean
  readOnly?: boolean
  isPremium?: boolean
  dataTestId?: string
  id?: string
  tooltipContent?: string
}

const SwitchToggle = ({
  className,
  children,
  noText,
  large = false,
  theme = 'success',
  onToggle,
  defaultOn = false,
  checked,
  readOnly,
  dataTestId,
  id = children?.replace(/\s/g, ''),
  tooltipContent,
  isPremium
}: Props) => {
  const [isOn, setIsOn] = useState(checked ?? defaultOn)

  if (checked !== undefined && checked !== isOn) {
    setIsOn(checked)
  }

  const value = checked === undefined ? { defaultChecked: defaultOn } : { checked }

  return (
    <>
      {/* @ts-expect-error */}
      <div className={className} key={checked}>
        <div
          aria-label={children}
          className={cn(s.switch, {
            [s.large]: large,
            [s.noText]: noText,
            [s.success]: theme === 'success',
            [s.disabled]: !onToggle
          })}
          data-for={`upgradeTier-${id}`}
          data-tip
        >
          <label
            className={cn(s.label, {
              [s.readOnly]: readOnly
            })}
            htmlFor={id}
          >
            <input
              role="switch"
              type="checkbox"
              className={s.input}
              data-testid={dataTestId}
              id={id}
              onChange={value => {
                if (readOnly) return
                if (checked === undefined) setIsOn(value.target.checked)
                onToggle ? onToggle(value.target.checked) : noop()
              }}
              {...value}
            />
            <span
              className={cn(s.text, {
                [s.readOnly]: readOnly
              })}
            >
              {isOn ? (
                <FaCheck className={cn(s.icon, s.on)} />
              ) : (
                <FaTimes className={cn(s.icon, s.off)} />
              )}
            </span>
            <span className={s.handle}></span>
          </label>
        </div>
        {children && <span className={s.children}>{children}</span>}
        {isPremium === false && (
          <UpdateToPremiumLabel style={{ marginRight: 10, position: 'relative', bottom: 9 }} />
        )}
      </div>
      {tooltipContent && !onToggle && (
        <ReactTooltip id={`upgradeTier-${id}`} type="light" effect="solid" place="top" border>
          {tooltipContent}
        </ReactTooltip>
      )}
    </>
  )
}

export default SwitchToggle
