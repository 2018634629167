const BuildingIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3999 16.6V4.9C18.3999 4.375 18.0589 3.90976 17.5573 3.75391L7.95733 0.753907C7.93038 0.753907 7.90343 0.753906 7.87647 0.738672C7.8296 0.726953 7.78272 0.717578 7.73467 0.711719C7.64327 0.703516 7.55069 0.703516 7.45928 0.711719C7.41241 0.71875 7.36671 0.726953 7.321 0.738672C7.29405 0.738672 7.26709 0.738672 7.24014 0.753907L2.44014 2.25391C1.93974 2.41094 1.5999 2.875 1.5999 3.4V16.6C0.936632 16.6 0.399902 17.1367 0.399902 17.8C0.399902 18.4633 0.936632 19 1.5999 19H18.3999C19.0632 19 19.5999 18.4633 19.5999 17.8C19.5999 17.1367 19.0632 16.6 18.3999 16.6ZM3.99991 9.4363L6.39991 8.8363V11.4039L3.99991 11.8539V9.4363ZM8.79991 11.3441V8.79997L11.1999 9.34255V11.6558L8.79991 11.3441ZM13.5999 9.88279L15.9999 10.4254V12.2734L13.5999 11.9734V9.88279ZM15.9999 7.96561L13.5999 7.42303V5.02303L15.9999 5.77303V7.96561ZM11.1999 6.88279L8.79991 6.34021V3.53239L11.1999 4.28239V6.88279ZM6.39991 3.53239V6.36481L3.99991 6.96481V4.28239L6.39991 3.53239ZM3.99991 16.6001V14.2962L6.39991 13.8462V16.6001H3.99991ZM8.79991 16.6001V13.7653L11.1999 14.0653V16.6001H8.79991ZM13.5999 16.6001V14.3829L15.9999 14.6829V16.6001H13.5999Z"
        fill="#3C98FD"
      />
    </svg>
  )
}

export default BuildingIcon
