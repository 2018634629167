import * as React from 'react'
import get from 'lodash/get'

import { CopiableContainer, If, Markdown } from 'simple-core-ui'
import GitLab from '../GitLab'
import { linkToComponent } from '../helpers'

import s from './Doc.scss'

const Doc = ({
  name,
  description,
  props,
  example,
  category,
  markdown,
  path,
  propsAsArgs,
  theme
}) => (
  <section className={s.container}>
    <section className={s.header}>
      <h1 className={s.title}>
        <If condition={path}>
          <span className={s.gitLab}>
            <GitLab size={25} onClick={() => linkToComponent(path)} />
          </span>
        </If>
        {name}
      </h1>
      <p className={s.description}>{description}</p>
    </section>
    <If condition={example}>
      {() => (
        <React.Fragment>
          <section className={s.example}>{example.render(theme)}</section>
          <If condition={example.literal}>
            {() => (
              <section className={s.code}>
                <h4 className={s.subHeading}>Code:</h4>
                <pre>
                  <CopiableContainer
                    textToCopy={get(example, 'literal', '').trim()}
                    hoverActionProps={{ className: s.copyAction, tip: '' }}
                  />
                  {get(example, 'literal', '').trim()}
                </pre>
              </section>
            )}
          </If>
          <If condition={props && props.length}>
            {() => (
              <section className={s.props}>
                <h4 className={s.subHeading}>Props:</h4>
                <table className={s.table}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th className={s.centered}>Required</th>
                      <th className={s.centered}>Default Value</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.map(({ name, optional, defaultValue, type, note }, idx) => (
                      <tr key={idx}>
                        <td>{`${name}${!optional ? '*' : ''}`}</td>
                        <td>{type}</td>
                        <td className={s.centered}>{optional ? '' : '✔'}</td>
                        <td className={s.centered}>{defaultValue}</td>
                        <td>{note}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            )}
          </If>
        </React.Fragment>
      )}
    </If>
    <If condition={markdown}>
      {() => (
        <section className={s.markdownContainer}>
          <Markdown value={markdown} className={s.markdown} />
        </section>
      )}
    </If>
  </section>
)

Doc.defaultProps = {
  category: ''
}

export default Doc
