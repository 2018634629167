import { useEffect, useState, useRef } from 'react'

const useLoading = (
  loadingLockId = 'react-app'
): [boolean, <T>(aPromise: Promise<T>) => Promise<T>, () => void] => {
  const [isLoading, setState] = useState(false)
  const mount = useRef(false)

  useEffect(() => {
    mount.current = true
    return () => {
      mount.current = false
    }
  }, [])

  useEffect(() => {
    const element = document.getElementById(loadingLockId)
    if (element && loadingLockId !== 'disable-lock') {
      if (isLoading) {
        document.body.style.cursor = 'wait'
        if (element.style) {
          element.style.pointerEvents = 'none'
        }
      } else {
        document.body.style.cursor = 'default'
        if (element.style) {
          element.style.pointerEvents = 'auto'
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const load = (aPromise: Promise<any>) => {
    setState(true)
    return aPromise.finally(() => {
      if (mount.current) {
        setState(false)
      }
    })
  }
  const resetLoadingLocks = () => {
    document.body.style.cursor = 'default'
    const element = document.getElementById(loadingLockId)
    if (element) {
      element.style.pointerEvents = 'auto'
    }
  }

  return [isLoading, load, resetLoadingLocks]
}

export default useLoading
