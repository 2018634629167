import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

const { borderRadius, color, spacing } = DESIGN

const descriptions = {
  bdR100: {
    text: 'For smaller / thinner elements like progress bars that need a slight bevel.',
    render: value => (
      <section
        style={{
          borderRadius: value,
          background: color.green600,
          height: spacing.sp300,
          width: spacing.sp1200
        }}
      />
    )
  },
  bdR200: {
    text: 'Most commonly used value. Found on buttons, inputs, panels, and modals.',
    render: value => (
      <section
        style={{
          border: `1px solid ${color.gray400}`,
          borderRadius: value,
          width: spacing.sp1100,
          height: spacing.sp600,
          background: color.gray100
        }}
      />
    )
  },
  bdR300: {
    text:
      'For cases where you need to render a circle. Be sure width and height are identical in value.',
    render: value => (
      <section
        style={{
          borderRadius: value,
          width: spacing.sp700,
          height: spacing.sp700,
          background: color.gray600
        }}
      />
    )
  }
}

const sass = `
.button {
  border-radius: $bdR200;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { borderRadius: { bdR200 } } = DESIGN

const Button = () => <button style={{ borderRadius: bdR200 }}>Submit</button>
`

const html = `<button class='bdR200'>Submit</button>`

export default registration.register({
  name: 'Border Radius',
  description: 'SimpleLegal Design System Border Radius',
  example: {
    render: () => (
      <DescriptionList pairs={getDesignValues(borderRadius)} descriptions={descriptions} />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
