import s from './ErrorPage.scss'
import { IoIosAlert } from 'react-icons/io'

const Page403 = ({ flag }) => (
  <section className={s.sectionContainer}>
    <div className={s.icon}>
      <IoIosAlert />
    </div>
    <h1>{`You do not have access to this ${flag.replace(/_/g, ' ').toLowerCase()}.`}</h1>
    <h2 className={s.contact}>{`Contact ${
      window.credentials.userInfo
        ? window.credentials.userInfo.client
        : window.credentials.user.clientName
    } to be granted access.`}</h2>
  </section>
)

export default Page403
