import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'

const sass = `
.header {
  font-size: $ftSz500;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { fontSize: { ftSz500 } } = DESIGN

const Header = () => <h1 style={{fontSize: ftSz500}}>...</h1>
`

const html = '<h1 class="ftSz500">...</h1>'

export default registration.register({
  name: 'Font Size',
  description: 'SimpleLegal Design System Font Sizes',
  example: {
    render: () =>
      getDesignValues(DESIGN.fontSize).map(([name, value], idx) => (
        <span
          key={idx}
          style={{
            display: 'block',
            fontSize: value,
            marginBottom: DESIGN.spacing.sp500,
            lineHeight: DESIGN.lineHeight.lnHt100
          }}
        >
          <strong>{name}</strong> ({value})
        </span>
      ))
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
