import { useEffect, useRef, memo } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children: JSX.Element | null
  mountElement?: Element
}

const Portal = ({ children, mountElement }: Props) => {
  const el = useRef<Element | null>(null)
  if (!el.current) el.current = document.createElement('div')

  const mount = mountElement ?? document.getElementById('portal-root')

  useEffect(() => {
    const { current } = el

    mount && current && mount.appendChild(current)
    return () => {
      mount && current && mount.removeChild(current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mountElement])

  return mount ? createPortal(children, el.current) : children
}

export default memo(Portal)
