import * as React from 'react'
import { IoIosCopy, IoMdCheckmark } from 'react-icons/io'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { HoverAction } from 'simple-core-ui'

import s from './CopiableContainer.scss'

class CopiableContainer extends React.Component {
  state = {
    hasCopied: false
  }

  showCopySuccess = () => {
    this.setState({ hasCopied: true }, () => {
      setTimeout(() => {
        this.setState({ hasCopied: false })
      }, 700)
    })
  }

  render() {
    const { hasCopied } = this.state
    const { textToCopy, hoverActionProps } = this.props

    return (
      <CopyToClipboard text={textToCopy} onCopy={this.showCopySuccess}>
        <HoverAction
          tip={hasCopied ? 'Copied!' : 'Copy to Clipboard'}
          {...hoverActionProps}
          icon={hasCopied ? <IoMdCheckmark className={s.copy} /> : <IoIosCopy />}
        />
      </CopyToClipboard>
    )
  }
}

export default CopiableContainer
