import * as React from 'react'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

interface Props {
  condition: unknown
  children: JSX.Element | (() => JSX.Element | string) | string
  fallback?: JSX.Element | string | null
}

const If = ({ condition, children, fallback = null }: Props) => (
  <>{!condition ? fallback : typeof children === 'function' ? children() : children}</>
)

registration.register({
  name: 'If',
  description:
    'A utility component that aids in simple conditional renderings. If you find yourself using many of these components back to back to achieve an if/else if/else flow checkout the <ConditionalRender /> component.',
  props: [
    {
      name: 'condition',
      type: 'boolean',
      note: 'The main boolean condition. If true it will render the children prop.'
    },
    {
      name: 'children',
      type: 'React.Node | () => React.Node',
      note:
        'The content that will be rendered if the condition prop is true. Optionally you can provide a function here that returns a react node, which is necessary if you want to defer the execution of the children until the condition is true.'
    },
    {
      name: 'fallback',
      optional: true,
      type: 'React.Node',
      note:
        'Default: null - An optional prop that will serve as the content that will render if the condition is false. By default the fallback is null which will result in the component not rendering anything at all.'
    }
  ],
  example: {
    literal: `
<React.Fragment>
  <h3 style={{ textAlign: 'center' }}>
    <If condition={2 + 2 === 4}>Math is cool...</If>
  </h3>
  <h3 style={{ textAlign: 'center' }}>
    <If
      condition={2 + 2 !== 4}
      fallback='Math is irrational'
    >
      Math is cool...
    </If>
  </h3>
</React.Fragment>`.trim(),
    render: () => {
      const Styled = ({ children }: { children: JSX.Element }) => (
        <h3 style={{ textAlign: 'center' }}>{children}</h3>
      )

      const ian = { meta: { name: 'Ian', age: 27 } }
      const alan = { meta: { name: 'Alan', age: 28 } }
      const tony = {}

      return (
        <React.Fragment>
          <Styled>
            <If condition={ian.meta.age < alan.meta.age}>
              {`${ian.meta.name} is younger than ${alan.meta.name}`}
            </If>
          </Styled>
          <Styled>
            <If
              condition={ian.meta.age > alan.meta.age}
              fallback={`${ian.meta.name} is still younger than ${alan.meta.name}`}
            >
              {`${ian.meta.name} is finally older than ${alan.meta.age}`}
            </If>
          </Styled>
          <Styled>
            <If
              // @ts-expect-error
              condition={tony.meta && tony.meta.name && tony.meta.age}
              fallback="Tony does not exist yet..."
            >
              {() =>
                'We can safely access tony.meta.name / tony.meta.age without raising an exception...'
              }
            </If>
          </Styled>
        </React.Fragment>
      )
    }
  },
  category: CATEGORY.UTILS,
  path: 'components/Utils/If/If'
})

export default If
