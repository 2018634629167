import * as React from 'react'

import { Xeditable } from 'simple-core-ui'

class XeditableCoreContainer extends React.Component {
  static defaultProps = {
    placeholder: 'add...',
    position: 'top'
  }

  state = {
    isOpen: false,
    error: null
  }

  openHandler = () => {
    this.setState(() => ({ isOpen: true }))
  }

  closeHandler = () => {
    this.props.cancelCb()
    this.setState(() => ({ isOpen: false, error: null }))
  }

  setValue = value => {
    const { validation } = this.props
    const error = typeof validation === 'function' ? validation(value) : null

    if (error) {
      this.setState({ error })
    } else {
      this.props.setValueCb(value)
      this.setState({ isOpen: false, error: null })
    }
  }

  render() {
    return (
      <Xeditable
        {...this.props}
        {...this.state}
        closeCb={this.closeHandler}
        openCb={this.openHandler}
        setValueCb={this.setValue}
      />
    )
  }
}

export default XeditableCoreContainer
