import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import s from '../Table.scss'

const arrowSize = 24

const TableRowExpandIcon = ({ expanded = false, expandRow, isDisabled }) => (
  <td data-testid="expand-icon" className={s.tableRowExpand}>
    <button
      onClick={e => {
        e.stopPropagation()
        expandRow(!expanded)
      }}
      disabled={isDisabled}
    >
      {expanded ? <IoIosArrowUp size={arrowSize} /> : <IoIosArrowDown size={arrowSize} />}
    </button>
  </td>
)

export default TableRowExpandIcon
