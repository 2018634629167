export const CATEGORY = {
  ANIMATE: 'Animation / Transition',
  FORM: 'Form Elements',
  HALL_OF_FAME: 'Hall of Fame',
  HOOKS: 'Hooks',
  INFO: 'Informational',
  LAYOUT: 'Layout',
  MISC: 'Miscellaneous',
  NAV: 'Navigation / Filtration',
  OVERLAYS: 'Overlays',
  SYSTEM: 'Design System',
  TABLES: 'Tables / Charts',
  TYPE: 'Typography',
  UTILS: 'Utilities'
}

export const MARKDOWN = {
  GETTING_STARTED: 'Getting Started'
}

export const EMOJI_MAP = {
  [CATEGORY.ANIMATE]: '🎞',
  [CATEGORY.FORM]: '📝',
  [CATEGORY.HALL_OF_FAME]: '🏆',
  [CATEGORY.HOOKS]: '🎣',
  [CATEGORY.INFO]: 'ℹ️',
  [CATEGORY.LAYOUT]: '📐',
  [CATEGORY.MISC]: '🤔',
  [CATEGORY.NAV]: '🗺',
  [CATEGORY.OVERLAYS]: '🖥',
  [CATEGORY.SYSTEM]: '⚖️',
  [CATEGORY.TABLES]: '📊',
  [CATEGORY.TYPE]: '✒️',
  [CATEGORY.UTILS]: '🛠',
  [MARKDOWN.GETTING_STARTED]: '💁'
}

export const GITLAB_URL = 'https://gitlab.com/SimpleLegal/SimpleLegal/blob/master'
