/* eslint-disable no-console */
/* eslint-disable no-undef */

import { Component } from 'react'
import Select from 'react-select'
import get from 'lodash/get'

import { HoverField, Input, KeyValuePairs, Condense } from 'simple-core-ui'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

type Props = {
  isEditOpen?: boolean
  displayValue?: string | JSX.Element
  renderField?(e: unknown): JSX.Element
  onOpenEdit?(): void
  onCloseEdit?(): void
  readOnly?: boolean
  isEmpty?: boolean
  emptyValue?: string
  displayAsInput?: boolean
  alwaysShowPencil?: boolean
}

type State = {
  name?: string
  isEditOpen: boolean
}

class HoverFieldContainer extends Component<Props, State> {
  state = {
    isEditOpen: false
  }

  openEdit = () => {
    this.setState({ isEditOpen: true })

    if (typeof this.props.onOpenEdit === 'function') {
      this.props.onOpenEdit()
    }
  }

  closeEdit = () => {
    this.setState({ isEditOpen: false })

    if (typeof this.props.onCloseEdit === 'function') {
      this.props.onCloseEdit()
    }
  }
  render() {
    return (
      <HoverField
        {...this.props}
        {...this.state}
        openEdit={this.openEdit}
        closeEdit={this.closeEdit}
      />
    )
  }
}

registration.register({
  name: 'HoverFieldContainer',
  description:
    'A component that serves as a wrapper around an Input/Select/Form field and provides a nice way to toggle between read and edit states.',
  props: [
    {
      name: 'displayValue',
      type: 'React.Node',
      defaultValue: "''",
      note:
        'This prop should represent the values set in the editable field in the form of a component or string (renderable value). For example, if my input had users selected this would render the names of the users comma separated in string format.'
    },
    {
      name: 'renderField',
      type: '(SaveCb) => React.Node',
      note:
        'This is a render prop that should return the form field component. The render prop is provided the SaveCb which can be used to set this component into the read state. (Note: Manually using the SaveCb is not necessary in that it will be called on Outside click of this component.)'
    },
    {
      name: 'onOpenEdit',
      optional: true,
      type: '() => void',
      note:
        'If provided, this function will be invoked when the HoverField toggles into its edit state.'
    },
    {
      name: 'onCloseEdit',
      optional: true,
      type: '() => void',
      note:
        'If provided, this function will be invoked when the HoverField toggles out of its edit state.'
    },
    {
      name: 'isEmpty',
      optional: true,
      type: 'boolean',
      note: 'This prop will control whether to show the emptyValue prop (None by default).'
    },
    {
      name: 'saveOnBlur',
      optional: true,
      defaultValue: 'true',
      type: 'boolean',
      note:
        'This boolean prop can be set to false to prevent the SaveCb from being invoked on outside click/blur of the rendered field.'
    },
    {
      name: 'readOnly',
      optional: true,
      defaultValue: 'false',
      type: 'boolean',
      note:
        'This boolean prop can be set to prevent the editable form field from rendering, only rendering the displayValue accordingly.'
    },
    {
      name: 'alwaysShowPencil',
      optional: true,
      defaultValue: 'false',
      type: 'boolean',
      note:
        'This boolean prop can be set to always have the edit icon (pencil) show next to the displayValue rather than only on hover.'
    },
    {
      name: 'emptyValue',
      optional: true,
      defaultValue: 'None',
      type: 'string',
      note:
        'This is the empty placeholder text that will show when no value is set for displayValue.'
    },
    {
      name: 'displayAsInput',
      optional: true,
      defaultValue: 'false',
      type: 'boolean',
      note:
        "This simple boolean prop will allow the HoverField's displayValue form render as though it was an input (with border and placeholder coloring). This can be useful to set when HoverFieldContainer is used alongside standard form input elements. When this prop is set to true it will not render the pencil icon because the field will already look editable."
    }
  ],
  example: {
    literal: 'Refer to HoverFieldContainer registration doc for code...',
    render: () => {
      const PROG_LANG = {
        PY: { value: 'py', label: 'Python' },
        JS: { value: 'js', label: 'Javascript' },
        C: { value: 'c', label: 'C' },
        ELM: { value: 'elm', label: 'Elm' },
        RB: { value: 'rb', label: 'Ruby' },
        SQL: { value: 'sql', label: 'SQL' },
        RS: { value: 'rs', label: 'Rust' },
        HS: { value: 'hs', label: 'Haskell' }
      }

      const COUNTRY = {
        US: { value: 'us', label: 'United States' },
        MX: { value: 'mx', label: 'Mexico' },
        CH: { value: 'ch', label: 'China' },
        JP: { value: 'jp', label: 'Japan' },
        RM: { value: 'rm', label: 'Romania' }
      }

      const onOpenEdit = () => console.log(`Toggled into edit mode...`)

      const onCloseEdit = () => console.log(`Toggled out of edit mode...`)

      class ExampleForm extends Component {
        state = {
          name: 'Jabour v. Kentucky',
          country: COUNTRY.US,
          languages: [PROG_LANG.JS, PROG_LANG.PY, PROG_LANG.ELM, PROG_LANG.HS],
          notes: 'Sample notes here...',
          asanaId: 'A-3004',
          zipCode: '92867'
        }

        updateForm = (fields: { [key: string]: unknown }) => {
          console.log(fields)
          this.setState(fields)
        }

        render() {
          return (
            <KeyValuePairs
              style={{ padding: '1em', background: 'white', maxWidth: '600px' }}
              pairs={[
                {
                  key: 'Matter Name',
                  value: (
                    <HoverFieldContainer
                      displayValue={this.state.name}
                      onOpenEdit={onOpenEdit}
                      onCloseEdit={onCloseEdit}
                      renderField={() => (
                        <Input
                          placeholder="Jabour v. Canada"
                          text={this.state.name}
                          onChangeCb={({ currentTarget }: { currentTarget: { value: string } }) => {
                            this.updateForm({ name: currentTarget.value })
                          }}
                        />
                      )}
                      isEmpty={!this.state.name}
                    />
                  )
                },
                {
                  key: 'Client Matter ID (read only)',
                  value: <HoverFieldContainer displayValue="IJ-v-CD" readOnly />
                },
                {
                  key: 'Country',
                  value: (
                    <HoverFieldContainer
                      displayValue={get(this.state.country, 'label', '')}
                      onOpenEdit={onOpenEdit}
                      onCloseEdit={onCloseEdit}
                      renderField={() => (
                        <Select
                          name="example-select-country"
                          placeholder="Select a country..."
                          //@ts-expect-error
                          noOptionsMessage="No country found..."
                          value={this.state.country}
                          onChange={country => this.updateForm({ country })}
                          options={[COUNTRY.US, COUNTRY.MX, COUNTRY.CH, COUNTRY.JP, COUNTRY.RM]}
                        />
                      )}
                      isEmpty={!this.state.country}
                    />
                  )
                },
                {
                  key: 'Languages',
                  value: (
                    <HoverFieldContainer
                      onOpenEdit={onOpenEdit}
                      onCloseEdit={onCloseEdit}
                      displayValue={
                        <Condense
                          values={this.state.languages.map(({ label }) => label)}
                          badgeProps={{
                            size: 'sm',
                            asPill: true
                          }}
                        />
                      }
                      renderField={() => (
                        <Select
                          name="example-select-languages"
                          placeholder="Select programming languages..."
                          //@ts-expect-error
                          noOptionsMessage="No language found..."
                          value={this.state.languages}
                          onChange={languages => this.updateForm({ languages })}
                          options={[
                            PROG_LANG.PY,
                            PROG_LANG.JS,
                            PROG_LANG.C,
                            PROG_LANG.ELM,
                            PROG_LANG.RB,
                            PROG_LANG.SQL,
                            PROG_LANG.RS,
                            PROG_LANG.HS
                          ]}
                          isClearable={false}
                          isMulti
                        />
                      )}
                      isEmpty={!this.state.languages.length}
                    />
                  )
                },
                {
                  key: 'Notes',
                  value: (
                    <HoverFieldContainer
                      displayValue={this.state.notes}
                      onOpenEdit={onOpenEdit}
                      onCloseEdit={onCloseEdit}
                      renderField={() => (
                        <textarea
                          placeholder="Enter notes here..."
                          value={this.state.notes}
                          onChange={({ currentTarget }: { currentTarget: { value: string } }) => {
                            this.updateForm({ notes: currentTarget.value })
                          }}
                          style={{
                            minHeight: '150px',
                            width: '100%',
                            boxSizing: 'border-box'
                          }}
                        />
                      )}
                      isEmpty={!this.state.notes}
                    />
                  )
                },
                {
                  key: 'Asana ID (always pencil)',
                  value: (
                    <HoverFieldContainer
                      displayValue={this.state.asanaId}
                      onOpenEdit={onOpenEdit}
                      onCloseEdit={onCloseEdit}
                      renderField={() => (
                        <Input
                          placeholder="A-1002"
                          text={this.state.asanaId}
                          onChangeCb={({ currentTarget }: { currentTarget: { value: string } }) => {
                            this.updateForm({ asanaId: currentTarget.value })
                          }}
                        />
                      )}
                      isEmpty={!this.state.asanaId}
                      alwaysShowPencil
                    />
                  )
                },
                {
                  key: 'Zip code (displayAsInput)',
                  value: (
                    <HoverFieldContainer
                      displayValue={this.state.zipCode}
                      onOpenEdit={onOpenEdit}
                      onCloseEdit={onCloseEdit}
                      renderField={() => (
                        <Input
                          placeholder="94063"
                          text={this.state.zipCode}
                          onChangeCb={({ currentTarget }: { currentTarget: { value: string } }) => {
                            this.updateForm({ zipCode: currentTarget.value })
                          }}
                        />
                      )}
                      isEmpty={!this.state.zipCode}
                      displayAsInput
                    />
                  )
                }
              ]}
            />
          )
        }
      }

      return <ExampleForm />
    }
  },
  category: CATEGORY.FORM,
  path: 'containers/Core/HoverFieldContainer/HoverFieldContainer'
})

export default HoverFieldContainer
