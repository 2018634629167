import * as React from 'react'
import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import * as Sentry from '@sentry/browser'

class ErrorBoundaryContainer extends React.Component {
  state = { isError: false }

  componentDidCatch(error, info) {
    this.setState({ isError: true })

    // Log to sentry (only production environments)
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key])
      })
      Sentry.captureException(error)
    })
  }

  errorMessage = () => (
    <section style={{ textAlign: 'center', padding: '1em', fontWeight: 'lighter' }}>
      <p>An error has caused this component to stop working.</p>
      <p>Please contact support. We will resolve this issue as soon as possible.</p>
    </section>
  )

  render() {
    return this.state.isError
      ? this.errorMessage()
      : this.props.children(this.state.isError, this.errorMessage())
  }
}

registration.register({
  name: 'ErrorBoundaryContainer',
  description: 'A utility that will catch any propagated errors.',
  props: [
    {
      name: 'children',
      type: '(boolean, React.Node) => React.Node',
      note:
        'Should be a callback that provided the error state and the standard error message, returns a React node.'
    },
    {
      name: 'isError',
      optional: true,
      type: 'boolean',
      note: 'An optional boolean to set the error state from outside the boundary.'
    }
  ],
  example: {
    literal: `
const Broken = () => { throw {message: 'Broken code here...'} }

const Fallback = ({message}) => (
  <h4 style={{textAlign: 'center', color: 'indianred', fontWeight: 'lighter'}}>
    {message}
  </h4>
)

return (
  <ErrorBoundaryContainer>
    {(isError, message) => (
      isError ? <Fallback message={message} /> : <Broken />
    )}
  </ErrorBoundaryContainer>
)`.trim(),
    render: () => {
      const Broken = () => {
        throw { message: 'Broken code here...' }
      } // eslint-disable-line

      const Fallback = ({ message }) => (
        <h4 style={{ textAlign: 'center', color: 'indianred', fontWeight: 'lighter' }}>
          {message}
        </h4>
      )

      return (
        <ErrorBoundaryContainer>
          {(isError, message) => (isError ? <Fallback message={message} /> : <Broken />)}
        </ErrorBoundaryContainer>
      )
    }
  },
  category: CATEGORY.UTILS,
  path: 'containers/Utils/ErrorBoundaryContainer/ErrorBoundaryContainer'
})

export default ErrorBoundaryContainer
