import { CSSProperties } from 'react'
import classNames from 'classnames'
import noop from 'lodash/noop'

import DropdownHeader from './DropdownHeader'
import DropdownList from './DropdownList'
import s from './Dropdown.scss'

type Value = string | number
type Option = { value: Value; label: string }
type Props = {
  dataSet: Array<Option>
  isExpanded?: boolean
  selectedOption?: Option
  updateOption(value: Value): void
  expandDropdown(): void
  disabled?: boolean
  styles?: CSSProperties
  className?: string
}

const Dropdown = ({
  dataSet,
  isExpanded,
  selectedOption,
  updateOption,
  expandDropdown,
  disabled,
  styles = {},
  className = ''
}: Props) => (
  <section
    className={classNames(s.container, className, {
      [s.expanded]: !disabled && isExpanded,
      [s.disabled]: disabled
    })}
    style={{ ...styles }}
  >
    <DropdownHeader
      expandDropdown={disabled ? noop : expandDropdown}
      label={selectedOption?.label}
    />
    {isExpanded && !disabled && (
      <DropdownList selectedOption={selectedOption} dataSet={dataSet} updateOption={updateOption} />
    )}
  </section>
)

export default Dropdown
