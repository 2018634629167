import { CATEGORY } from 'simple-core-ui/docs/constants'
import registration from 'simple-core-ui/docs/registration'
import * as React from 'react'

import { Badge, KeyValuePairs, If } from 'simple-core-ui'

import s from './Condense.scss'

type Size = 'sm' | 'md' | 'lg'

interface Props {
  values: string[]
  threshold: number
  badgeProps?: Record<string, any>
  badgeAsPill?: boolean
  onBadgeClick?: () => void
  badgeSize?: Size
  badgeClassName?: string
  withoutSuffix?: boolean
  hideTitle?: boolean
}

const Condense = ({
  values,
  threshold,
  badgeProps,
  badgeAsPill,
  onBadgeClick,
  badgeSize,
  badgeClassName = '',
  withoutSuffix = false,
  hideTitle = false
}: Props) => {
  const remaining = values.slice(threshold)

  return (
    <React.Fragment>
      <span className={s.values}>{values.slice(0, threshold).join(', ')}</span>
      <If condition={remaining.length}>
        <Badge
          {...badgeProps}
          content={`+${remaining.length} ${withoutSuffix ? '' : 'more'}`}
          title={!hideTitle ? remaining.join(', ') : ''}
          asPill={badgeAsPill}
          onBadgeClick={onBadgeClick}
          size={badgeSize}
          className={badgeClassName}
        />
      </If>
    </React.Fragment>
  )
}

Condense.defaultProps = {
  threshold: 3,
  badgeProps: {}
}

registration.register({
  name: 'Condense',
  description:
    'A utility component that will render a condensed version of a list of string values.',
  props: [
    { name: 'values', type: 'Array<string>', note: 'The list of string values to condense down.' },
    {
      name: 'threshold',
      type: 'number',
      optional: true,
      defaultValue: '3',
      note:
        'The threshold at which to cutoff the list of users and render the Badge component (i.e 2 -> Ian, Eryn  [+5 more]).'
    },
    {
      name: 'badgeProps',
      type: 'Prop types for Badge',
      optional: true,
      defaultValue: '{}',
      note:
        'Optionally able to add custom props for the Badge component used. You do not need to provide the content prop for the Badge as this will be calculated by this component.'
    }
  ],
  example: {
    literal: ``.trim(),
    render: () => {
      const PEOPLE = [
        'Paula Dan',
        'Andrei Stanciu',
        'Ian Jabour',
        'Eryn Dickison',
        'Sylvia Yu',
        'Darren Dahl',
        'Cameron Cairns'
      ]

      return (
        <KeyValuePairs
          pairs={[
            {
              key: 'Threshold 1',
              value: <Condense values={PEOPLE} threshold={1} />
            },
            {
              key: 'Threshold 3 (default)',
              value: <Condense values={PEOPLE} threshold={3} />
            },
            {
              key: 'Threshold 5',
              value: <Condense values={PEOPLE} threshold={5} />
            },
            {
              key: 'Threshold 7 (no condensing)',
              value: <Condense values={PEOPLE} threshold={7} />
            }
          ]}
        />
      )
    }
  },
  category: CATEGORY.UTILS,
  path: 'components/Utils/Condense/Condense'
})

export default Condense
