import get from 'lodash/get'
import { IoIosAttach } from 'react-icons/io'
import { FaTrash, FaArchive, FaSync, FaPencilAlt, FaDownload } from 'react-icons/fa'
import s from '../Table.scss'
import { HoverAction, Action } from 'simple-core-ui'
import cn from 'classnames'

const TableRowActions = ({
  row,
  deleteRow,
  editRow,
  downloadRow,
  customAction,
  actions,
  pinActions,
  alwaysShowActions,
  disableActionsEventPropagation,
  customizableColumns
}) => {
  return (
    <td
      className={cn(s.attachments, {
        [s.sticky]: pinActions
      })}
      onClick={e => disableActionsEventPropagation && e.stopPropagation()}
      style={customizableColumns && pinActions ? { right: '51px' } : null}
    >
      {get(row, 'attachments.length', 0) ? (
        <span
          className={s.paperclip}
          title={row.attachments.map(({ name }) => name || '').join(', ')}
        >
          <IoIosAttach />
        </span>
      ) : null}
      <span
        className={cn(s.iconWrapper, {
          [s.visible]: alwaysShowActions
        })}
      >
        <>
          {actions.length > 0 ? (
            actions.map((action, i) => <Action row={row} key={i} action={action} />)
          ) : row.actions ? (
            <>
              {row.actions.useTrashcan && (
                <HoverAction
                  tip="Delete"
                  onClick={e => {
                    e.stopPropagation()
                    deleteRow(row)
                  }}
                  icon={<FaTrash className={s.tableIcon} />}
                  size="xsmall"
                  className={s.hoverAction}
                />
              )}
              {row.actions.useEdit && (
                <HoverAction
                  tip="Edit"
                  onClick={e => {
                    e.stopPropagation()
                    editRow(row)
                  }}
                  icon={<FaPencilAlt className={s.tableIcon} />}
                  size="xsmall"
                  className={s.hoverAction}
                />
              )}
              {row.actions.useRefresh && (
                <FaSync
                  onClick={e => {
                    e.stopPropagation()
                    deleteRow(row)
                  }}
                />
              )}
              {row.actions.useArchive && (
                <FaArchive
                  onClick={e => {
                    e.stopPropagation()
                    deleteRow(row)
                  }}
                />
              )}
              {row.actions.useDownload && (
                <HoverAction
                  tip="Download"
                  onClick={e => {
                    e.stopPropagation()
                    !row.actions.useDownload.isDisabled && downloadRow(row)
                  }}
                  icon={<FaDownload className={s.tableIcon} />}
                  size="xsmall"
                  className={s.hoverAction}
                  isDisabled={row.actions.useDownload.isDisabled}
                />
              )}
            </>
          ) : null}
          {customAction && customAction(row)}
        </>
      </span>
    </td>
  )
}

TableRowActions.defaultProps = {
  row: {
    attachments: []
  },
  deleteRow: () => {},
  actions: []
}

export default TableRowActions
