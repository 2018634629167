import registration from 'docs/registration'
import { MARKDOWN } from 'docs/constants'

const markdown = `
# Base Dependencies

Before we can get up and running we need to gather all the necessary tools on
our host machines.

The following dependencies are needed in order to properly build the frontend code:

- [Node](https://nodejs.org/en/): Node is needed to run the build process and install javascript dependencies.
- [Yarn](https://yarnpkg.com/lang/en/docs/install/): Yarn the tool responsible for tracking our dependencies and orchestrating the processes that need to take place.

> **Note**: It is highly recommended to install [NVM](https://github.com/creationix/nvm) if possible over the standard Node install. NVM will provide the ability to easily switch between Node versions.

---

# Yarn

Once we have the global dependencies available we can start installing our javascript dependencies and bundling our static assets.

From the static folder (\`SimpleLegal/static\`) you can run the following commands:

1. \`yarn\` - This will install all the dependencies listed in the \`package.json\` file, generating/updating the \`yarn.lock\` file if necessary.
2. \`yarn:start\` - This command will run a \`webpack\` build process to bundle the javascript and css across \`ebill\`, \`vendorportal\`, and \`simple-core-ui\` (shared components). This process will finish with information about the build and remain in a watching state, ready swift rebuilding when subsequent changes are made.

🦄 You are now all set and ready to develop! 🦄
`.trim()

registration.register({
  name: 'Installation',
  description:
    'This doc will provide information to get you up and running with the Frontend Workflow in the SimpleLegal App.',
  category: MARKDOWN.GETTING_STARTED,
  props: [],
  markdown
})

export default markdown
