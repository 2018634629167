import tinyColor from 'tinycolor2'
import has from 'lodash/has'

import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

import s from './system_doc.scss'

const { color, spacing } = DESIGN

const fontColor = backgroundColor =>
  tinyColor(backgroundColor).isDark() ? color.gray100 : color.gray800

const ColorGrid = ({ colors }) => (
  <section className={s.colorSwatches}>
    {colors.map(([name, value], idx) => (
      <section
        className={s.colorSwatch}
        style={{ background: value, color: fontColor(value) }}
        key={idx}
      >
        <strong>{name}</strong>
        <span>{value}</span>
      </section>
    ))}
  </section>
)

const colorGroups = Object.entries(
  getDesignValues(color).reduce((acc, [name, value]) => {
    const colorGroup = name.replace(/\d+/g, '')

    if (has(acc, colorGroup)) {
      acc[colorGroup].push([name, value])
    } else {
      acc[colorGroup] = [[name, value]]
    }

    return acc
  }, {})
)

const descriptions = {
  blue: {
    text:
      'Blue color range. Should be blue source when stylizing with color, when a gray is not being used.',
    render: colors => <ColorGrid colors={colors} />
  },
  green: {
    text:
      'An accent color used to show successful states, progress, and blue call-to-action buttons.',
    render: colors => <ColorGrid colors={colors} />
  },
  gray: {
    text:
      'Range from black (not pure black) to white with shades between. These will be the building blocks of color in the app as they will make up most of the UI.',
    render: colors => <ColorGrid colors={colors} />
  },
  red: {
    text:
      'An accent color used for scenarios where we are warning of an irreversible action or of some sort of error.',
    render: colors => <ColorGrid colors={colors} />
  },
  orange: {
    text:
      'An accent color used for scenarios where we are warning about something that the user should be aware of but not absolutely critical of their attention.',
    render: colors => <ColorGrid colors={colors} />
  },
  yellow: {
    text:
      'A highlight color used if we want to highlight a word or block of words. Use lighter for the block of words, use darker for one word.',
    render: colors => <ColorGrid colors={colors} />
  },
  white: {
    text: 'Shortcut for white.',
    render: colors => <ColorGrid colors={colors} />
  },
  black: {
    text: 'Shortcut for black.',
    render: colors => <ColorGrid colors={colors} />
  }
}

const sass = `
.swatch {
  background-color: $blue600;
  color: $blue100;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { color: { blue600, blue100 } } = DESIGN

const Swatch = () => <span style={{color: blue100, backgroundColor: blue600}} />
`

const html = `
<span class='fg-blue100 bg-blue600'></span>

<!--
.bg-blue100  ==> background-color to specified color
.fg-blue100  ==> font color to the specified color
.bd-blue100  ==> border-color to the specified color
.o-blue100   ==> outline-color to the specified color
-->
`

export default registration.register({
  name: 'Color',
  description: 'SimpleLegal Design System Colors',
  example: {
    render: () => (
      <DescriptionList
        pairs={colorGroups}
        descriptions={descriptions}
        containerStyle={{ flexFlow: 'row wrap', justifyContent: 'center' }}
        valueStyle={{
          marginLeft: spacing.sp500,
          marginRight: spacing.sp500,
          maxWidth: spacing.sp1300
        }}
        noSubtext
      />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
