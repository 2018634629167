import s from './ErrorPage.scss'

const PageJSError = ({ message }) => (
  <h3 className={s.jsError}>
    {message || (
      <section style={{ textAlign: 'center', padding: '1em', fontWeight: 'lighter' }}>
        <p>An error has caused this page to stop working.</p>
        <p>Please contact support. We will resolve this issue as soon as possible.</p>
      </section>
    )}
  </h3>
)

export default PageJSError
