/* eslint-disable no-console */
import noop from 'lodash/noop'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import { XeditableContainer, XeditableLink, If } from 'simple-core-ui'

import s from './XeditableChain.scss'

const XeditableChain = ({
  createCb,
  updateCb,
  filterCb,
  deleteCb,
  values,
  xeditableConfig,
  newPlaceholder,
  newTitle,
  readOnly
}) => (
  <section className={s.container}>
    {values
      .filter(({ id, value }) => filterCb(value))
      .map(({ id, value }) => (
        <XeditableContainer
          {...xeditableConfig}
          setValueCb={newValue => {
            if (filterCb(newValue)) {
              updateCb({ id, value: newValue })
            } else {
              deleteCb({ id, value: newValue })
            }
          }}
          initialValue={value}
          readOnly={readOnly}
          key={id}
        />
      ))}
    <If condition={!readOnly}>
      <XeditableContainer
        {...xeditableConfig}
        setValueCb={value => (filterCb(value) ? createCb(value) : noop())}
        renderContent={() => <XeditableLink asPlaceholder>{newPlaceholder}</XeditableLink>}
        placeholder={newPlaceholder}
        title={newTitle}
        key={-1}
      />
    </If>
  </section>
)

registration.register({
  name: 'XeditableChain',
  description:
    'This component utilizes the XeditableContainer to render in a number sequential values of the same type with a persistent Xeditable tail to add another item. It can be useful for situations where you want the user to always be able to add another value on the end.',
  props: [
    {
      name: 'createCb',
      type: '(newValue: any) => void',
      note: 'This is the callback that will be invoked when the tail Xeditable value has been set.'
    },
    {
      name: 'filterCb',
      type: '(value: any) => boolean',
      note:
        'This is the callback that will determine if a value in the chain should be filtered out. For example if the fields are empty, one might want to remove that item. This callback will also be used to determine if the createCb is invoked or not.'
    },
    {
      name: 'deleteCb',
      type: '(removedValue: any) => void',
      note:
        'This is the callback that will be invoked when a value is filtered out of the chain. It receives the filtered out value.'
    },
    {
      name: 'values',
      type: 'Array<any>',
      note: 'The collection of values that will be used to create the xeditables in the chain.'
    },
    {
      name: 'xeditableConfig',
      type: 'Object (Reference props for XeditableContainer)',
      note:
        'These are the props that will be used when creating each Xeditable in the chain. Note that the initialValue, setValueCb, and readOnly props in XeditableContainer will be overridden by the values, updateCb, and readOnly prop that you pass into this XeditableChain component. Additionally the tail of the XeditableChain will override the setValueCb, placeholder, title, and readOnly with createCb, newPlaceholder, newTitle, and readOnly respectively.'
    },
    {
      name: 'newPlaceholder',
      type: 'string',
      note: 'The placeholder that will be shown for the tail of the XeditableChain.'
    },
    {
      name: 'newTitle',
      type: 'string',
      note: 'The title that will be shown for the tail of the XeditableChain.'
    },
    {
      name: 'readOnly',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - Will render the chain as readOnly. Not rendering the tail of the chain used to create new items.'
    }
  ],
  example: {
    literal: '',
    render: theme => (
      <XeditableChain
        createCb={newValue => console.log(`Setting new value: ${newValue}`)}
        updateCb={({ id, value }) => console.log(`Update ${id} to: ${value}`)}
        filterCb={value => value.length > 0}
        deleteCb={({ id, value }) =>
          console.log(`${id} should be popped of the chain, value: ${value}.`)
        }
        values={[
          { id: 1, value: 'January' },
          { id: 2, value: 'February' },
          { id: 3, value: 'March' },
          { id: 4, value: 'April' },
          { id: 5, value: 'May' },
          { id: 6, value: 'June' },
          { id: 7, value: 'July' },
          { id: 8, value: 'August' },
          { id: 9, value: 'September' },
          { id: 10, value: 'October' },
          { id: 11, value: 'November' },
          { id: 12, value: 'December' }
        ]}
        xeditableConfig={{
          type: 'text',
          position: 'right',
          setValueCb: noop,
          cancelCb: noop,
          placeholder: '',
          title: 'Update Month',
          theme
        }}
        newPlaceholder="...add a new month"
        newTitle="Create Month"
      />
    )
  },
  category: CATEGORY.FORM,
  path: 'components/Core/XeditableChain/XeditableChain'
})

export default XeditableChain
