import { ErrorBoundaryContainer, If } from 'simple-core-ui'

const withErrorBoundary = WrappedComponent => {
  return props => (
    <ErrorBoundaryContainer>
      {({ isError, fallback }) => (
        <If condition={!isError} fallback={fallback}>
          <WrappedComponent {...props} />
        </If>
      )}
    </ErrorBoundaryContainer>
  )
}

export default withErrorBoundary
