import { useState } from 'react'
import isEqual from 'lodash/isEqual'

const useSyncStateWithProps: typeof useState = (propValue?) => {
  const [value, setValue] = useState(propValue)
  const [prevValue, setPrevValue] = useState(propValue)

  if (!isEqual(prevValue, propValue)) {
    setPrevValue(propValue)
    setValue(propValue)
  }

  return [value, setValue]
}

export default useSyncStateWithProps
