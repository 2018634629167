const Document = params => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g
        fill="none"
        fillRule="evenodd"
        stroke={params.color || '#000'} // TODO: figure out how to add color to svg like react icons
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path
          d="M19.207 4.5a1 1 0 01.293.707V22.5a1 1 0 01-1 1h-17a1 1 0 01-1-1v-21a1 1 0 011-1h13.293a1 1 0 01.707.293L19.207 4.5zM4 16h12M4 19h12M4 13h12M4 10h12M4 7h7M4 7h7M4 10h12M4 13h12M4 16h12M4 19h12"
          transform="translate(2)"
        ></path>
      </g>
    </svg>
  )
}

export default Document
