const allowedExtensions = (isCG = false) => [
  'pdf',
  'jpeg',
  'png',
  'gif',
  'tiff',
  'bmp',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'doc',
  'docx',
  'txt',
  'xml',
  'rtf',
  'odt',
  'ods',
  'tsv',
  'odp',
  'svg',
  'pages',
  'numbers',
  'psd',
  'zip',
  'rar',
  'wav',
  'html',
  'csv',
  ...(!isCG
    ? [
        'lds',
        'dat',
        'edi',
        'bx',
        'led',
        'jpg',
        'msg',
        'ai',
        'aiff',
        'avi',
        'mp3',
        'mp4',
        'wma',
        'eml',
        'mpg',
        'mpeg',
        'aac',
        'm4a',
        'mov',
        'wmv',
        'webp'
      ]
    : [])
]

export default allowedExtensions
