import { If, SimpleContext } from 'simple-core-ui'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import s from './Anchor.scss'

const Anchor = ({ url, children, asNewTab, style }) => {
  const newTabConfig = asNewTab ? { target: '_blank' } : {}

  return (
    <SimpleContext.Consumer>
      {({ theme }) => {
        return (
          <If condition={url} fallback={<span style={style}>{children}</span>}>
            <a
              href={url}
              onClick={event => event.stopPropagation()}
              className={s[theme]}
              style={style}
              {...newTabConfig}
            >
              {children}
            </a>
          </If>
        )
      }}
    </SimpleContext.Consumer>
  )
}

Anchor.defaultProps = {
  style: {}
}

registration.register({
  name: 'Anchor',
  description: 'For basic links in the ui.',
  props: [
    {
      name: 'url',
      optional: true,
      type: 'string',
      note:
        'The url to navigate to. If it is not provided or is falsy, the children prop is rendered as read-only text. This can be useful when wanting to easily toggle to read-only without needing additional render logic.'
    },
    { name: 'children', type: 'React.Node', note: 'The content within the link.' },
    {
      name: 'asNewTab',
      optional: true,
      type: 'boolean',
      note: 'Default: false - If true the link will open in a new tab.'
    },
    { name: 'style', optional: true, type: 'object', note: 'Custom styles for the link.' },
    { name: 'theme', type: 'eb | cg', note: 'The theme for the component.' }
  ],
  example: {
    literal: `
<section style={{ display: 'flex', justifyContent: 'center' }}>
  <Anchor
    url='https://gitlab.com/SimpleLegal/SimpleLegal'
  >
    Go To SimpleLegal GitLab
  </Anchor>
</section>`.trim(),
    render: theme => (
      <section style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Anchor url="https://gitlab.com/SimpleLegal/SimpleLegal">Go To SimpleLegal GitLab</Anchor>
        <Anchor>This Link is Read-Only</Anchor>
      </section>
    )
  },
  category: CATEGORY.NAV,
  path: 'components/Core/Anchor/Anchor'
})

export default Anchor
