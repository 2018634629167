import { useState } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  UseFloatingOptions,
  ReferenceType
} from '@floating-ui/react'

interface Args extends Partial<UseFloatingOptions<ReferenceType>> {
  handleState?: boolean
}

const useWithPopper = ({ handleState, ...args }: Args) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: args?.middleware || [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'end',
        padding: 5
      }),
      shift({ padding: 5 })
    ],
    ...args,
    ...(handleState ? { open: isPopperOpen, onOpenChange: setIsPopperOpen } : {})
  })
  const { setReference, setFloating } = refs

  const togglePopper = () => {
    setIsPopperOpen(!isPopperOpen)
  }

  return {
    floatingStyles,
    setReference,
    setFloating,
    context,
    isPopperOpen,
    togglePopper
  }
}

export default useWithPopper
