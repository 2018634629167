import { makePostRequest, makeGetRequest } from './api'
import { toS3UploadForm } from '../serializers'
import axios, { CancelToken } from 'axios'

type Config = {
  onUploadProgress: (progressEvent: ProgressEvent) => void
  cancelToken: CancelToken
}

type HasAtLeastOneStringValue =
  | Record<string | number | symbol, string>
  | Record<string | number | symbol, any>

const getStringProperty = <T, K extends keyof T>(someObject: T, property: K): string => {
  const value: unknown = someObject[property]
  if (typeof value !== 'string') {
    throw Error(`The value of the object's ${property.toString()} property is not of type string.`)
  }
  return value
}

export const processAttachment = async (
  file: File,
  config: Config,
  errorCb: (error: any) => void,
  cancelCb?: () => void,
  s3successCb?: (
    bucketUrl: string,
    fileKey: string,
    fileName: string,
    fileSize: number,
    fileType: string
  ) => void | Promise<void>,
  lifespan?: 'short' | 'medium' | 'long' | undefined
) => {
  let s3ParamsEndpoint = '/upload/params'

  if (!!lifespan) {
    s3ParamsEndpoint += '/lifespan'
  }

  const uploadParamResponse = await makeGetRequest(s3ParamsEndpoint, {
    params: {
      processData: false,
      contentType: false,
      data: file.name
    }
  })
  const s3Form = toS3UploadForm(uploadParamResponse, file, lifespan)
  const fileKey = uploadParamResponse.key
  try {
    // Upload to S3
    await makePostRequest(uploadParamResponse.AWS_S3_BUCKET_URL, s3Form, config)
    s3successCb &&
      (await s3successCb(
        uploadParamResponse.AWS_S3_BUCKET_URL,
        fileKey,
        file.name,
        file.size,
        file.type
      ))

    return {
      key: fileKey,
      name: file.name,
      size: file.size,
      contentType: file.type
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      cancelCb && cancelCb()
    } else {
      errorCb && errorCb(error)
    }
  }
}

export const prependProtocol = (url = ''): string =>
  /http(s)?:\/\//gi.test(url) ? url : `http://${url}`

export const createBgImg = ({
  path,
  position,
  size,
  theme = 'EB'
}: {
  path: string
  position?: string
  size?: string
  theme?: 'EB' | 'CG'
}): Record<string, string> => {
  if (theme === 'EB') {
    return {
      background: `url(${window.credentials.urls.s3BucketUrl}react/assets/${path}) ${position ||
        'center center'} / ${size || 'contain'} no-repeat`
    }
  }
  return {
    background: `url(${window.credentials.s3.bucketLocation}react/assets/${path}) ${position ||
      'center center'} / contain no-repeat`
  }
}

export const combineCssModules = (classes: Array<string>): string => classes.join(' ')

export const createInitials = (raw: string): string => {
  return raw
    .trim()
    .split(' ')
    .filter(Boolean)
    .reduce((initials, word) => {
      initials += word[0].toUpperCase()
      return initials
    }, '')
    .slice(0, 2)
}

export const getNumericFromPixel = (stringified: string): number => {
  const numericOnly = stringified.replace(/[^\d.]/g, '')
  return Number(numericOnly)
}

export const sortAlphabeticallyByProperty = <T extends HasAtLeastOneStringValue, K extends keyof T>(
  array: T[],
  key: K
): T[] => {
  const byPropertyValue = (property: K): ((left: T, right: T) => number) => {
    return (leftElement: T, rightElement: T) => {
      return getStringProperty(leftElement, property)
        .toLocaleLowerCase()
        .localeCompare(getStringProperty(rightElement, property).toLocaleLowerCase())
    }
  }

  return [...array].sort(byPropertyValue(key))
}

export const hasModule = (module: string): boolean =>
  (window as Window).credentials.modules.includes(module)

export const saveToLocalStorage = (key: string, value: unknown) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Local Storage is full. Please empty data')
  }
}

export const getFromLocalStorage = (key: string) => {
  const item = window.localStorage.getItem(key)
  if (item === null) {
    return null
  }
  return JSON.parse(item)
}

export const removeFromLocalStorage = (key: string) => {
  window.localStorage.removeItem(key)
}
