import sortBy from 'lodash/sortBy'

import { CATEGORY, MARKDOWN } from './constants'

const validateUniqueName = (newDoc, existingDocs) => {
  const nameExists = existingDocs.some(
    ({ name }) => newDoc.name.toLowerCase() === name.toLowerCase()
  )

  if (nameExists) {
    console.error(
      `Registered name: ${newDoc.name} is already in use. Please register a unique name.`
    )
  }
}

// Used to register components for documentation
const registration = () => {
  let docs = []

  return {
    register: doc => {
      validateUniqueName(doc, docs)
      doc.category = doc.category || CATEGORY.MISC
      docs = sortBy(
        [...docs, doc],
        [
          ({ category }) => category !== MARKDOWN.GETTING_STARTED,
          ({ markdown }) => !markdown,
          'category',
          'name'
        ]
      )
    },
    docs: () => [...docs]
  }
}

export default registration()
