import * as React from 'react'
import classNames from 'classnames'

import s from './MenuToggle.scss'

class MenuToggle extends React.Component {
  state = {
    isOpen: true
  }

  toggleMenu = () => {
    this.setState(
      prevState => ({ isOpen: !prevState.isOpen }),
      () => {
        this.props.toggleMenu()
      }
    )
  }

  render() {
    return (
      <span
        className={classNames(s.container, { [s.open]: this.state.isOpen })}
        onClick={this.toggleMenu}
      />
    )
  }
}

export default MenuToggle
