const Category = ({ content, isActive, clickCb }) => {
  return isActive ? (
    <div className="active" data-testid={content}>
      {content}
    </div>
  ) : (
    <a data-testid={content} onClick={clickCb}>
      {content}
    </a>
  )
}

export default Category
