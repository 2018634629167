import s from './ErrorPage.scss'
import { IoIosAlert } from 'react-icons/io'
import { Link } from 'react-router-dom'

const flagUrls = {
  Budget: { url: '/budgets', text: 'Budget List' },
  Matter: { url: '/v2/matters/list', text: 'Matter List' },
  Template: { url: '/v2/task_management/settings/templates', text: 'Templates List' }
}

const Page404 = ({ flag }) => (
  <section className={s.sectionContainer}>
    <div className={s.icon}>
      <IoIosAlert />
    </div>
    <h1>{`This ${flag.replace(/_/g, ' ').toLowerCase()} doesn't exist.`}</h1>
    {flagUrls[flag] ? (
      <div className={s.linkText}>
        <span>{`Return to `}</span>
        <span className={s.link}>
          <Link to={flagUrls[flag].url}>{flagUrls[flag].text}</Link>
        </span>
      </div>
    ) : null}
  </section>
)

export default Page404
