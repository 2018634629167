import { useLocation, useNavigate, useParams, useNavigationType } from 'react-router-dom'

const withRouter = Component => {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    let actionType = useNavigationType()
    return <Component {...props} router={{ location, navigate, params, actionType }} />
  }

  return ComponentWithRouterProp
}

export default withRouter
