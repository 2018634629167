import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

const { shadow, borderRadius, color, spacing } = DESIGN

const Box = ({ shadow, width, height }) => (
  <div
    style={{
      width,
      height,
      boxShadow: shadow,
      borderRadius: borderRadius.bdR200,
      background: color.gray100
    }}
  />
)

const descriptions = {
  bs100: {
    text:
      'A shallow box shadow for panels / content wrappers throughout the app that need a bit of depth added.',
    render: value => <Box width={spacing.sp1200} height={spacing.sp1000} shadow={value} />
  },
  bs200: {
    text: 'A standard box shadow used for tooltips and dropdowns.',
    render: value => <Box width={spacing.sp1400} height={spacing.sp1200} shadow={value} />
  },
  bs300: {
    text:
      'A large shadow with more spread, useful for elements with a really high z-index. This value is not typically used.',
    render: value => <Box width={spacing.sp1500} height={spacing.sp1300} shadow={value} />
  }
}

const sass = `
.box {
  box-shadow: $bs300;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { shadow: { bs300 } } = DESIGN

const Box = () => <div style={{boxShadow: bs300}} />
`

const html = '<div class="bs300"></div>'

export default registration.register({
  name: 'Box-Shadow',
  description: 'SimpleLegal Design System Box Shadows',
  example: {
    render: () => <DescriptionList pairs={getDesignValues(shadow)} descriptions={descriptions} />
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
