import classNames from 'classnames'

import { ConditionalRender, HoverAction, OutsideClickContainer, If } from 'simple-core-ui'

import s from './HoverField.scss'

type Props = {
  isEditOpen: boolean
  displayValue?: string | JSX.Element
  renderField?(cb: () => void): JSX.Element
  openEdit(): void
  closeEdit(): void
  readOnly?: boolean
  isEmpty?: boolean
  emptyValue: string
  displayAsInput?: boolean
  alwaysShowPencil?: boolean
}

const HoverField = ({
  isEditOpen,
  displayValue,
  renderField,
  openEdit,
  closeEdit,
  readOnly = false,
  isEmpty,
  emptyValue,
  displayAsInput,
  alwaysShowPencil
}: Props) => (
  <ConditionalRender
    conditions={[
      {
        condition: isEditOpen,
        content: (
          <OutsideClickContainer closeComponent={closeEdit}>
            {renderField && renderField(closeEdit)}
          </OutsideClickContainer>
        )
      },
      {
        condition: readOnly,
        content: <section className={s.readOnly}>{displayValue}</section>
      }
    ]}
    fallback={
      <section
        className={classNames(s.container, {
          [s.asInput]: displayAsInput,
          [s.asPlaceholder]: displayAsInput && isEmpty
        })}
        onClick={openEdit}
      >
        {isEmpty ? emptyValue : displayValue}
        <If condition={!displayAsInput}>
          <span className={classNames(s.editIcon, { [s.alwaysShowPencil]: alwaysShowPencil })}>
            <HoverAction
              icon={<span className={classNames('simple-line-icon-pencil', s.pencil)} />}
              onClick={openEdit}
              tip="Edit"
              size="xsmall"
            />
          </span>
        </If>
      </section>
    }
  />
)

HoverField.defaultProps = {
  emptyValue: 'None'
}

export default HoverField
