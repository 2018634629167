export const coreThemes = {
  EB: 'eb',
  CG: 'cg'
}

export const SECONDARY_TYPES = ['text', 'textarea', 'number', 'url', 'email', 'phone']

export const ON_ENTER = 'onEnter'
export const ON_TYPE = 'onType'

export const DATE_FORMATS = {
  DJANGO_DATETIME: 'YYYY-MM-DDTHH:mm:ss.SSS',
  DJANGO_DATE: 'YYYY-MM-DD',
  DEFAULT_DATE: 'MM/DD/YYYY',
  DEFAULT_DATETIME: 'MM/dd/yyyy h:mm a',
  LONG_DATE: 'MMMM Do, YYYY',
  LONG_DATETIME: 'MMMM Do, YYYY h:mm A',
  DEFAULT_DATE_FNS: 'MM/dd/yyyy'
}

export const SCOPE = {
  VENDOR: 'vendor',
  MATTER: 'matter',
  LEGAL_ENTITY: 'legalEntity',
  PRACTICE_AREA: 'practiceArea',
  APPROVAL_WORKFLOW: 'approvalWorkflow',
  COMPANY: 'company'
}
