import { CSSProperties, ChangeEvent, forwardRef, FocusEvent, KeyboardEvent } from 'react'
import { Spinner } from 'simple-core-ui/components'
import noop from 'lodash/noop'
import cn from 'classnames'
import { useAutosizeField } from 'simple-core-ui/hooks'

import s from './Input.scss'

interface Props {
  style?: CSSProperties
  text: string
  hasSpellcheck?: boolean
  placeholder?: string
  onChangeCb: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
  onKeyPressCb?: (e: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void
  isDisabled?: boolean
  isLoading?: boolean
  testid?: string
  cssClass?: string
  className?: string
  type?: string
  maxLength?: number
  step?: number
  ariaLabel?: string
  readOnly?: boolean
  autoGrowing?: boolean
  forwardedRef?:
    | React.MutableRefObject<HTMLTextAreaElement | null>
    | React.MutableRefObject<HTMLInputElement | null>
  onFocusCb?: (e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => void
  onBlurCb?: (e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => void
  onEnterCb?: (e: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void
}

function cleanNum(amount: string) {
  return amount.replace(/[^0-9\.\-]+/g, '')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Input = forwardRef((props: Props, ref) => {
  const {
    style,
    text,
    hasSpellcheck,
    placeholder,
    onChangeCb,
    onEnterCb = noop,
    onKeyPressCb,
    onBlurCb,
    onFocusCb,
    isDisabled,
    isLoading,
    type,
    testid,
    cssClass,
    forwardedRef,
    maxLength,
    step,
    ariaLabel,
    readOnly,
    autoGrowing
  } = props
  const isTextarea = type === 'textarea'
  const Component = isTextarea ? 'textarea' : 'input'
  const containerClassName = isTextarea ? s.containerTextarea : s.container
  const className = typeof isLoading === 'boolean' ? s.spinnerInput : s.input

  useAutosizeField({
    autoHeight: !!autoGrowing,
    ref: forwardedRef,
    value: text
  })

  return (
    <div className={containerClassName}>
      <Component
        aria-label={ariaLabel}
        data-testid={testid}
        className={cn(className, cssClass)}
        style={style}
        type={type}
        value={text}
        placeholder={placeholder}
        disabled={isDisabled || false}
        spellCheck={hasSpellcheck || isTextarea}
        onChange={event => {
          event.target.value = type === 'number' ? cleanNum(event.target.value) : event.target.value
          onChangeCb(event)
        }}
        onBlur={onBlurCb}
        onKeyPress={event => {
          if (event.key.toLowerCase() === 'enter') {
            const target = event.target as HTMLInputElement | HTMLTextAreaElement
            onEnterCb && onEnterCb(target.value)
          } else {
            onKeyPressCb?.(event)
          }
        }}
        onKeyDown={event => {
          if (event.key.toLowerCase() === 'backspace') {
            onKeyPressCb?.(event)
          }
        }}
        onFocus={onFocusCb}
        ref={forwardedRef as any}
        maxLength={maxLength}
        step={step}
        readOnly={readOnly}
      />
      {isLoading && <Spinner />}
    </div>
  )
})

export default Input
