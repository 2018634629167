import { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import moment from 'moment'
import { IoIosArrowForward } from 'react-icons/io'
import s from './ControlledDateRangePicker.scss'

class ControlledDateRangePicker extends Component {
  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  focusTo = wrapper => {
    // Focus to `to` field. A timeout is required here because the overlays
    // already set timeouts to work well with input fields
    this.timeout = setTimeout(() => wrapper.getInput().focus(), 0)
  }

  handleStartChange = start => {
    if (start && !this.props.end) {
      this.focusTo(this.endWrapper)
    }

    if (this.props.onRangeChange) {
      this.props.onRangeChange({ start, end: this.props.end })
    }
  }

  handleEndChange = end => {
    if (end && !this.props.start) {
      this.focusTo(this.startWrapper)
    }
    this.showStartMonth()

    if (this.props.onRangeChange) {
      this.props.onRangeChange({ end, start: this.props.start })
    }
  }

  showStartMonth = () => {
    const { start, end } = this.props
    if (!start) {
      return
    }
    if (moment(end).diff(moment(start), 'months') < 2) {
      this.endWrapper.getDayPicker().showMonth(start)
    }
  }

  setStartWrapper = el => {
    this.startWrapper = el
  }

  setEndWrapper = el => {
    this.endWrapper = el
  }

  render() {
    const { start, end } = this.props
    const modifiers = { start, end }
    return (
      <section className={'InputFromTo'}>
        <DayPickerInput
          value={start || ''}
          ref={this.setStartWrapper}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder={this.props.placeholderStart || 'Start'}
          dayPickerProps={{
            selectedDays: [start, { from: start, to: end }],
            disabledDays: { after: end },
            toMonth: end,
            modifiers,
            numberOfMonths: 2
          }}
          onDayChange={this.handleStartChange}
        />
        <p className={s.arrow}>
          <IoIosArrowForward />
        </p>
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={this.setEndWrapper}
            value={end || ''}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={this.props.placeholderEnd || 'End'}
            dayPickerProps={{
              selectedDays: [start, { from: start, to: end }],
              disabledDays: { before: start },
              modifiers,
              month: start,
              fromMonth: start,
              numberOfMonths: 2
            }}
            onDayChange={this.handleEndChange}
          />
        </span>
      </section>
    )
  }
}

export default ControlledDateRangePicker
