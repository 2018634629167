import classNames from 'classnames'

import s from './Grid.scss'

const GridColumn = ({ children, span = 12, breakpoint = 'phone', style = {}, className = '' }) => (
  <section
    className={classNames(s.column, s[`col${span}`], s[breakpoint], className)}
    style={style}
  >
    {children}
  </section>
)

export default GridColumn
