import { useState, useEffect, ChangeEventHandler } from 'react'
import { Button } from 'simple-core-ui'
import { DayPicker } from 'react-day-picker8'
import { format, parse, add, isValid, startOfDay, sub } from 'date-fns'
import cn from 'classnames'
import s from './DueDatePicker.scss'
import '!style-loader!css-loader!react-day-picker8/dist/style.css' // eslint-disable-line
import { DATE_FORMATS } from 'simple-core-ui/utils/constants'

const { DEFAULT_DATE_FNS } = DATE_FORMATS

// eslint-disable-next-line prettier/prettier
export type DateFormat = `${number | ''}${number}/${number}${number}/${number}${number}${number}${number}`

type InputError = 'wrongFormat' | 'outOfLimits' | 'disabledDays'

type ShortcutType = 'past' | 'future'

const shortcutOptions: { [key in ShortcutType]: Array<{ text: string; params: Duration }> } = {
  past: [
    { text: 'Last 7 Days', params: { days: 7 } },
    { text: 'Last 30 Days', params: { days: 30 } },
    { text: 'Last 90 Days', params: { days: 90 } },
    { text: 'Last 6 Months', params: { months: 6 } },
    { text: 'Last Year', params: { years: 1 } }
  ],
  future: [
    { text: 'Tomorrow', params: { days: 1 } },
    { text: 'In 1 week', params: { weeks: 1 } },
    { text: 'In 2 weeks', params: { weeks: 2 } },
    { text: 'In 4 weeks', params: { weeks: 4 } }
  ]
}

interface DisabledDays {
  before: Date
  after: Date
}

interface Props {
  value: DateFormat | Date | undefined
  className?: string
  placeholder?: string
  disabledDays?: DisabledDays
  maxYear?: number
  minYear?: number
  togglePopper?: () => void
  readOnly?: boolean
  canSave?: (date: Date | undefined) => boolean
  onClearCb?: () => void
  shortcutType?: 'past' | 'future'
  isCg?: boolean
}

interface WithButtons extends Props {
  onConfirm: (value: Date | undefined) => void
  onChange?: never
}
interface WithoutButtons extends Props {
  onConfirm?: never
  onChange: (value: Date | undefined) => void
}

const DEFAULT_MIN_YEAR = 1970
const DEFAULT_MAX_YEAR = 2999

const toDate = (date: string) => {
  if (!date) {
    return undefined
  }
  return parse(date, DEFAULT_DATE_FNS, new Date())
}

const getRelativeToTodayDate = (type: ShortcutType, param: Duration) => {
  const fn = type === 'past' ? sub : add
  return format(fn(new Date(), param), 'E')
}

const isValidFormat = (dateString: string): boolean => {
  const regEx = /^\d{2}\/\d{2}\/\d{4}$/
  return regEx.test(dateString)
}

const isValidRange = (dateString: string, minYear: number, maxYear: number) => {
  const year = parseInt(dateString.substring(6))
  return year >= minYear && year <= maxYear
}

const isDateAvailable = (date?: Date, disabledDays?: DisabledDays): boolean => {
  if (!disabledDays || !date) return true
  const { before, after } = disabledDays
  if (before < after) {
    return date >= before && date <= after
  }
  return date <= after || date >= before
}

export const DueDatePicker = ({
  className,
  disabledDays,
  minYear = DEFAULT_MIN_YEAR,
  maxYear = DEFAULT_MAX_YEAR,
  onChange,
  value,
  onConfirm,
  togglePopper,
  readOnly,
  canSave,
  onClearCb,
  shortcutType = 'future',
  isCg
}: WithButtons | WithoutButtons) => {
  const [selected, setSelected] = useState<Date | undefined>(undefined)
  const [inputValue, setInputValue] = useState('')
  const [refreshKey, setRefreshKey] = useState(0)
  const [error, setError] = useState<InputError | null>(null)

  useEffect(() => {
    if (!value) {
      setSelected(undefined)
      setInputValue('')
      return
    }
    const date = value instanceof Date ? value : toDate(value)
    if (date !== selected) {
      setSelected(date)
      date && setInputValue(format(date, DEFAULT_DATE_FNS))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleDaySelect = (date: Date | undefined) => {
    setSelected(date)
    setError(null)
    if (date) {
      setInputValue(format(date, DEFAULT_DATE_FNS))
      onChange?.(date)
    } else {
      setInputValue('')
    }
  }

  const handleShortcutClick = (date: Date) => {
    if (readOnly || !isDateAvailable(date, disabledDays)) {
      return
    }
    handleDaySelect(date)
    setRefreshKey(refreshKey + 1)
    setError(null)
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget
    setInputValue(value)

    const handleError = (err: InputError | null) => {
      setError(err)
      setSelected(undefined)
    }

    if (value === '') {
      handleError(null)
      return
    }

    const date = toDate(value)
    if (!isValid(date) || !isValidFormat(value)) {
      handleError('wrongFormat')
      return
    }
    if (!isValidRange(value, minYear, maxYear)) {
      handleError('outOfLimits')
      return
    }
    if (!isDateAvailable(date, disabledDays)) {
      handleError('disabledDays')
      return
    }

    setError(null)
    setRefreshKey(refreshKey + 1)
    setSelected(date)
    onChange?.(date)
  }

  const onClear = () => {
    setError(null)
    setSelected(undefined)
    setInputValue('')
    onChange?.(undefined)
    onClearCb?.()
  }

  const errorMessages: { [K in InputError]: string } = {
    wrongFormat: 'Enter MM/DD/YYYY format',
    outOfLimits: `Enter date within 01/01/${minYear} and 12/31/${maxYear}`,
    disabledDays: 'Date is not selectable'
  }

  return (
    <div className={cn(s.dayPickerWrapper, className)}>
      <div className={s.sidebar}>
        <input
          disabled={readOnly}
          data-testid="due-date-picker-input"
          type="text"
          placeholder="MM/DD/YYYY"
          value={inputValue}
          onChange={handleInputChange}
          style={error ? { border: '1px solid red' } : {}}
        />
        {error && <p className={s.error}>{errorMessages[error]}</p>}
        <div className={s.row} onClick={() => handleShortcutClick(startOfDay(new Date()))}>
          <div className={s.bold}>Today</div>
          <div className={s.day}>{format(new Date(), 'E')}</div>
        </div>
        {shortcutOptions[shortcutType].map(option => (
          <div
            key={option.text}
            className={s.row}
            onClick={() =>
              handleShortcutClick(
                (shortcutType === 'future' ? add : sub)(startOfDay(new Date()), option.params)
              )
            }
          >
            <div className={s.bold}>{option.text}</div>
            <div className={s.day}>{getRelativeToTodayDate(shortcutType, option.params)}</div>
          </div>
        ))}
      </div>
      <div className={s.main}>
        <DayPicker
          key={refreshKey}
          mode="single"
          defaultMonth={selected}
          selected={selected}
          onSelect={handleDaySelect}
          className={className}
          disabled={disabledDays || readOnly}
          modifiersStyles={{
            selected: { background: '#E2F0FF', border: '2px solid #3C98FD', color: '#000000' }
          }}
          fromYear={minYear}
          toYear={maxYear}
        />
        {onConfirm && (
          <div className={s.footer}>
            <Button
              hasNewDesign
              onClick={onClear}
              isPrimary
              isOutline
              isDisabled={readOnly}
              isCg={isCg}
              className={cn(s.clearBtn, { [s.cgButton]: isCg })}
            >
              Clear
            </Button>
            <Button
              hasNewDesign
              onClick={() => {
                onConfirm?.(selected)
                togglePopper?.()
              }}
              isPrimary
              isDisabled={!!error || readOnly || (canSave && !canSave(selected))}
              className={cn(s.applyBtn, { [s.cgButton]: isCg })}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
