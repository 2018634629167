import { Component } from 'react'
import { FaAngleLeft, FaAngleDown } from 'react-icons/fa'
import s from './Accordion.scss'

class Accordion extends Component {
  state = { isOpen: true }

  toggleAccordion = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { name, children, draggable } = this.props
    let arrow
    if (this.state.isOpen) {
      arrow = <FaAngleDown className={s.arrow} />
    } else {
      arrow = <FaAngleLeft className={s.arrow} />
    }

    return (
      <div>
        <div
          ref={this.props.refProp}
          onClick={this.toggleAccordion}
          className={`${s.sectionHeader} ${draggable ? s.draggable : ''}`}
        >
          <span>{name}</span>
          {arrow}
        </div>
        {this.state.isOpen && children}
      </div>
    )
  }
}

export default Accordion
