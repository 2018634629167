import { useRef, useEffect } from 'react'
import classNames from 'classnames'
import head from 'lodash/head'
import set from 'lodash/set'
import queryString from 'query-string'

import registration from 'docs/registration'
import { If, coreThemes } from 'simple-core-ui'
import DocTree from '../DocTree/DocTree'
import Doc from '../Doc/Doc'
import MenuToggle from '../MenuToggle/MenuToggle'
import { getThemeColor } from 'simple-core-ui/styles/cssInJs'
import { useImmer } from 'use-immer'
import { useLocation } from 'react-router-dom'

import s from './Styleguide.scss'

const getInitialSelectedDoc = location => {
  const { selected } = queryString.parse(location.search)
  const docs = registration.docs()
  const selectedDoc = docs.find(
    doc => selected && doc.name.toLowerCase() === selected.toLowerCase()
  )

  return selectedDoc || head(docs)
}

// Necessary because a couple of global CG style declarations break
// the Styleguide UI (i.e overflow: hidden)
const adjsutCGGlobalStyling = () => {
  const documentBody = document.body
  const appMountPoint = document.getElementById('app')
  set(documentBody, 'style.overflow', 'initial')
  set(appMountPoint, 'style.width', 'auto')
  set(appMountPoint, 'style.height', 'auto')
}

const Styleguide = ({ theme }) => {
  const location = useLocation()
  const [state, setState] = useImmer({
    isSidebarOpen: true,
    docs: registration.docs(),
    selectedDoc: getInitialSelectedDoc(location)
  })
  const docRef = useRef({ current: 'section' })
  const { isSidebarOpen, selectedDoc, docs } = state

  useEffect(() => {
    if (theme === coreThemes.CG) {
      adjsutCGGlobalStyling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateLinkParam()
    if (docRef.current) {
      docRef.current.scrollTop = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedDoc])

  const toggleSidebar = () => {
    setState(draft => {
      draft.isSidebarOpen = !draft.isSidebarOpen
    })
  }

  const updateLinkParam = () => {
    const { search, pathname } = window.location
    let queryParams = new URLSearchParams(search.slice(1))
    queryParams.set('selected', state.selectedDoc.name)
    window.history.replaceState(null, null, `${pathname}?${String(queryParams)}`)
  }

  const selectDoc = doc => {
    setState(draft => {
      draft.selectedDoc = doc
    })
  }

  return (
    <section className={classNames(s.container, { [s.closed]: !isSidebarOpen })}>
      <section className={s.header} style={{ borderColor: getThemeColor(theme, 'primary') }}>
        <MenuToggle toggleMenu={toggleSidebar} />
        <h1 className={s.animatedTitle}>
          <span className={s.titleLeft}>SimpleLegal</span>
          <span className={s.emoji}>🎨</span>
          <span className={s.titleRight}>Styleguide</span>
        </h1>
        <h4 className={s.appName}>{theme === coreThemes.EB ? 'Ebill' : 'CounselGO'}</h4>
      </section>
      <If condition={isSidebarOpen}>
        <section className={s.sidebar}>
          <DocTree docs={docs} onSelect={selectDoc} selectedDoc={selectedDoc} />
        </section>
      </If>
      <section className={s.main} ref={docRef}>
        <Doc {...selectedDoc} theme={theme} />
      </section>
    </section>
  )
}

export default Styleguide
