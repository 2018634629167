import cn from 'classnames'

import s from './PageSizeOptions.scss'

interface Props {
  options?: { label: string; value: number }[]
  pageSize: number
  classNames?: Partial<{
    wrapper: string
  }>
  onChange(value: number): void
}

const defaultOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 }
]

export default function PageSizeOptions({
  options = defaultOptions,
  pageSize,
  classNames,
  onChange
}: Props) {
  return (
    <section className={cn(s.wrapper, classNames?.wrapper)}>
      View:
      {options.map(option => (
        <span
          key={option.value}
          onClick={() => onChange(option.value)}
          data-testid={`page-size-${option.value}`}
          className={cn({
            [s.selected]: pageSize === option.value
          })}
        >
          {option.value}
        </span>
      ))}
    </section>
  )
}
