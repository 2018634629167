const CleanFileIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.60742 12.6074 5.53398 12.4975 5.48387 12.3765C5.43377 12.2556 5.40798 12.1259 5.40798 11.995C5.40798 11.8641 5.43377 11.7344 5.48387 11.6135C5.53398 11.4925 5.60742 11.3826 5.7 11.29C5.79258 11.1974 5.90249 11.124 6.02346 11.0739C6.14442 11.0238 6.27407 10.998 6.405 10.998C6.53593 10.998 6.66558 11.0238 6.78654 11.0739C6.90751 11.124 7.01742 11.1974 7.11 11.29L10 14.17L16.88 7.29C17.067 7.10302 17.3206 6.99798 17.585 6.99798C17.8494 6.99798 18.103 7.10302 18.29 7.29C18.477 7.47698 18.582 7.73057 18.582 7.995C18.582 8.25943 18.477 8.51302 18.29 8.7L10.7 16.29C10.6075 16.3827 10.4976 16.4563 10.3766 16.5064C10.2557 16.5566 10.126 16.5824 9.995 16.5824C9.86403 16.5824 9.73435 16.5566 9.61338 16.5064C9.4924 16.4563 9.38251 16.3827 9.29 16.29Z"
        fill="#57C787"
      />
    </svg>
  )
}

export default CleanFileIcon
