/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react'
import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'
import cn from 'classnames'
import VerticalTab from '../VerticalTab/VerticalTab'
import s from './VerticalTabs.scss'
import { useNavigate, useRoutes } from 'react-router-dom'

type TabType = JSX.Element | false | null | undefined

interface Tab {
  index?: boolean
  key: string
  label: string
  path: string
  element: JSX.Element
  children?: Array<{
    path: string
    element: JSX.Element
  }>
}

interface WithRoutes extends Props {
  withRoutes: boolean
  children?: never
}

interface WithChildren extends Props {
  withRoutes?: never
  children: JSX.Element | Array<TabType>
}

interface Props {
  activeTabKey: string
  className?: string
  sidebarClassName?: string
  withUpdateUrl?: boolean
  hasControlledTabs?: boolean
  tabs?: Tab[]
}

const VerticalTabs = ({
  children,
  activeTabKey,
  className,
  sidebarClassName,
  withUpdateUrl = false,
  hasControlledTabs = false,
  withRoutes = false,
  tabs = []
}: WithChildren | WithRoutes) => {
  const [activeTab, setActiveTab] = useState(activeTabKey)
  const [prevActiveTab, setPrevActiveTab] = useState(activeTabKey)
  const navigate = useNavigate()

  useEffect(() => {
    if (withUpdateUrl) {
      navigate({
        search: `?tab=${activeTab}`
      })
    }
  }, [activeTab, navigate, withUpdateUrl])

  if (
    (activeTabKey !== activeTab && ((!hasControlledTabs && !activeTab) || hasControlledTabs)) ||
    (withRoutes && activeTabKey !== activeTab)
  ) {
    setActiveTab(activeTabKey)
  }

  if (activeTab !== prevActiveTab) {
    setPrevActiveTab(activeTab)
  }

  const getTab = (tab: TabType | Tab) => {
    return (
      !!tab && (
        <div key={tab.key} className={s.navItem}>
          <div
            className={cn(s.navLink, { [s.active]: activeTab === tab.key })}
            onClick={() => {
              if (withRoutes) {
                if ('path' in tab) navigate(tab.path as string)
              } else {
                setActiveTab(tab.key as string)
                'props' in tab && tab.props.onClick && tab.props.onClick()
              }
            }}
          >
            {withRoutes ? 'label' in tab && tab.label : 'props' in tab && tab.props.label}
          </div>
        </div>
      )
    )
  }

  const tabChildren = Array.isArray(children) ? children.filter(tab => tab !== null) : children

  const filterChildren = () => {
    return (tabChildren as Array<JSX.Element>).filter(tab => activeTab === tab.key)
  }

  return (
    <div className={cn(s.tabsWrapper, className)}>
      <aside className={cn(s.nav, sidebarClassName)}>
        {withRoutes ? (
          <>{tabs.map(tab => getTab(tab))}</>
        ) : Array.isArray(tabChildren) ? (
          tabChildren.map(getTab)
        ) : (
          getTab(tabChildren)
        )}
      </aside>
      <main className={s.tabContent}>
        {withRoutes ? (
          <div className={s.tabPane}>
            {useRoutes([
              ...tabs
                .filter(t => t.index)
                .map(t => ({
                  index: true,
                  element: t.element
                })),
              ...tabs
            ])}
          </div>
        ) : Array.isArray(tabChildren) ? (
          filterChildren().map(tab => (
            <div className={s.tabPane} key={tab.key}>
              {tab.props.children}
            </div>
          ))
        ) : (
          <div className={s.tabPane} key={tabChildren?.key}>
            {tabChildren?.props.children}
          </div>
        )}
      </main>
    </div>
  )
}

registration.register({
  name: 'VerticalTabs',
  description:
    'A component that can be used to separate content into different panes where each pane is viewable one at a time.',
  props: [
    { name: 'children', type: 'React.Node', note: 'The content within the tab.' },
    {
      name: 'activeTabKey',
      type: 'string',
      note: 'The key of the current visible tab.'
    },
    {
      name: 'className',
      optional: true,
      type: 'string',
      note: 'The class that can be passed to the parent element for custom styles.'
    }
  ],
  example: {
    literal: `
<section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
  <VerticalTabs activeTabKey={'tab1'}>
    <VerticalTab key="tab1" label="First Tab">
      <div>This is the content of the first tab</div>
    </VerticalTab>
    <VerticalTab key="tab2" label="Second Tab">
      <div>This is the content of the second tab</div>
    </VerticalTab>
    <VerticalTab key="tab3" label="Third Tab">
      <div>This is the content of the third tab</div>
    </VerticalTab>
  </VerticalTabs>
</section>`.trim(),
    render: () => (
      <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <VerticalTabs activeTabKey={'tab1'}>
          <VerticalTab key="tab1" label="First Tab">
            <div>This is the content of the first tab</div>
          </VerticalTab>
          <VerticalTab key="tab2" label="Second Tab">
            <div>This is the content of the second tab</div>
          </VerticalTab>
          <VerticalTab key="tab3" label="Third Tab">
            <div>This is the content of the third tab</div>
          </VerticalTab>
        </VerticalTabs>
      </section>
    )
  },
  category: CATEGORY.NAV,
  path: 'components/Core/VerticalTabs/VerticalTabs'
})

export default VerticalTabs
