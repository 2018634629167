interface Props {
  pinnedColumns: {
    label: string
    pinPosition: number
  }[]
  width: string
  key: string
  pinActions: boolean
  customizableColumns: boolean
}

export const getColumnOffsets = ({
  pinnedColumns,
  width,
  key,
  pinActions,
  customizableColumns
}: Props) => {
  const pinnedColumn = pinnedColumns.find(col => col.label === key)
  const OFFSET = 52

  let defaultOffset = 0
  if (pinActions) {
    defaultOffset += OFFSET
  }
  if (customizableColumns) {
    defaultOffset += OFFSET
  }

  const customColumnsOffset = pinnedColumn
    ? pinnedColumn.pinPosition * (width ? parseInt(width) : OFFSET)
    : 0

  return { defaultOffset, customColumnsOffset }
}
