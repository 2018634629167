import s from './Dropdown.scss'

type Value = string | number
type Option = { value: Value; label: string }
type Props = {
  selectedOption?: Option
  dataSet: Array<Option>
  updateOption(value: Value): void
}

const List = ({ selectedOption, dataSet, updateOption }: Props) => (
  <ul className={s.list}>
    {dataSet.map((el, i) => (
      <li
        className={el.value === selectedOption?.value ? s.active : s.item}
        onClick={() => updateOption(el.value)}
        key={i}
      >
        <span>{el.label}</span>
      </li>
    ))}
  </ul>
)

export default List
