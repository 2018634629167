const Invoices = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 128 128">
      <g className="st0">
        {' '}
        <path
          className="st1"
          d="M55.4,29.5l23,23v51.8c0,1.6-0.6,3-1.7,4.1c-1.1,1.1-2.5,1.7-4.1,1.7H23.7c-1.6,0-3-0.6-4.1-1.7  c-1.1-1.1-1.7-2.5-1.7-4.1v-69c0-1.6,0.6-3,1.7-4.1c1.1-1.1,2.5-1.7,4.1-1.7H55.4z M72.6,58.2h-23v-23H23.7v69h48.9V58.2z M110,41  v51.8c0,1.6-0.6,3-1.7,4.1c-1.1,1.1-2.5,1.7-4.1,1.7H84.1v-5.7h20.1v-46h-23v-23H49.6c0-1.6,0.6-3,1.7-4.1c1.1-1.1,2.5-1.7,4.1-1.7  H87L110,41z M70.2,52.5L55.4,37.6v14.9H70.2z M101.8,41L87,26.1V41H101.8z"
        />
      </g>
    </svg>
  )
}

export default Invoices
