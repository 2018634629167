import { useState, useRef, useEffect } from 'react'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const useScrollIntoView = deps => {
  const ref = useRef(null)

  useEffect(() => {
    if (deps.some(v => v) && ref.current) {
      ref.current.scrollIntoView(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return ref
}

registration.register({
  name: 'useScrollIntoView',
  description:
    'A simple hook that provides a ref that can be placed on an element when any dependency props passed in have changed.',
  props: [
    {
      name: 'dependencies',
      type: 'Array<any>',
      note:
        'Should be an array of props from the calling component that when set to true (i.e isExpanded) would cause the ref to scroll into view.'
    }
  ],
  example: {
    literal: `
import React, { useScrollIntoView } from 'react'

// When isExpanded is true the ListItem component will be scrolled
// into view at the top of the screen.
const ListItem = ({ isExpanded, label, ...restProps }) => {
  const liRef = useScrollIntoView([isExpanded])

  return (
    <li ref={liRef} isExpanded={isExpanded} {...restProps}>
      {label}
    </li>
  )
}`,
    render: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [expandedIdx, updateExpanded] = useState()

      const ListItem = ({ isExpanded, label, details, idx }) => {
        const liRef = useScrollIntoView([isExpanded])

        return (
          <li
            ref={liRef}
            onClick={() => updateExpanded(idx === expandedIdx ? null : idx)}
            style={{
              padding: '1rem',
              backgroundColor: 'white',
              border: '1px solid #ededed',
              cursor: 'pointer'
            }}
          >
            <span style={{ fontWeight: isExpanded ? 'bold' : 'normal', fontSize: '1.25rem' }}>
              {label}
            </span>
            {isExpanded && <p style={{ marginTop: '1rem', lineHeight: '1.5' }}>{details}</p>}
          </li>
        )
      }

      const details =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

      const data = Array(7)
        .fill('')
        .map((_, idx) => ({
          label: (idx + 1) * 10000000000,
          details
        }))

      return (
        <ul style={{ maxWidth: '500px' }}>
          {data.map((props, idx) => (
            <ListItem {...props} isExpanded={idx === expandedIdx} idx={idx} key={idx} />
          ))}
        </ul>
      )
    }
  },
  propsAsArgs: true,
  category: CATEGORY.HOOKS,
  path: 'hooks/useScrollIntoView'
})

export default useScrollIntoView
