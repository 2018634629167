import { OutsideClickContainer, useWithPopper } from 'simple-core-ui'
import { cloneElement, ReactElement, JSXElementConstructor, CSSProperties } from 'react'
import s from './DropdownPopper.scss'
import cn from 'classnames'
import { FaAngleDown } from 'react-icons/fa'
import capitalize from 'lodash/capitalize'
import pluralize from 'pluralize'
import { Placement } from '@floating-ui/react'

interface Option {
  value: string | number | undefined
  label: string
}
interface Props {
  value: Option[] | (() => string | null)
  children: ReactElement<any, string | JSXElementConstructor<any>>
  label: string
  placeholder: string
  style?: CSSProperties
  pillClass?: string
  placement?: Placement
  floatingStyles?: CSSProperties
  noActiveStyles?: boolean
  isCg?: boolean
}

const DropdownPopper = ({
  value,
  children,
  label,
  style,
  pillClass,
  placeholder,
  placement = 'bottom-start',
  floatingStyles: customFloatingStyles = {},
  noActiveStyles,
  isCg
}: Props) => {
  const { isPopperOpen, togglePopper, setReference, setFloating, floatingStyles } = useWithPopper({
    placement
  })

  const getLabel = () => {
    if (typeof value === 'function') return value() ?? placeholder
    if (value?.length > 0) {
      return value.length === 1
        ? decodeURIComponent(value[0].label)
        : `${value.length} ${pluralize(capitalize(label))}`
    }
    return placeholder
  }

  return (
    <div style={style} className={s.filter}>
      <div
        ref={setReference}
        className={cn(
          s.pill,
          {
            [s.active]:
              (isPopperOpen || (typeof value === 'function' && value()) || value.length > 0) &&
              !noActiveStyles,
            [s.isCg]: isCg
          },
          pillClass
        )}
        onClick={togglePopper}
      >
        <span
          style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {getLabel()}
        </span>
        <FaAngleDown className={s.icon} />
      </div>

      {isPopperOpen && (
        <OutsideClickContainer styles={{ height: 'initial' }} closeComponent={togglePopper}>
          <div
            tabIndex={-1}
            style={{ ...floatingStyles, ...customFloatingStyles }}
            ref={setFloating}
            className={s.popper}
          >
            {cloneElement(children, { togglePopper })}
          </div>
        </OutsideClickContainer>
      )}
    </div>
  )
}

export default DropdownPopper
