import s from '../Table/Table.scss'
import { HoverAction } from 'simple-core-ui'
import cn from 'classnames'

const Action = ({ row, action }) => {
  let button = action
  if (typeof action === 'function') {
    button = action(row)
    if (!button) {
      return null
    }
  }

  const renderButton = (button, row) => {
    return (
      <button.icon
        aria-label={`item-${row.id}`}
        className={cn(s.tableIcon, {
          [button.className]: button.className
        })}
        onClick={e => {
          e.stopPropagation()
          !button.disabled && button.onClick && button.onClick(e, row)
        }}
      />
    )
  }

  if (button.condition !== undefined && !button.condition) return null

  if (button.tooltip) {
    return (
      <HoverAction
        tip={button.tooltip}
        isDisabled={button.disabled}
        icon={renderButton(button, row)}
        size="xsmall"
        className={s.hoverAction}
      />
    )
  }

  return renderButton(button)
}

export default Action
