import * as React from 'react'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'

import { If, TextInput } from 'simple-core-ui'

import { EMOJI_MAP } from '../constants'

import s from './DocTree.scss'

class DocTree extends React.Component {
  state = {
    docFilter: '',
    openCategories: [this.props.selectedDoc.category]
  }

  updateDocFilter = filter => {
    this.setState({ docFilter: filter })
  }

  filteredDocs = () => {
    const normalizedFilter = this.state.docFilter.toLowerCase()

    return this.props.docs.filter(({ name, category }) => {
      const normalizedName = name.toLowerCase()
      const normalizedCategory = category.toLowerCase()

      return (
        normalizedName.startsWith(normalizedFilter) ||
        normalizedCategory.startsWith(normalizedFilter)
      )
    })
  }

  onSelect = doc => {
    if (!this.state.openCategories.includes(doc.category)) {
      this.toggleCategory(doc.category)
    }

    this.props.onSelect(doc)
  }

  toggleCategory = category => {
    this.setState(prevState => {
      return {
        openCategories: prevState.openCategories.includes(category)
          ? prevState.openCategories.filter(openCategory => openCategory !== category)
          : [...prevState.openCategories, category]
      }
    })
  }

  groupedByCategory = () => groupBy(this.filteredDocs(), 'category')

  render() {
    const { selectedDoc } = this.props
    const { docFilter, openCategories } = this.state
    const filteredDocs = this.filteredDocs()
    const groupedByCategory = this.groupedByCategory()

    return (
      <React.Fragment>
        <TextInput
          onChange={this.updateDocFilter}
          value={docFilter}
          placeholder="Search..."
          style={{
            maxWidth: '500px',
            marginBottom: window.DESIGN.spacing.sp300
          }}
        />
        <ul className={s.sidebarDocListing}>
          <If
            condition={filteredDocs.length}
            fallback={<span className={s.empty}>No Results Found...</span>}
          >
            {Object.keys(groupedByCategory).map((category, categoryIdx) => (
              <li key={categoryIdx} className={s.category}>
                <ul className={s.categoryGroup}>
                  <li
                    key={-1}
                    className={classNames(s.sidebarDoc, {
                      [s.selected]: openCategories.includes(category)
                    })}
                    onClick={() => this.toggleCategory(category)}
                  >
                    <span className={s.emoji}>{get(EMOJI_MAP, category, '🤔')}</span>
                    {category}
                  </li>
                  <If condition={docFilter.length || openCategories.includes(category)}>
                    {groupedByCategory[category].map((doc, docIdx) => (
                      <li
                        key={docIdx}
                        onClick={() => this.onSelect(doc)}
                        className={classNames(s.sidebarDoc, s.nested, {
                          [s.selected]: isEqual(doc, selectedDoc)
                        })}
                      >
                        <If condition={doc.markdown && !doc.example}>
                          <span className={s.emoji}>📖</span>
                        </If>
                        {doc.name}
                      </li>
                    ))}
                  </If>
                </ul>
              </li>
            ))}
          </If>
        </ul>
      </React.Fragment>
    )
  }
}

export default DocTree
