import { LoadingSkeleton } from 'simple-core-ui'
import s from './TableLoadingSkeleton.scss'

const NUM_ROWS = 11
const DEFAULT_NUM_COLUMNS = 6
const CELL_WIDTHS = ['40%', '90%', '70%', '60%', '80%', '90%', '40%', '90%', '70%', '60%', '80%']

const getCells = (numCol, width) =>
  [...new Array(numCol)].map((item, index) => (
    <div className={s.column} key={index}>
      <LoadingSkeleton height={10} style={{ width: width || '80%', borderRadius: 2 }} />
    </div>
  ))

const getRows = numCol =>
  [...new Array(NUM_ROWS)].map((item, index) => (
    <div key={index} className={s.row}>
      {getCells(numCol, CELL_WIDTHS[index])}
    </div>
  ))

const TableLoadingSkeleton = ({ columnCount }) => {
  const numColumns = columnCount || DEFAULT_NUM_COLUMNS
  return (
    <section className={s.skeletonContainer}>
      <div className={s.row}>{getCells(numColumns)}</div>
      <LoadingSkeleton line style={{ marginBottom: '30px' }} />
      {getRows(numColumns)}
    </section>
  )
}

export default TableLoadingSkeleton
