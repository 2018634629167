import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

const { spacing } = DESIGN

const Spacer = ({ value, color }) => (
  <section
    style={{
      width: value,
      background: color,
      height: spacing.sp300
    }}
  />
)

const descriptions = getDesignValues(spacing).reduce(
  (acc, [name, value], idx) => ({
    ...acc,
    [name]: {
      render: v => <Spacer value={v} color={`hsl(${210 + idx * 10}, 100%, 56%)`} />
    }
  }),
  {}
)

const sass = `
.box {
  margin: $sp300 $sp200;
  padding: $sp400;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { spacing: { sp300, sp400 } } = DESIGN

const Box = () => <div style={{padding: sp400, margin: sp300 + ' ' + sp400}} />
`

const html = `
<div class='p-sp400 m-v-sp300 m-h-sp400'></div>

<!--
.m-sp100    ==> margin all around
.m-t-sp100  ==> margin on top
.m-r-sp100  ==> margin on right
.m-b-sp100  ==> margin on bottom
.m-l-sp100  ==> margin on left
.m-v-sp100  ==> margin on top / bottom
.m-h-sp100  ==> margin on left / right

.p-sp100    ==> padding all around
.p-t-sp100  ==> padding on top
.p-r-sp100  ==> padding on right
.p-b-sp100  ==> padding on bottom
.p-l-sp100  ==> padding on left
.p-v-sp100  ==> padding on top / bottom
.p-h-sp100  ==> padding on left / right

.w-sp100      ==> width set to spacing 100
.max-w-sp100  ==> max-width set to spacing 100
.min-w-sp100  ==> min-width set to spacing 100

.h-sp100      ==> height set to spacing 100
.max-h-sp100  ==> max-height set to spacing 100
.min-h-sp100  ==> min-height set to spacing 100
-->
`

export default registration.register({
  name: 'Spacing',
  description:
    'SimpleLegal Design System Spacing Scale. Pull from this area for properties such as padding or margin.',
  example: {
    render: () => <DescriptionList pairs={getDesignValues(spacing)} descriptions={descriptions} />
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
