import s from './FilterValues.scss'
import cn from 'classnames'
import { CheckboxContainer } from 'simple-core-ui'

const FilterValue = ({
  value,
  label,
  isSelected,
  setSelectedValue,
  valid,
  optionFormat,
  isMulti,
  isReadOnly,
  labelPrefix = ''
}) => (
  <li
    aria-label={labelPrefix && `${labelPrefix}-${value}`}
    className={cn({
      [s.selected]: !isMulti && isSelected,
      [s.item]: isMulti || (!isMulti && !isSelected),
      [s.disabled]: !valid,
      [s.isMulti]: isMulti
    })}
    onClick={isReadOnly ? null : () => setSelectedValue(value, valid)}
  >
    <>
      {isMulti && (
        <CheckboxContainer
          isChecked={isSelected}
          cb={() => setSelectedValue(value, valid)}
          size="md"
          hasWhiteBorder
          hasGreyBorder
          className={s.checkbox}
          disabled={isReadOnly}
        />
      )}
      <span>{optionFormat || label}</span>
    </>
  </li>
)

export default FilterValue
