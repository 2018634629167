const LegalRequests = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 92.1 88.2">
      <path d="M39.1,92.44h.46v0A3.73,3.73,0,0,1,39.1,92.44Z" transform="translate(-17.95 -19.9)" />
      <path
        d="M110.05,58.11v47a3,3,0,0,1-3,3h0a3,3,0,0,1-3-3v-44H55.21v44a3,3,0,1,1-5.93,0v-47a3,3,0,0,1,3-3h54.83A3,3,0,0,1,110.05,58.11Z"
        transform="translate(-17.95 -19.9)"
      />
      <path
        d="M86.55,73.78a3,3,0,0,1-3,3H67.91a3,3,0,0,1,0-6H83.58A3,3,0,0,1,86.55,73.78Z"
        transform="translate(-17.95 -19.9)"
      />
      <path
        d="M94.39,85.53a3,3,0,0,1-3,3H67.91a3,3,0,1,1,0-5.93H91.43A3,3,0,0,1,94.39,85.53Z"
        transform="translate(-17.95 -19.9)"
      />
      <path
        d="M94.39,97.29a3,3,0,0,1-3,3H67.91a3,3,0,1,1,0-5.93H91.43A3,3,0,0,1,94.39,97.29Z"
        transform="translate(-17.95 -19.9)"
      />
      <path
        d="M95.31,31.65H74.81v-9.1a2.66,2.66,0,0,0-2.65-2.65H48a2.65,2.65,0,0,0-2.65,2.65v9.1H24.84A6.89,6.89,0,0,0,18,38.56v47a6.88,6.88,0,0,0,6.89,6.89H39.1a3.73,3.73,0,0,0,.46,0,3,3,0,0,0,0-5.85v-.06H23.9V37.62H96.27v7.83a3,3,0,0,0,5.93,0V38.56A6.91,6.91,0,0,0,95.31,31.65Zm-26.47,0H51.31V25.87H68.84Z"
        transform="translate(-17.95 -19.9)"
      />
    </svg>
  )
}

export default LegalRequests
