// Helper functions for filtering

const defaultMatcher = (filterText, node) => {
  return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
}

export const setAllOtherInactive = (data, node) => {
  return {
    ...data,
    children:
      Array.isArray(data.children) && data.children.map(child => setAllOtherInactive(child, node)),
    active: data.id === node.id
  }
}

export const findNode = (node, filter, matcher) => {
  return (
    matcher(filter, node) || // I match
    (Array.isArray(node.children) && // or I have decendents and one of them match
      !!node.children.find(child => findNode(child, filter, matcher)))
  )
}

export const filterTree = (node, filter, matcher = defaultMatcher) => {
  // If I'm an exact match then all my children get to stay
  if (matcher(filter, node) || !node.children) {
    return node
  }
  // If not then only keep the ones that match or have matching descendants
  const filtered = node.children
    .filter(child => findNode(child, filter, matcher))
    .map(child => filterTree(child, filter, matcher))

  return Object.assign({}, node, { children: filtered })
}

export const expandFilteredNodes = (node, filter, matcher = defaultMatcher) => {
  let children = node.children
  if (!children || children.length === 0) {
    return Object.assign({}, node, { toggled: false })
  }
  const childrenWithMatches = node.children.filter(child => findNode(child, filter, matcher))
  const shouldExpand = childrenWithMatches.length > 0
  // If I'm going to expand, go through all the matches and see if thier children need to expand
  if (shouldExpand) {
    children = childrenWithMatches.map(child => {
      return expandFilteredNodes(child, filter, matcher)
    })
  }
  return Object.assign({}, node, {
    children: children,
    toggled: shouldExpand
  })
}
