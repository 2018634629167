import React, { ReactNode } from 'react'
import Truncate from 'react-truncate'
import s from './Ellipsis.scss'
import cn from 'classnames'
import { Tooltip } from 'simple-core-ui'

type Props = {
  children?: ReactNode
  lines: number
  className?: string
  width?: number
  truncateText?: ReactNode
  tooltipClassName?: string
}

type State = {
  isTruncated: boolean
}

class Ellipsis extends React.Component<Props, State> {
  state = { isTruncated: false }

  static defaultProps = {
    lines: 2
  }

  render() {
    const { children, lines, className, width, truncateText = null, tooltipClassName } = this.props
    const { isTruncated } = this.state

    return (
      <Tooltip
        trigger={
          <Truncate
            lines={lines}
            width={width}
            {...(truncateText ? { ellipsis: truncateText } : {})}
            onTruncate={isTruncated =>
              isTruncated !== this.state.isTruncated && this.setState({ isTruncated })
            }
          >
            {children}
          </Truncate>
        }
        content={isTruncated ? children : ''}
        triggerClassName={cn(s.container, className)}
        contentClassName={tooltipClassName}
      />
    )
  }
}

export default Ellipsis
