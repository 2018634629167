import { GITLAB_URL } from './constants'

export const linkToComponent = path => {
  const a = document.createElement('a')
  a.setAttribute('href', `${GITLAB_URL}/static/simple-core-ui/${path}.js`)
  a.setAttribute('target', '_blank')
  a.click()
  a.remove()
}

const designPropsBlackList = [
  'fade-in',
  'fade-top',
  'fade-bottom',
  'fade-left',
  'fade-right',
  'bulkBlack',
  'bulkGrey',
  'bulkLightGrey1',
  'bulkLightGrey2',
  'bulkRed',
  'bulkRed2'
]

export const getDesignValues = (desSysObj = {}) =>
  Object.entries(desSysObj).filter(([name]) => !designPropsBlackList.includes(name))

export const usageMarkdown = ({ sass = '', js = '', html = '' }) =>
  `
## Sass Usage

\`\`\`
// In .scss file...

${sass.trim()}
\`\`\`

---

## JS Usage

\`\`\`
// In .js file...

${js.trim()}
\`\`\`

---

## HTML Usage

\`\`\`
// In .html file...

${html.trim()}
\`\`\`
`.trim()
