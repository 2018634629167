import s from './List.scss'

const List = ({ items, fallback, render }) => {
  if (!items.length) {
    return <section className={s.fallback}>{fallback}</section>
  }

  return (
    <ul>
      {items.map((item, idx) => {
        return <li key={idx}>{render(item, idx)}</li>
      })}
    </ul>
  )
}

export default List
