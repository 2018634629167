import FilterValues from './FilterValues'
import s from './EditableFieldFilter.scss'

const AvailableValuesList = ({
  options,
  onSearch,
  term,
  value,
  values,
  setValue,
  optionFormat,
  isMulti,
  isReadOnly
}) => (
  <div className={s.filterWrapper}>
    <h2 className={s.filterTitle}>Available Values</h2>
    <FilterValues
      options={options}
      selectedValue={value}
      selectedValues={values}
      setSelectedValue={setValue}
      searchTerm={term}
      onSearch={onSearch}
      noOptionsTitle="All values have been selected."
      noOptionsText="If additional global values are added, they will automatically be added to the selected values."
      optionFormat={optionFormat}
      isMulti={isMulti}
      isReadOnly={isReadOnly}
      labelPrefix="available-values"
    />
  </div>
)

export default AvailableValuesList
