import { Component, CSSProperties } from 'react'
import { OutsideClickContainer, Dropdown } from 'simple-core-ui'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

type Value = string | number
interface Option {
  value: Value
  label: string
}
interface Props {
  dataSet: Array<Option>
  selectedValue?: Value
  shouldReset?: boolean
  disabled?: boolean
  className?: string
  styles?: CSSProperties
  cb?(value: Value): void
}

interface State {
  isExpanded: boolean
  selectedOption: Option | undefined
}

class DropdownContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isExpanded: false,
      selectedOption: this.findSelectedOption(props.selectedValue) || this.getDefaultOption()
    }
  }

  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.shouldReset) {
      return { selectedOption: nextProps.dataSet[1] }
    } else {
      return null
    }
  }

  findSelectedOption(value: Value | undefined) {
    return this.props.dataSet.find(el => el.value === value)
  }
  getDefaultOption() {
    return this.props.dataSet[1]
  }
  expandDropdown = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }))
  }
  closeDropdown = () => {
    this.setState({ isExpanded: false })
  }
  updateOption = (value: Value) => {
    const newSelectedOption = this.findSelectedOption(value)
    this.setState(
      () => ({
        isExpanded: false,
        selectedOption: newSelectedOption
      }),
      () => {
        this.props.cb && this.props.cb((this.state.selectedOption as Option).value)
      }
    )
  }
  render() {
    return (
      <OutsideClickContainer closeComponent={this.closeDropdown}>
        <Dropdown
          {...this.props}
          {...this.state}
          updateOption={this.updateOption}
          expandDropdown={this.expandDropdown}
        />
      </OutsideClickContainer>
    )
  }
}

registration.register({
  name: 'DropdownContainer',
  description:
    'A basic dropdown component that can be used in place of a static html <select /> element.',
  props: [
    {
      name: 'dataSet',
      type: 'Array<{label: string, value: string | number}>',
      note: 'The options shown to be selected in the dropdown.'
    },
    {
      name: 'selectedValue',
      optional: true,
      type: 'string | number',
      note: 'The string/numeric value of the option which is selected.'
    },
    {
      name: 'cb',
      type: 'function --- (selectedValue) => void',
      note:
        'The callback which will be triggered when a value has been selected. The numeric value is provided to the callback.'
    },
    {
      name: 'shouldReset',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop which sets the selected value shown to the first item in the dataSet and resets the dropdown.'
    },
    {
      name: 'disabled',
      optional: true,
      type: 'boolean',
      note: 'If true it will render the dropdown in a disabled state (readonly).'
    },
    {
      name: 'styles',
      optional: true,
      type: 'Object',
      note: 'Add custom styles to the container of the Dropdown.'
    },
    {
      name: 'className',
      optional: true,
      type: 'string',
      note: 'Custom styling via css class.'
    }
  ],
  example: {
    literal: `
<DropdownContainer
  styles={{ maxWidth: '250px', margin: 'auto' }}
  dataSet={[
    {label: 'Javascript', value: 1},
    {label: 'Python', value: 2},
    {label: 'Bash', value: 3},
    {label: 'Ruby', value: 4},
    {label: 'Rust', value: 5}
  ]}
  cb={value => { console.log('You selected dropdown value: ' + value) }} // eslint-disable-line
/>`.trim(),
    render: () => (
      <DropdownContainer
        styles={{ maxWidth: '250px', margin: 'auto' }}
        dataSet={[
          { label: 'Javascript', value: 1 },
          { label: 'Python', value: 2 },
          { label: 'Bash', value: 3 },
          { label: 'Ruby', value: 4 },
          { label: 'Rust', value: 5 }
        ]}
        cb={value => {
          // eslint-disable-next-line no-console
          console.log('You selected dropdown value: ' + value)
        }} // eslint-disable-line
      />
    )
  },
  category: CATEGORY.FORM,
  path: 'containers/Core/DropdownContainer/DropdownContainer'
})

export default DropdownContainer
