import s from './TableHead.scss'
import cn from 'classnames'

const TableColumnActions = ({ alwaysShowActions, pinActions, style }) => (
  <th
    className={cn(s.actions, {
      [s.sticky]: pinActions
    })}
    style={style}
  >
    {alwaysShowActions ? 'Actions' : ''}
  </th>
)

export default TableColumnActions
