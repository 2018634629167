const EmailFolderIcon = params => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      style={{ verticalAlign: 'middle' }}
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#000" transform="translate(-130 -413)">
          <g transform="translate(131 414)">
            <g fill="#FFF">
              <path d="M11.167 3.667c-.414 0-.791-.262-.976-.676L9.157.676C8.972.262 8.595 0 8.182 0H1.09C.488 0 0 .547 0 1.222v19.556C0 21.453.488 22 1.09 22h21.82c.602 0 1.09-.547 1.09-1.222V4.888c0-.674-.488-1.221-1.09-1.221H11.166z"></path>
            </g>
            <g strokeLinecap="round" strokeLinejoin="round" transform="translate(11 8)">
              <circle cx="8" cy="8" r="7.667" fill="#FFF"></circle>
              <path
                strokeWidth="0.8"
                d="M12.333 6v4.333a.667.667 0 01-.666.667H4.333a.667.667 0 01-.666-.667V6"
              ></path>
              <path
                strokeWidth="0.8"
                d="M12.333 6v-.333A.667.667 0 0011.667 5H4.333a.667.667 0 00-.666.667V6L7.47 8.169a1 1 0 001.06 0L12.333 6z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EmailFolderIcon
