/* eslint-disable no-console */
import { IoIosClose, IoIosArrowBack, IoIosMail } from 'react-icons/io'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import { HoverAction } from 'simple-core-ui'

import s from './Actionable.scss'

const Actionable = ({ actions, alignment, children, style, actionsStyle }) => (
  <section className={s.container} style={style}>
    <section className={s.actions} style={actionsStyle}>
      {actions.map((action, idx) => (
        <span className={s.action} key={idx}>
          {action}
        </span>
      ))}
    </section>
    {children}
  </section>
)

Actionable.defaultProps = {
  alignment: 'top',
  style: {},
  actionsStyle: {}
}

registration.register({
  name: 'Actionable',
  description:
    'A utility component that will wrap a given component and properly place the provided actionable components. HoverAction component should be provided in most cases.',
  props: [
    {
      name: 'actions',
      type: 'Array<React.Node>',
      note:
        'The actionable components that should be rendered. Should use HoverAction component in most cases.'
    },
    {
      name: 'alignment',
      type: 'top | center',
      optional: true,
      defaultValue: 'top',
      note: 'The alignment of the actionable components.'
    },
    {
      name: 'children',
      type: 'React.Node',
      note: 'The component that is decorated with the actionable components.'
    },
    {
      name: 'style',
      type: 'CSSStyleDeclaration',
      optional: true,
      defaultValue: '{}',
      note: 'Custom styles for the Actionable wrapper.'
    },
    {
      name: 'actionsStyles',
      type: 'CSSStyleDeclaration',
      optional: true,
      defaultValue: '{}',
      note:
        'Custom styles for the actionable icons container. Should be used in rare cases (i.e custom alignment is required).'
    }
  ],
  example: {
    literal: '',
    render: () => (
      <Actionable
        actions={[
          <HoverAction
            key={1}
            onClick={() => console.log('Back action clicked!')}
            tip="Send Back"
            icon={<IoIosArrowBack />}
          />,
          <HoverAction
            key={2}
            onClick={() => console.log('Email action clicked!')}
            tip="Email"
            icon={<IoIosMail />}
          />,
          <HoverAction
            key={0}
            onClick={() => console.log('Delete action clicked!')}
            tip="Delete"
            icon={<IoIosClose />}
          />
        ]}
        style={{
          maxWidth: '800px',
          margin: 'auto'
        }}
      >
        <h3
          style={{
            border: '1px solid #a9a9a9',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            height: '300px'
          }}
        >
          Hover Me 😎
        </h3>
      </Actionable>
    )
  },
  category: CATEGORY.LAYOUT,
  path: 'components/Utils/Actionable/Actionable'
})

export default Actionable
