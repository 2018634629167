import { Component } from 'react'
import { If, Badge } from 'simple-core-ui'
import classNames from 'classnames'
import s from './Tabs.scss'

export const Tab = ({ selected, onClick, header, count }) => {
  return (
    <li className={`${s.tab} ${selected ? s.selected : ''}`} title={header} onClick={onClick}>
      <If condition={selected}>
        <hr className={classNames(s.selectedLine, s.selectedLineOn)} />
      </If>
      {count !== undefined && <Badge asPill className={s.pill} content={String(count)} size="sm" />}
      {header}
    </li>
  )
}

Tab.defaultProps = {
  isVisible: true
}

class Tabs extends Component {
  state = {
    selectedIndex: this.props.selectedIndex || 0
  }

  clickTab = index => {
    this.setState({
      selectedIndex: index
    })
  }

  render() {
    const {
      children,
      alignRight,
      selectedIndex: propsSelectedIndex,
      headerClassName = ''
    } = this.props
    const { selectedIndex: stateSelectedIndex } = this.state
    const selectedIndex = Number.isInteger(propsSelectedIndex)
      ? propsSelectedIndex
      : stateSelectedIndex

    return (
      <div>
        <ul className={classNames(s.tabs, headerClassName, { [s.alignRight]: alignRight })}>
          {children
            .filter(Boolean)
            .flat()
            .filter(c => c.props?.isVisible)
            .map((child, index) => {
              return {
                ...child,
                props: {
                  selected: selectedIndex === index,
                  onClick: () => {
                    this.clickTab(index)
                    child.props.onClickCb && child.props.onClickCb()
                  },
                  ...child.props
                }
              }
            })}
        </ul>
        {children
          .flat()
          .filter(c => c.props?.isVisible)
          .map((child, index) => {
            if (selectedIndex !== index) return null
            return (
              <div className={s.container} key={index}>
                {child.props.children}
              </div>
            )
          })}
      </div>
    )
  }
}

export default Tabs
