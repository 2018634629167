import s from '../Table.scss'

const TableRowBorder = ({ color }) => (
  <td className={s.borderRight}>
    <span style={{ backgroundColor: color }} />
  </td>
)

TableRowBorder.defaultProps = {
  color: 'black'
}

export default TableRowBorder
