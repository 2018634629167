import { DESIGN } from 'simple-core-ui'

import registration from '../registration'
import { CATEGORY } from '../constants'
import { getDesignValues, usageMarkdown } from '../helpers'
import DescriptionList from '../DescriptionList/DescriptionList'

const { lineHeight, spacing } = DESIGN

const LOREM = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras finibus libero odio, non laoreet quam laoreet et. Aenean lacinia iaculis pretium. Maecenas aliquet tempor ligula sit amet malesuada. Proin efficitur, augue vel semper mattis, ante nibh venenatis ipsum, sed bibendum ligula tellus sit amet lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Phasellus justo erat, consequat in nisl id, aliquet vehicula massa. Donec in egestas lacus. Praesent non dictum diam, hendrerit imperdiet est.
`.trim()

const descriptionText = {
  lnHt100:
    'No spacing between lines, useful for cases where you do not want line-height to affect height of an element.',
  lnHt200: 'Tight spacing between lines for dense bodies of text.',
  lnHt300: 'Standard breathable spacing for content.',
  lnHt400: 'A fair amount of breathing room for longer bodies of text.'
}

const descriptions = getDesignValues(lineHeight).reduce(
  (acc, [name, value]) => ({
    ...acc,
    [name]: {
      text: descriptionText[name],
      render: v => <p style={{ lineHeight: value, maxWidth: spacing.spacing1600 }}>{LOREM}</p>
    }
  }),
  {}
)

const sass = `
.p {
  line-height: $lnHt200;
}
`

const js = `
import { DESIGN } from 'simple-core-ui'

const { lineHeight: { lnHt200 } } = DESIGN

const Paragraph = () => <p style={{lineHeight: lnHt300}}>...</p>
`

const html = '<p class="lnHt300">...</p>'

export default registration.register({
  name: 'Line Height',
  description: 'SimpleLegal Design System Line Heights',
  example: {
    render: () => (
      <DescriptionList pairs={getDesignValues(lineHeight)} descriptions={descriptions} />
    )
  },
  markdown: usageMarkdown({ sass, js, html }),
  category: CATEGORY.SYSTEM
})
