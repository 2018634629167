import AvailableValuesList from './AvailableValuesList'
import SelectedValuesList from './SelectedValuesList'
import ValuesMover from './ValuesMover'
import s from './EditableFieldFilter.scss'

const EditableFieldFilter = ({
  availableOptions,
  selectedOptions,
  onLeftSearch,
  onRightSearch,
  leftSearchTerm,
  rightSearchTerm,
  leftSelectedOption,
  rightSelectedOption,
  leftSelectedOptions,
  rightSelectedOptions,
  setLeftSelectedValue,
  setRightSelectedValue,
  moveAvailable2Selected,
  moveSelected2Available,
  moveAllAvailable2Selected,
  moveAllSelected2Available,
  style,
  optionFormat,
  isReadOnly,
  isMulti
}) => (
  <div style={style} className={s.filterContainer}>
    <div className={s.filterContent}>
      <AvailableValuesList
        options={availableOptions}
        onSearch={onLeftSearch}
        term={leftSearchTerm}
        value={leftSelectedOption}
        values={leftSelectedOptions}
        setValue={setLeftSelectedValue}
        optionFormat={optionFormat}
        isMulti={isMulti}
        isReadOnly={isReadOnly}
      />
      <ValuesMover
        leftSelectedOption={leftSelectedOption}
        rightSelectedOption={rightSelectedOption}
        moveAvailable2Selected={moveAvailable2Selected}
        moveSelected2Available={moveSelected2Available}
        moveAllAvailable2Selected={moveAllAvailable2Selected}
        moveAllSelected2Available={moveAllSelected2Available}
        isReadOnly={isReadOnly}
      />
      <SelectedValuesList
        options={selectedOptions}
        onSearch={onRightSearch}
        term={rightSearchTerm}
        value={rightSelectedOption}
        values={rightSelectedOptions}
        setValue={setRightSelectedValue}
        optionFormat={optionFormat}
        isMulti={isMulti}
        isReadOnly={isReadOnly}
      />
    </div>
  </div>
)

export default EditableFieldFilter
