import s from './FlexyThird.scss'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const FlexyThird = ({ children }) => <section className={s.container}>{children}</section>

registration.register({
  name: 'FlexyThird',
  description:
    'A utility component used for layout that will evenly split child components into three even sections with added styling for proper wrapping.',
  props: [
    {
      name: 'children',
      optional: false,
      type: 'React Nodes',
      note: 'The component expects three or less react nodes as children.'
    }
  ],
  example: {
    literal: `
<FlexyThird>
  <div style={{background: 'mediumseagreen', height: '100px'}} />
  <div style={{background: 'palevioletred', height: '100px'}} />
  <div style={{background: 'mediumaquamarine', height: '100px'}} />
</FlexyThird>
    `.trim(),
    render: () => (
      <FlexyThird>
        <div style={{ background: 'mediumseagreen', height: '100px' }} />
        <div style={{ background: 'palevioletred', height: '100px' }} />
        <div style={{ background: 'mediumaquamarine', height: '100px' }} />
      </FlexyThird>
    )
  },
  category: CATEGORY.LAYOUT,
  path: 'components/Utils/FlexyThird/FlexyThird'
})

export default FlexyThird
