import * as React from 'react'

import { Collapsible, KeyValuePairs } from 'simple-core-ui'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

class CollapsibleContainer extends React.Component {
  static defaultProps = {
    startCollapsed: true
  }

  state = {
    isCollapsed: this.props.startCollapsed
  }

  toggleCollapsed = () => {
    this.setState(prevState => ({
      isCollapsed: !prevState.isCollapsed
    }))
  }

  render() {
    const { isCollapsed } = this.state
    const { title, children, style, titleClassName, contentClassName } = this.props

    return (
      <Collapsible
        title={title}
        isCollapsed={isCollapsed}
        toggleCollapsed={this.toggleCollapsed}
        style={style}
        titleClassName={titleClassName}
        contentClassName={contentClassName}
      >
        {children}
      </Collapsible>
    )
  }
}

registration.register({
  name: 'CollapsibleContainer',
  description:
    'This component can be used for any UI that relies on optional expansion of details.',
  props: [
    {
      name: 'title',
      type: 'React.Node',
      note:
        'The component/text that will always be shown at the top of the expanded/collapsed section.'
    },
    {
      name: 'startCollapsed',
      optional: true,
      defaultValue: 'true',
      type: 'boolean',
      note: 'If true the component will mount in a collapsed state.'
    },
    {
      name: 'children',
      type: 'React.Node',
      note: 'The content to show when the component is expanded.'
    },
    {
      name: 'style',
      optional: true,
      type: 'Object',
      defaultValue: '{}',
      note: 'The styling to be applied to the container around the pairs.'
    }
  ],
  example: {
    literal: `
<CollapsibleContainer
  title='Author'
>
  <KeyValuePairs
    pairs={[
      { key: 'First Name', value: 'Ian' },
      { key: 'Last Name', value: 'Jabour' },
      { key: 'Position', value: 'Intern' },
      { key: 'DOB', value: '10/12/1991' },
      { key: 'Days Active', value: '42' },
      { key: 'Permission', value: 'Read-only', style: {opacity: 0.7} }
    ]}
    pairsInline
    stackedKeys
  />
</CollapsibleContainer>`.trim(),
    render: () => (
      <CollapsibleContainer title="Author">
        <KeyValuePairs
          pairs={[
            { key: 'First Name', value: 'Ian' },
            { key: 'Last Name', value: 'Jabour' },
            { key: 'Position', value: 'Intern' },
            { key: 'DOB', value: '10/12/1991' },
            { key: 'Days Active', value: '42' },
            { key: 'Permission', value: 'Read-only', style: { opacity: 0.7 } }
          ]}
          pairsInline
          stackedKeys
        />
      </CollapsibleContainer>
    )
  },
  category: CATEGORY.LAYOUT,
  path: 'containers/Utils/CollapsibleContainer/CollapsibleContainer'
})

export default CollapsibleContainer
