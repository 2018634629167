import classNames from 'classnames'
import isNull from 'lodash/isNull'
import { IoIosMail } from 'react-icons/io'

import { Anchor, If } from 'simple-core-ui/components'

import s from './Xeditable.scss'

const XeditableLink = ({
  children,
  withoutUnderline,
  asPlaceholder,
  shareLink,
  openLinkInNewTab,
  shareIcon,
  readOnly
}) => (
  <span className={classNames(s.linkContainer, { [s.readOnly]: readOnly })}>
    <span
      className={classNames({
        [s.link]: !withoutUnderline && !readOnly,
        [s.withoutUnderline]: withoutUnderline && !readOnly,
        [s.asPlaceholder]: asPlaceholder && !readOnly,
        [s.readOnly]: readOnly
      })}
    >
      <If condition={asPlaceholder && readOnly} fallback={children}>
        ----
      </If>
    </span>
    <If condition={Boolean(shareLink) && !isNull(shareIcon)}>
      <span className={s.shareLink}>
        <Anchor
          url={shareLink}
          style={{
            display: 'inline-block',
            marginLeft: '0.5em',
            borderBottom: 'none'
          }}
          asNewTab={openLinkInNewTab}
        >
          {shareIcon}
        </Anchor>
      </span>
    </If>
  </span>
)

XeditableLink.defaultProps = {
  shareIcon: <IoIosMail />,
  readOnly: false
}

export default XeditableLink
