import { Component } from 'react'
import { Modal } from 'simple-core-ui'
import axios from 'axios'
import { makeGetRequest } from 'utils/api'

class SessionExpiration extends Component {
  static defaultProps = {
    sessionTime: 28800,
    refreshUrl: ''
  }

  constructor(props) {
    super(props)
    this.state = {
      isSessionModalOpen: false
    }
  }

  componentDidMount = () => {
    this.timer = setTimeout(this.showSessionModal, this.props.sessionTime)
    axios.interceptors.request.use(
      config => {
        this.resetTimer()
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  resetTimer = () => {
    const { sessionTime } = this.props
    clearTimeout(this.timer)
    this.timer = setTimeout(this.showSessionModal, sessionTime)
  }

  showSessionModal = () => {
    this.setState({
      isSessionModalOpen: true
    })
  }

  refreshCurrentSession = () => {
    const { refreshUrl } = this.props
    if (!refreshUrl) {
      window.location.reload()
    }

    makeGetRequest(refreshUrl).catch(error => {
      if (error.response?.status == 403) {
        window.location.reload()
      }
    })
    this.resetTimer()
    this.setState({
      isSessionModalOpen: false
    })
  }

  render() {
    return (
      <Modal
        size="sm"
        isVisible={this.state.isSessionModalOpen}
        title="Session Expiration"
        confirmText="Refresh Session"
        hasCloseIcon={false}
        confirmCb={this.refreshCurrentSession}
        content={
          <div>
            <p>
              <strong>Your current session is about to expire.</strong>
            </p>
            <p>
              You will be logged out and forced to sign in again. Please click the button below to
              refresh your session.
            </p>
          </div>
        }
      />
    )
  }
}

export default SessionExpiration
