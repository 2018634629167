import { useState } from 'react'
import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'
import s from './ColorPicker.scss'
import { RGBColor, ChromePicker } from 'react-color'

type Color = string | RGBColor

interface Props {
  initialColor?: string
  onChange(color: Color): void
  allowTransparency?: boolean
}

const defaultColor = '#bbdefb'

const ColorPicker = ({ initialColor, onChange, allowTransparency = false }: Props) => {
  const [color, setColor] = useState<Color>(initialColor || defaultColor)

  return (
    <div className={s.blockContainer}>
      <ChromePicker
        styles={{ default: { picker: { boxShadow: 'none' } } }}
        disableAlpha={!allowTransparency}
        color={color}
        onChangeComplete={color => {
          const c = allowTransparency ? color.rgb : color.hex
          setColor(c)
          onChange(c)
        }}
      />
    </div>
  )
}

registration.register({
  name: 'ColorPicker',
  description:
    'A component that can be used as a color picker to select a color from a list of colors.',
  props: [
    {
      name: 'initialColor',
      type: 'string',
      note: 'The color initially selected.'
    },
    {
      name: 'allowTransparency',
      type: 'boolean',
      note: 'Should the color picker allow transparency?'
    },
    {
      name: 'onChange',
      type: '(color: string) => void',
      note: 'The function to be called when the selected color is changed.'
    }
  ],
  example: {
    literal: `
<section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
  <div style={{ textAlign: 'center' }}>
    <div
      id="colorDisplay"
      style={{
        width: '24px',
        height: '24px',
        backgroundColor: color,
        display: 'inline-block',
        marginBottom: '24px'
      }}
    ></div>
    <ChromePicker
      disableAlpha={!allowTransparency}
      color={color}
      onChangeComplete={color => {
        setColor(color.hex)
        document.getElementById('colorDisplay').style.backgroundColor = color
      }}
    />
  </div>
</section>`.trim(),
    render: () => {
      const color = '#bbdefb'
      return (
        <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <div
              id="colorDisplay"
              style={{
                width: '24px',
                height: '24px',
                backgroundColor: color,
                display: 'inline-block',
                marginBottom: '24px'
              }}
            ></div>
            <ChromePicker
              disableAlpha
              color={color}
              onChangeComplete={color => {
                const element = document.getElementById('colorDisplay') as HTMLElement
                element.style.backgroundColor = color.hex
              }}
            />
          </div>
        </section>
      )
    }
  },
  category: CATEGORY.MISC,
  path: 'components/Core/ColorPicker/ColorPicker'
})

export default ColorPicker
