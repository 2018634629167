import registration from '../registration'
import { CATEGORY } from '../constants'
import s from './icons.scss'
import {
  FaTrash,
  FaArchive,
  FaListUl,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaExclamationTriangle,
  FaPencilAlt,
  FaAngleRight,
  FaAngleLeft,
  FaList,
  FaCheck,
  FaTimes,
  FaChevronRight,
  FaChevronDown,
  FaDownload,
  FaPlus,
  FaInfoCircle,
  FaEnvelope,
  FaPen,
  FaAngleDown,
  FaStar,
  FaPlusCircle,
  FaMinusCircle,
  FaFolder,
  FaCheckCircle,
  FaSync,
  FaTwitter,
  FaExpandAlt,
  FaCompressAlt,
  FaCircle,
  FaExclamationCircle,
  FaClone,
  FaRegClone
} from 'react-icons/fa'
import {
  IoIosInformationCircle,
  IoIosCog,
  IoMdClose,
  IoMdCheckmark,
  IoIosDownload,
  IoIosArrowDown,
  IoIosSend,
  IoIosSave,
  IoIosTrash,
  IoIosCopy,
  IoIosMore,
  IoIosLock,
  IoIosClose,
  IoIosInformationCircleOutline,
  IoIosArrowForward,
  IoIosAlert,
  IoIosArrowBack,
  IoIosMail,
  IoMdPhonePortrait,
  IoMdShareAlt,
  IoIosMenu,
  IoMdAdd,
  IoIosSearch,
  IoIosFunnel,
  IoIosArrowUp,
  IoMdHelp,
  IoIosUndo,
  IoIosRedo,
  IoIosFlag,
  IoIosAttach,
  IoIosBriefcase,
  IoMdHelpCircleOutline,
  IoIosArrowRoundForward,
  IoIosWarning,
  IoIosAddCircleOutline,
  IoIosResize
} from 'react-icons/io'
import { IoTrashOutline, IoWarningOutline } from 'react-icons/io5'
import { MdBugReport, MdOutlineEvent, MdOutlineEventAvailable } from 'react-icons/md'
import { TbAlertCircle, TbCopy } from 'react-icons/tb'
import {
  AppleCalendarIcon,
  AssessmentOutlinedIcon,
  Budgets,
  CsvDownloadIcon,
  CsvFileIcon,
  CsvUploadIcon,
  Document,
  Download,
  EmailFolderIcon,
  FileIcon,
  FolderIcon,
  GoogleCalendarIcon,
  Invoices,
  LawRuleIcon,
  LegalRequests,
  Matters,
  MicrosoftOfficeOutlookIcon,
  PeopleImage,
  StarryIcon,
  TagIcon,
  UsersIcon,
  Vendors,
  WebFormIcon,
  XlsDownloadIcon,
  XlsFileIcon,
  XlsUploadIcon
} from '../../icons'

const libraryUrl = 'https://react-icons.github.io/react-icons/'

export default registration.register({
  name: 'Icons',
  description: (
    <>
      SimpleLegal Design System Icons (if you need something else, please try to find it here:
      <a href={libraryUrl} target="_blank" rel="noreferrer">
        {` react-icons `}
      </a>
      )
    </>
  ),
  example: {
    literal: `
<>
  <FaTrash style={{ marginRight: '30px', marginBottom: '30px', fontSize: '3em' }}/>
  <FaArchive style={{ marginRight: '30px', marginBottom: '30px', fontSize: '3em' }}/>
  <FaListUl style={{ marginRight: '30px', marginBottom: '30px', fontSize: '3em' }}/>
  ...
</>`.trim(),
    render: theme => {
      const Flex = ({ direction = 'row', title, children }) => (
        <>
          {title && <div style={{ marginBottom: '10px' }}>{title}</div>}
          <section
            style={{
              display: 'flex',
              flexFlow: `${direction} wrap`,
              padding: '0.5em',
              color: '#3C99FD',
              backgroundColor: theme === 'cg' ? 'black' : 'transparent'
            }}
          >
            {children}
          </section>
        </>
      )
      const renderIcons = icons =>
        icons.map((Icon, index) => (
          <Icon
            key={index}
            style={{ marginRight: '30px', marginBottom: '30px', fontSize: '3em' }}
          />
        ))

      return (
        <Flex direction="column">
          <Flex
            title={
              <a href={`${libraryUrl}icons?name=fa`} target="_blank" rel="noreferrer">
                Font Awesome
              </a>
            }
          >
            {renderIcons([
              FaTrash,
              FaArchive,
              FaListUl,
              FaAngleDoubleRight,
              FaAngleDoubleLeft,
              FaExclamationTriangle,
              FaPencilAlt,
              FaAngleRight,
              FaAngleLeft,
              FaAngleDown,
              FaList,
              FaCheck,
              FaTimes,
              FaChevronRight,
              FaChevronDown,
              FaDownload,
              FaPlus,
              FaEnvelope,
              FaPen,
              FaStar,
              FaInfoCircle,
              FaPlusCircle,
              FaMinusCircle,
              FaCheckCircle,
              FaCircle,
              FaExclamationCircle,
              FaFolder,
              FaSync,
              FaTwitter,
              FaExpandAlt,
              FaCompressAlt,
              FaClone,
              FaRegClone
            ])}
          </Flex>
          <Flex
            title={
              <a href={`${libraryUrl}icons?name=io`} target="_blank" rel="noreferrer">
                Icons 4
              </a>
            }
          >
            {renderIcons([
              IoIosInformationCircle,
              IoIosInformationCircleOutline,
              IoIosAddCircleOutline,
              IoMdHelpCircleOutline,
              IoIosAlert,
              IoIosCog,
              IoMdClose,
              IoMdCheckmark,
              IoIosDownload,
              IoIosArrowDown,
              IoIosArrowForward,
              IoIosArrowBack,
              IoIosArrowUp,
              IoIosArrowRoundForward,
              IoIosSend,
              IoIosSave,
              IoIosTrash,
              IoIosCopy,
              IoIosMore,
              IoIosLock,
              IoIosClose,
              IoIosMail,
              IoMdPhonePortrait,
              IoMdShareAlt,
              IoIosMenu,
              IoMdAdd,
              IoIosSearch,
              IoIosFunnel,
              IoMdHelp,
              IoIosUndo,
              IoIosRedo,
              IoIosFlag,
              IoIosAttach,
              IoIosBriefcase,
              IoIosWarning,
              IoIosResize
            ])}
          </Flex>
          <Flex
            title={
              <a href={`${libraryUrl}icons?name=io5`} target="_blank" rel="noreferrer">
                Icons 5
              </a>
            }
          >
            {renderIcons([IoTrashOutline, IoWarningOutline])}
          </Flex>
          <Flex
            title={
              <a href={`${libraryUrl}icons?name=tb`} target="_blank" rel="noreferrer">
                Tabler Icons
              </a>
            }
          >
            {renderIcons([TbAlertCircle, TbCopy])}
          </Flex>
          <Flex
            title={
              <a href={`${libraryUrl}icons?name=md`} target="_blank" rel="noreferrer">
                Material Design Icons
              </a>
            }
          >
            {renderIcons([MdBugReport, MdOutlineEvent, MdOutlineEventAvailable])}
          </Flex>
          <Flex title={<div>Locally saved Icons</div>}>
            {[
              AppleCalendarIcon,
              AssessmentOutlinedIcon,
              Budgets,
              Document,
              Download,
              EmailFolderIcon,
              FileIcon,
              FolderIcon,
              GoogleCalendarIcon,
              Invoices,
              LegalRequests,
              Matters,
              MicrosoftOfficeOutlookIcon,
              TagIcon,
              UsersIcon,
              Vendors
            ].map((Icon, index) => (
              <div key={index} className={s.localIcons}>
                <Icon />
              </div>
            ))}
            {[StarryIcon].map((Icon, index) => (
              <Icon key={index} />
            ))}
            {<LawRuleIcon style={{ scale: '0.5', position: 'relative', top: '-24px' }} />}
          </Flex>
          <Flex title={<div>Locally saved .svg Icons</div>}>
            {[
              CsvDownloadIcon,
              CsvFileIcon,
              CsvUploadIcon,
              PeopleImage,
              WebFormIcon,
              XlsDownloadIcon,
              XlsFileIcon,
              XlsUploadIcon
            ].map((iconPath, index) => (
              <img src={iconPath} className={s.localIcons} key={index} />
            ))}
          </Flex>
        </Flex>
      )
    }
  },
  category: CATEGORY.SYSTEM
})
