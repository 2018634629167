const Tag = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 24">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path d="M23 14.516a1 1 0 010 1.414L15.928 23a1 1 0 01-1.414 0L1.007 9.494a1 1 0 01-.293-.707L.707 1.708a1 1 0 011-1h7.077A1 1 0 019.492 1L23 14.516z"></path>
        <circle cx="5.707" cy="5.707" r="1.516"></circle>
      </g>
    </svg>
  )
}

export default Tag
