const Budgets = () => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path
        className="st0"
        d="M119 201.9v-11.5c0-1.9-1.5-3.4-3.4-3.6-9.6-.7-18.9-3.4-25.3-6.9-1.3-.7-1.9-2.3-1.5-3.8.8-2.8 2-7.1 2-7.1 1.3-4.5 5.5-6.5 9.1-4.9 6.6 2.8 14.5 4.9 22.9 4.9 12 0 20.2-5.9 20.2-15 0-8.6-6.7-14.1-20.7-19.2-20-7.2-32.9-16.4-32.9-34.1 0-15.5 10.3-27.6 27.8-31.8 1.6-.4 2.8-1.8 2.8-3.5V53.2c0-2 1.6-3.6 3.6-3.6h8.4c2 0 3.6 1.6 3.6 3.6v10.7c0 1.9 1.5 3.4 3.4 3.6 8.5.6 14.9 2.5 20 4.8 1.7.7 2.5 2.6 2 4.3l-2 6.7c-1.3 4.3-5.3 6.3-8.9 4.9-4.7-1.8-11.1-3.6-19.2-3.6-13.3 0-18.1 6.8-18.1 13.3 0 7.8 6.9 12.2 23 18.6 21.3 8 30.8 18.3 30.8 35.2 0 15.4-10.2 29.1-29.3 33.3-1.7.4-2.9 1.8-2.9 3.5v13.1c0 2-1.6 3.6-3.6 3.6h-8.4c-1.8.3-3.4-1.3-3.4-3.3z"
      />
      <path
        className="st0"
        d="M177.8 10.1c15.8 6.8 29.5 15.8 40.9 27.2 11.4 11.4 20.5 25 27.2 40.9C252.7 94 256 110.6 256 128c0 17.3-3.4 33.9-10.1 49.8-6.8 15.8-15.8 29.5-27.2 40.9-11.4 11.4-25 20.5-40.9 27.2S145.3 256 128 256c-23.2 0-44.6-5.7-64.2-17.1-19.7-11.4-35.2-27-46.6-46.6C5.7 172.6 0 151.2 0 128c0-23.2 5.7-44.6 17.1-64.2 11.4-19.7 27-35.2 46.6-46.6C83.4 5.7 104.8 0 128 0c17.3 0 33.9 3.4 49.8 10.1zm-6.3 221.3c13.8-5.9 25.8-13.9 35.8-24 10-10.1 18-22 23.9-35.9 5.9-13.8 8.9-28.3 8.9-43.5s-3-29.7-8.9-43.5-13.9-25.8-23.9-35.8-21.9-18-35.8-23.9C157.7 19 143.2 16 128 16s-29.7 3-43.5 8.9-25.8 13.9-35.8 23.9-18 21.9-23.9 35.8C19 98.3 16 112.8 16 128c0 12.2 1.9 24 5.6 35.4 3.8 11.4 9.1 21.7 16 30.9s15 17.2 24.1 24.2 19.5 12.4 30.9 16.1 23.2 5.6 35.4 5.6c15.2 0 29.7-2.9 43.5-8.8z"
      />
    </svg>
  )
}

export default Budgets
