import md5 from 'blueimp-md5'
import ArtworkGenerator from 'identicon.js'
import noop from 'lodash/noop'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import { If } from 'simple-core-ui/components'

import s from './Identicon.scss'

const generateArtwork = (email, color, size, isInverted) => {
  const hash = md5(email)
  const config = {
    foreground: isInverted ? [255, 255, 255] : color,
    background: isInverted ? color : [255, 255, 255],
    margin: 0.2,
    size: size,
    format: 'svg'
  }
  const encodedArtwork = new ArtworkGenerator(hash, config).toString()
  const backgroundImage = `url(data:image/svg+xml;base64,${encodedArtwork})`
  const border = isInverted ? { border: 'none' } : {}

  return {
    width: size,
    height: size,
    backgroundImage,
    ...border
  }
}

const Identicon = ({
  email,
  backgroundImage,
  color,
  size,
  onClick,
  overlayText,
  isInverted,
  isLoading,
  style
}) => {
  const stylesToRender = isLoading
    ? { width: `${size}px`, height: `${size}px` }
    : Boolean(backgroundImage)
    ? { backgroundImage: `url(${backgroundImage})`, width: `${size}px`, height: `${size}px` }
    : generateArtwork(email, color, size, isInverted)

  return (
    <section
      className={s.container}
      style={{
        ...stylesToRender,
        ...style
      }}
      onClick={onClick}
    >
      <If condition={overlayText}>
        <span className={s.overlayText}>{overlayText}</span>
      </If>
    </section>
  )
}

Identicon.defaultProps = {
  color: [114, 116, 121],
  size: 160,
  onClick: noop,
  style: {},
  isInverted: false
}

registration.register({
  name: 'Identicon',
  description:
    'A component that serves as a unique identifier for users without a profile picture. If a background image is provided it will render that instead of the artwork.',
  props: [
    { name: 'email', type: 'string', note: '' },
    {
      name: 'backgroundImage',
      type: 'string',
      note:
        'Url to a image to set. If provided it will serve as the image in place of the generated artwork.'
    },
    {
      name: 'color',
      optional: true,
      type: 'Default: [114, 116, 121] - Array<number, number, number>',
      note:
        'An array of rgb values that will be used to set the color of the squares for the artwork.'
    },
    {
      name: 'size',
      optional: true,
      type: 'number',
      note: 'Default: 160 - The size of the image in pixels.'
    },
    {
      name: 'overlayText',
      optional: true,
      type: 'string',
      note: 'Default: "" - If provided, this text will show up along with an overlay on hover.'
    },
    {
      name: 'isInverted',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - Will set the color provided as the background color and have the foreground set to white.'
    },
    {
      name: 'isLoading',
      optional: true,
      type: 'boolean',
      note: 'Default: false - Will set the loading state for the component.'
    },
    {
      name: 'style',
      optional: true,
      type: 'object',
      note: 'Default: {} - The custom styles to be applied to the component container.'
    }
  ],
  example: {
    literal: `
const styles = {display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'space-around'}
const onClick = () => {console.log('Clicked the Identicon...')}

return (
  <section style={styles}>
    <Identicon email='ian@simplelegal.com' color={[54, 169, 225]} overlayText='Overlay Text Here' onClick={onClick} />
    <Identicon email='darren@simplelegal.com' color={[239, 98, 98]} overlayText='Overlay Text Here' onClick={onClick} />
    <Identicon email='zac@simplelegal.com' overlayText='Overlay Text Here' onClick={onClick} />
    <Identicon email='ian@simplelegal.com' color={[54, 169, 225]} overlayText='Overlay Text Here' onClick={onClick} isInverted />
    <Identicon email='darren@simplelegal.com' color={[239, 98, 98]} overlayText='Overlay Text Here' onClick={onClick} isInverted />
    <Identicon email='zac@simplelegal.com' overlayText='Overlay Text Here' onClick={onClick} isInverted />
    <Identicon
      email='ian@simplelegal.com'
      backgroundImage='https://avatars3.githubusercontent.com/u/13607977?v=3&s=460'
      overlayText='Oh yeah!'
      onClick={onClick}
    />
    <Identicon
      email='ian@simplelegal.com'
      isLoading
      overlayText='Loading...'
      onClick={onClick}
    />
  </section>
)`.trim(),
    render: () => {
      const styles = {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'space-around'
      }
      const onClick = () => {
        // eslint-disable-next-line no-console
        console.log('Clicked the Identicon...')
      }

      return (
        <section style={styles}>
          <Identicon
            email="ian@simplelegal.com"
            color={[54, 169, 225]}
            overlayText="Overlay Text Here"
            onClick={onClick}
          />
          <Identicon
            email="ian@simplelegal.com"
            color={[54, 169, 225]}
            overlayText="Overlay Text Here"
            onClick={onClick}
            isInverted
          />
          <Identicon
            email="darren@simplelegal.com"
            color={[239, 98, 98]}
            overlayText="Overlay Text Here"
            onClick={onClick}
          />
          <Identicon
            email="darren@simplelegal.com"
            color={[239, 98, 98]}
            overlayText="Overlay Text Here"
            onClick={onClick}
            isInverted
          />
          <Identicon
            email="zac@simplelegal.com"
            overlayText="Overlay Text Here"
            onClick={onClick}
          />
          <Identicon
            email="zac@simplelegal.com"
            overlayText="Overlay Text Here"
            onClick={onClick}
            isInverted
          />
          <Identicon
            email="ian@simplelegal.com"
            backgroundImage="https://avatars3.githubusercontent.com/u/13607977?v=3&s=460"
            overlayText="Oh yeah!"
            onClick={onClick}
          />
          <Identicon
            email="ian@simplelegal.com"
            isLoading
            overlayText="Loading..."
            onClick={onClick}
          />
        </section>
      )
    }
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Identicon/Identicon'
})

export default Identicon
