import { SVGProps } from 'react'

const Conditional = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" {...props}>
    <rect width={26} height={26} fill="#DCAC00" rx={3} />
    <path
      fill="#fff"
      d="M12.205 23.4v-4.983c0-.798-.14-1.454-.423-1.969-.283-.515-.715-1.046-1.298-1.594l1.139-1.072c.23.183.472.432.728.748.256.315.472.606.649.872.3-.432.596-.806.887-1.121.291-.316.57-.582.834-.798 1.024-.78 1.761-1.723 2.211-2.828.45-1.104.623-2.554.517-4.348L15.065 8.55l-1.112-1.047 4.29-4.036 4.29 4.036-1.112 1.047-2.383-2.243c.088 2.093-.128 3.742-.65 4.946-.52 1.205-1.39 2.33-2.608 3.377-.776.664-1.302 1.27-1.575 1.818-.274.549-.41 1.205-.41 1.969V23.4h-1.59ZM7.121 9.546c-.07-.299-.128-.735-.172-1.308a15.06 15.06 0 0 1-.013-1.906L4.579 8.55 3.467 7.503l4.29-4.036 4.29 4.036-1.113 1.047-2.383-2.243a18.064 18.064 0 0 0-.026 1.657c.017.474.061.885.132 1.234l-1.536.348Zm2.224 4.261c-.3-.299-.63-.693-.993-1.183a5.76 5.76 0 0 1-.834-1.607l1.563-.374c.159.415.37.814.635 1.196s.512.69.742.922l-1.113 1.046Z"
    />
  </svg>
)

export default Conditional
