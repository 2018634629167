const FileIcon = params => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle' }}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#000">
          <g>
            <path d="M21.207 4.5a1 1 0 01.293.707V22.5a1 1 0 01-1 1h-17a1 1 0 01-1-1v-21a1 1 0 011-1h13.293a1 1 0 01.707.293L21.207 4.5zM6 16h12M6 19h12M6 13h12M6 10h12M6 7h7M6 7h7M6 10h12M6 13h12M6 16h12M6 19h12"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FileIcon
