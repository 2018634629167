import * as React from 'react'
import get from 'lodash/get'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

type Props = {
  conditions: Array<{
    condition: boolean
    content: React.ReactNode
  }>
  fallback?: React.ReactNode
}

const ConditionalRender = ({ conditions, fallback = null }: Props) => (
  <React.Fragment>
    {get(
      conditions.find(({ condition }) => condition),
      'content',
      fallback
    )}
  </React.Fragment>
)

registration.register({
  name: 'ConditionalRender',
  description:
    'A utility component that aids in the if / else if / else rendering logic that often becomes chained ternary operators. The idea is that you provide a list of conditions with associated content and the first "truthy" conditional will determine the content rendered. If no conditions are found to be "truthy" then the component will render the fallback prop, which defaults to "null" (essentially not rendering content at all).',
  props: [
    {
      name: 'conditions',
      type: 'Array<{condition: boolean, content: React.Node}>',
      note: 'The array of conditions with associated content.'
    },
    {
      name: 'fallback',
      optional: true,
      type: 'React.Node',
      note: 'Default: null - The fallback value to be rendered if no other conditions are "truthy".'
    }
  ],
  example: {
    literal: `
<ConditionalRender
  conditions={[
    {
      condition: (3 + 4 > 10),
      content: <h6>This should not content...</h6>
    },
    {
      condition: (7 > 10),
      content: <h6>This should not content either...</h6>
    },
    {
      condition: ('Ian' === 'Ian'),
      content: <h3 style={{textAlign: 'center'}}>Ian rules...</h6>
    },
    {
      condition: (4 === 4),
      content: <h6>This will not get contented because the previous truthy expression...</h6>
    }
  ]}
/> `.trim(),
    render: () => (
      <ConditionalRender
        conditions={[
          {
            condition: 3 + 4 > 10,
            content: <h6>This should not content...</h6>
          },
          {
            condition: 7 > 10,
            content: <h6>This should not content either...</h6>
          },
          {
            condition: 'Ian' === 'Ian', // eslint-disable-line
            content: <h3 style={{ textAlign: 'center' }}>Ian rules...</h3>
          },
          {
            condition: 4 === 4, // eslint-disable-line
            content: <h6>This will not get contented because the previous truthy expression...</h6>
          }
        ]}
      />
    )
  },
  category: CATEGORY.UTILS,
  path: 'components/Utils/ConditionalRender/ConditionalRender'
})

export default ConditionalRender
