import { CSSProperties, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: number
  style?: CSSProperties
}

const AiIcon = ({ size = 26, style = {}, ...rest }: Props) => {
  return (
    <div
      style={{
        backgroundColor: '#1561AB',
        width: size,
        height: size,
        borderRadius: '3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontWeight: 500,
        fontSize: Math.floor(size * 0.85),
        ...style
      }}
      {...rest}
    >
      ai
    </div>
  )
}

export default AiIcon
