import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import s from './TableHead.scss'

const TableHeadSortIcons = ({ isDesc }) => (
  <span className={s.sortIconsContainer}>
    <Icon isActive={typeof isDesc === 'boolean' && !isDesc} Icon={IoIosArrowUp} />
    <Icon isActive={typeof isDesc === 'boolean' && isDesc} Icon={IoIosArrowDown} />
  </span>
)

export default TableHeadSortIcons

const Icon = ({ isActive, Icon }) => (
  <span className={isActive ? s.activeSortIcon : s.sortIcon}>
    <Icon />
  </span>
)
