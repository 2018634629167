import { useState } from 'react'
import moment from 'moment'
import cn from 'classnames'
import capitalize from 'lodash/capitalize'
import { Button } from 'simple-core-ui'

const addMonths = (date, months) => {
  const d = date.getDate()
  date.setMonth(date.getMonth() + months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

const addWeeks = (date, weeks) => {
  date.setDate(date.getDate() + 7 * weeks)
  return date
}

const addDays = (date, days) => {
  date.setDate(date.getDate() + days)
  return date
}

const CustomCalendarToolbar = props => {
  const [viewState, setViewState] = useState('month')

  const goToDayView = () => {
    props.onView('day')
    setViewState('day')
  }
  const goToWeekView = () => {
    props.onView('week')
    setViewState('week')
  }
  const goToMonthView = () => {
    props.onView('month')
    setViewState('month')
  }

  const goToBack = () => {
    if (viewState === 'month') {
      props.onNavigate('prev', addMonths(props.date, -1))
    } else if (viewState === 'week') {
      props.onNavigate('prev', addWeeks(props.date, -1))
    } else {
      props.onNavigate('prev', addDays(props.date, -1))
    }
  }

  const goToNext = () => {
    if (viewState === 'month') {
      props.onNavigate('next', addMonths(props.date, 1))
    } else if (viewState === 'week') {
      props.onNavigate('next', addWeeks(props.date, 1))
    } else {
      props.onNavigate('next', addDays(props.date, 1))
    }
  }

  const goToToday = () => {
    const now = new Date()
    props.date.setMonth(now.getMonth())
    props.date.setYear(now.getFullYear())
    props.date.setDate(now.getDate())
    props.onNavigate('current')
  }

  const viewNamesGroup = viewNames => {
    const view = props.view

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <button
          type="button"
          key={name}
          className={cn({ 'rbc-active': view === name })}
          onClick={() => props.onView(name)}
        >
          {capitalize(name)}
        </button>
      ))
    }
  }

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToToday}>
          Today
        </button>
        <button type="button" onClick={goToBack}>
          Back
        </button>
        <button type="button" onClick={goToNext}>
          Next
        </button>
      </span>

      <span className="rbc-toolbar-label">{moment(props.date).format('DD/MM/YYYY')}</span>

      <span className="rbc-btn-group">{viewNamesGroup(props.views)}</span>
      {props.customActions?.length > 0 &&
        props.customActions.map((action, index) => (
          <Button
            key={index}
            isPrimary={action.isPrimary}
            style={action.style}
            className={action.className}
            onClick={action.cb}
          >
            {action.text}
          </Button>
        ))}
    </div>
  )
}

export default CustomCalendarToolbar
