export const absolutePosition = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0
}

export const flexyCentered = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const flexyColCentered = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
}

export const fullSize = {
  height: '100%',
  width: '100%'
}

export const getThemeColor = (theme, color) =>
  ({
    eb: {
      primary: '#36a9e1'
    },
    cg: {
      primary: '#ff7373'
    }
  }[theme][color])

export const colors = {
  mainRed: '#ff7373',
  selectedRed: '#f17979',
  errorRed: '#fa2735',
  white: '#fff',
  offwhite: '#f5f5f5',
  tableBorders: {
    green: '#68c5b5',
    yellow: '#ffd28a',
    red: '#f17979'
  },
  charcoal: '#727479'
}

export const notificationStyles = {
  NotificationItem: {
    DefaultStyle: {
      backgroundColor: '#fff',
      borderTopWidth: '0.33em'
    }
  },
  Title: {
    DefaultStyle: {
      position: 'relative',
      paddingBottom: '1.5em',
      paddingRight: '1em',
      marginBottom: '0.75em',
      lineHeight: 'initial'
    }
  },
  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'transparent',
      color: '#000',
      fontWeight: 'lighter',
      fontFamily: 'inherit',
      opacity: '0.4',
      transition: 'opacity 0.44s',
      top: '1.5%',
      right: '1.5%',
      height: '1.2em',
      width: '1.2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}

export const breakpoints = {
  PHONE: '576px',
  TABLET: '768px',
  LAPTOP: '992px',
  DESKTOP: '1200px'
}

export const COLUMN_SPAN = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  LAPTOP: 'laptop',
  DESKTOP: 'desktop'
}
