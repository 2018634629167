import { CSSProperties } from 'react'
import * as React from 'react'
import classNames from 'classnames'

import { If } from 'simple-core-ui'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

import s from './KeyValuePairs.scss'

interface Props {
  pairs: {
    key: JSX.Element | string
    value: JSX.Element | string
    style?: CSSProperties
    keyClass?: string
    sectionClass?: string
    valueClass?: string
  }[]
  stackedKeys?: boolean
  pairsInline?: boolean
  isLoading?: boolean
  style?: CSSProperties
}

const KeyValuePairs = ({ pairs, stackedKeys, pairsInline, isLoading, style = {} }: Props) => (
  <section className={classNames(s.container, { [s.pairsInline]: pairsInline })} style={style}>
    {pairs.map(
      ({ key, value, style = {}, keyClass = '', sectionClass = '', valueClass = '' }, idx) => (
        <section
          className={classNames(s.pair, { [s.stacked]: stackedKeys, [sectionClass]: sectionClass })}
          style={style}
          key={idx}
        >
          <section className={classNames(s.key, { [keyClass]: keyClass })}>{key}</section>
          <If condition={!isLoading} fallback={<span className={s.empty}>----</span>}>
            <section className={classNames(s.value, { [valueClass]: valueClass })}>{value}</section>
          </If>
        </section>
      )
    )}
  </section>
)

registration.register({
  name: 'KeyValuePairs',
  description:
    'This component is used to structure key/value pairs for things such as attributes for a given object in the system.',
  props: [
    {
      name: 'pairs',
      type: 'Array<{key: React.Node, value: React.Node, style?: Object}>',
      note:
        'The key/value pairs that should be rendered. The optional style property will be set on that given pair container.'
    },
    {
      name: 'stackedKeys',
      optional: true,
      type: 'boolean',
      note: 'If true then the key will be stacked on top of the value rather than inline.'
    },
    {
      name: 'pairsInline',
      optional: true,
      type: 'boolean',
      note: 'If true then the pairs will be rendered inline.'
    },
    {
      name: 'isLoading',
      optional: true,
      type: 'boolean',
      note: 'If true it will render the values as ----.'
    },
    {
      name: 'style',
      optional: true,
      type: 'Object',
      note: 'The styling to be applied to the container around the pairs.'
    }
  ],
  example: {
    literal: '',
    render: () => (
      <React.Fragment>
        <h3>Inline Keys</h3>
        <KeyValuePairs
          style={{ maxWidth: '500px', marginBottom: '2em' }}
          pairs={[
            { key: 'First Name:', value: 'Ian' },
            { key: 'Last Name:', value: 'Jabour' },
            { key: 'Position:', value: 'Intern' },
            { key: 'DOB:', value: '10/12/1991' },
            { key: 'Days Active:', value: '42' },
            { key: 'Permission:', value: 'Read-only', style: { opacity: 0.7 } }
          ]}
        />
        <h3>Stacked Keys</h3>
        <KeyValuePairs
          style={{ maxWidth: '500px', marginBottom: '2em' }}
          pairs={[
            { key: 'First Name', value: 'Ian' },
            { key: 'Last Name', value: 'Jabour' },
            { key: 'Position', value: 'Intern' },
            { key: 'DOB', value: '10/12/1991' },
            { key: 'Days Active', value: '42' },
            { key: 'Permission', value: 'Read-only', style: { opacity: 0.7 } }
          ]}
          stackedKeys
        />
        <h3>Inline Keys with Pairs Inline</h3>
        <KeyValuePairs
          style={{ marginBottom: '2em' }}
          pairs={[
            { key: 'First Name:', value: 'Ian' },
            { key: 'Last Name:', value: 'Jabour' },
            { key: 'Position:', value: 'Intern' },
            { key: 'DOB:', value: '10/12/1991' },
            { key: 'Days Active:', value: '42' },
            { key: 'Permission:', value: 'Read-only', style: { opacity: 0.7 } }
          ]}
          pairsInline
        />
        <h3>Stacked Keys with Pairs Inline</h3>
        <KeyValuePairs
          pairs={[
            { key: 'First Name', value: 'Ian' },
            { key: 'Last Name', value: 'Jabour' },
            { key: 'Position', value: 'Intern' },
            { key: 'DOB', value: '10/12/1991' },
            { key: 'Days Active', value: '42' },
            { key: 'Permission', value: 'Read-only', style: { opacity: 0.7 } }
          ]}
          stackedKeys
          pairsInline
        />
      </React.Fragment>
    )
  },
  category: CATEGORY.LAYOUT,
  path: 'components/Core/KeyValuePairs/KeyValuePairs'
})

export default KeyValuePairs
