const Flex = ({ direction, wrap, align, justify, isInline, style, className, children }) => (
  <section
    style={{
      display: isInline ? 'inline-flex' : 'flex',
      flexFlow: `${direction} ${wrap}`,
      alignItems: align,
      justifyContent: justify,
      ...style
    }}
    className={className}
  >
    {children}
  </section>
)

Flex.defaultProps = {
  direction: 'row',
  wrap: 'wrap',
  align: 'center',
  justify: 'center',
  isInline: false,
  style: {},
  className: '',
  children: null
}

export default Flex
