import { IoMdLock } from 'react-icons/io'
import s from './UpdateToPremiumLabel.scss'

interface Props {
  dataTestId?: string
  style?: React.CSSProperties
}

const UpdateToPremiumLabel = ({ dataTestId, style = {} }: Props) => {
  return (
    <span className={s.updateMatterPlanLabel} style={style}>
      Premium <IoMdLock data-testid={dataTestId} className={s.lockIcon} />
    </span>
  )
}

export default UpdateToPremiumLabel
